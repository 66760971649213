import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Paper, Button, Box, AppBar, Tabs, Tab, TextField, Typography } from '@material-ui/core';
import BasicTable from '../../common/table/BasicTable';
import { MTableToolbar } from 'material-table';
import CancelButton from '../../common/dialog/CancelButton';
import { PartClient, PartVM, DispatchVM } from '../../../brines-refrigerator-api';
import { Color } from '@material-ui/lab/Alert';
import SnackbarNotification from '../../common/snackbar/SnackbarNotification';
import * as client from '../../../helpers/offline/offline-api';

interface PartsUsedProps {
  dispatch: DispatchVM
  closePartsModal: Function
  updateDispatchParts: Function
  addItemToDispatch: Function
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    '& #cancel-btn': {
      marginLeft: "2.5em",
      fontSize: "0.6rem"
    },
    height: '37rem'
  },
  appbar: {
    backgroundColor: "#ffffff",
    color: "#000"
  },
  button: {
    marginBottom: "1em"
  },
  boxScroll: {
    overflowY: "auto",
    overflowX: "hidden"
  },
  partTable: {
    height: '30rem'
  },
  addPartTableButton: {
    marginLeft: "67%%"
  },
  addItemHeading: {
    marginBottom: "1em"
  },
  highlight: {
    backgroundColor: '#3f51b5cc',
    '& .MuiTypography-h6': {
      color: '#ffffff',
    },
  }
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

const DispatchPartsUsed = (props: PartsUsedProps) => {
  const classes = useStyles();

  //=============  PARTS ===================//

  const [selectedParts, setSelectedParts] = useState([])
  const [partsTableIsLoading, setTableLoading] = useState(true);
  const [partsList, setPartsList] = useState([])

  const [value, setValue] = useState(0);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<Color>()

  const [_getParts] = client.useGetParts()

  const getParts = async () => {
    try {
      const parts = await _getParts();
      setPartsList(parts.map((e: PartVM) => ({
        id: Number(e.id), name: e.name, manufacturer: e.manufacturer.name
      })));
    } catch (error) {
      setSnackBarState(true, 'Error while getting parts.', "error")
    }
    setTableLoading(false)
  }

  useEffect(() => {
    getParts()
  }, []);

  const addSelectedPartsToDispatch = async () => {
    props.updateDispatchParts(selectedParts)
    closePartsModal();
    setPartsList(
      partsList.map(e => ({ ...e, tableData: { checked: false } }))
    )
    setTableLoading(false);
  };

  const partsToList = (rows) => {
    setSelectedParts(rows);
  }

  const closePartsModal = () => {
    props.closePartsModal()
  }

  const partsColumns = [
    { title: '#', field: 'id' },
    { title: 'Name', field: 'name' },
    { title: 'Manufacturer', field: 'manufacturer' },
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  //======================= ITEM =================//
  const [itemDescription, setItemDescription] = useState('')

  const validateInput = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let description = e.target.value;
    setItemDescription(description)
  };

  const addItemToDispatch = () => {
    props.addItemToDispatch(itemDescription)
    closePartsModal();
  }

  const setSnackBarState = (state: boolean, message: string, severity: Color) => {
    setSnackbarOpen(state)
    setSnackbarMessage(message)
    setSeverity(severity)
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <div className="parts-modal">
      <SnackbarNotification
        open={snackbarOpen}
        snackbarMessage={snackbarMessage}
        handleClose={handleClose}
        severity={severity}
      />
      <div className={classes.root}>
        <AppBar position="static" className={classes.appbar}>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Add Part" />
            <Tab label="Add Item" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container className={classes.partTable}>
            <Grid item xs={12}>
              <BasicTable
                width={"100%"}
                title={"Add Parts"}
                data={partsList as []}
                columns={partsColumns}
                selection={true}
                components={{
                  Container: props => <Paper {...props} elevation={0} />,
                  Toolbar: props => (
                    <Grid container item xs={12} justify="flex-start" alignItems="center">
                      <Grid item xs={10}>
                        <MTableToolbar classes={{ highlight: classes.highlight }} {...props} />
                      </Grid>
                      <Grid item xs={2}>
                        <CancelButton onClick={closePartsModal} />
                      </Grid>
                    </Grid>
                  ),
                }}
                onSelectionChange={partsToList}
                search={true}
                paging={false}
                isLoading={partsTableIsLoading}
              />
            </Grid>
            <Grid className={classes.addPartTableButton} item xs={4}>
              <Button variant="contained" color="primary" onClick={addSelectedPartsToDispatch}>Add Selected</Button>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Grid container>
            <Grid container className={classes.addItemHeading}>
              <Grid item xs={8} >
                <Typography variant="h1">Add Item</Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={1}>
                  <CancelButton onClick={closePartsModal} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.addItemHeading}>
              <TextField
                id="outlined-multiline-static"
                label="Item Desription"
                multiline
                value={itemDescription}
                onChange={event => validateInput(event)}
                rows={5}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant='contained' fullWidth onClick={addItemToDispatch}>Add Item</Button>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </div>
  );
}
export default DispatchPartsUsed;