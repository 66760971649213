import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, createStyles, Theme, FormControl, Select, MenuItem, Paper, Grid, Button, IconButton } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { useSnackbar, SnackbarKey } from "notistack";
import BasicTable from "../../components/common/table/BasicTable";
import { DispatchClient, DispatchVM, DispatchStatusVM, DispatchStatusClient, CustomerClient, DispatchUpdateVM, ServiceRequestCodeClient, TradeClient, TradeVM, LocationClient, PriorityClient, PriorityTypeVM, UserClient, VisitsClient, DispatchSecondaryStatusClient } from "../../brines-refrigerator-api";
import DispatchCRUDForm from "../Dispatch/DispatchCRUDForm/DispatchCRUDForm";
import { redirectIfSessionExpired } from '../../components/common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Visits from "../../components/dispatch/visitsModal/visits";
import FormDialog from "../../components/common/dialog/FormDialog";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        dispatchCrud: {
            marginTop: '0.7rem',
        },
        disabledDispatchCrud: {
            marginTop: '0.7rem',
            pointerEvents: 'none',
            opacity: 0.4
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export default function FollowUpView() {
    const classes = useStyles();
    const history = useHistory();

    const [tableIsLoading, setTableLoading] = useState(true);

    const columns = [
        { title: 'Follow Up / Due Date', field: 'followUpDate' },
        { title: 'Work Order #', field: 'workOrderNumber' },
        { title: 'Customer', field: 'customer' },
        { title: 'Status', field: 'status' },
    ];

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [dispatches, setDispatches] = useState([]);
    const [dispatchesData, setDispatchesData] = useState([]);
    const [trades, setTrades] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [secondaryStatuses, setSecondaryStatuses] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);
    const [serviceRequestCodes, setServiceRequestCodes] = useState([]);
    const [dispatch, setDispatch] = useState(new DispatchVM({
        id: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        followUpDate: null,
        statusId: null
    }));

    const action = (key: SnackbarKey) => (
        <Fragment>
            <Button onClick={() => { closeSnackbar(key) }}>
                Dismiss
            </Button>
        </Fragment>
    );

    const getServiceRequestCodes = async () => {
        try {
            const serviceRequestCodesClient = new ServiceRequestCodeClient();
            const serviceRequestCodes = await serviceRequestCodesClient.get();
            setServiceRequestCodes(serviceRequestCodes)
        } catch (error) {
            redirectIfSessionExpired(history, error)
        }
    }

    async function getTrades() {
        try {
            const tradesClient = new TradeClient();
            const trades = await tradesClient.getAll();
            trades.push(new TradeVM({
                id: 0,
                name: 'N/A',
                hexCode: 'BCBCBC'
            }));
            setTrades(trades);
        } catch (error) {
            redirectIfSessionExpired(history, error)
        }
    };

    async function getCustomers() {
        try {
            const customersClient = new CustomerClient();
            const customers = await customersClient.getCustomersBase();
            setCustomers(customers);
        } catch (error) {
            redirectIfSessionExpired(history, error)
        }
    }

    async function getLocations() {
        try {
            const locationsClient = new LocationClient();
            const locations = await locationsClient.getLocationsDispatchHq();
            setLocations(locations);
        } catch (error) {
            redirectIfSessionExpired(history, error)
        }
    }

    async function getPriorities() {
        try {
            const prioritiesClient = new PriorityClient();
            const priorities = await prioritiesClient.get();
            priorities.push(new PriorityTypeVM({
                id: 0,
                name: 'N/A',
                hexCode: 'BCBCBC'
            }));
            setPriorities(priorities);
        } catch (error) {
            redirectIfSessionExpired(history, error)
        }
    }

    async function getUsers() {
        try {
            const usersClient = new UserClient();
            const users = await usersClient.users();
            setUsers(users);
        } catch (error) {
            redirectIfSessionExpired(history, error)
        }
    }

    const handleDateChange = async (date: Date | null, dispatch: DispatchVM) => {
        const dispatchClient = new DispatchClient();
        setTableLoading(true);
        try {
            await dispatchClient.put(new DispatchUpdateVM({
                id: dispatch.id!,
                locationId: dispatch.locationId!,
                followUpDate: date,
                statusId: dispatch.statusId === 0 ? null : dispatch.statusId,
                workOrderNumber: dispatch.workOrderNumber,
                priorityId: dispatch.priorityId,
                tradeId: dispatch.tradeId === 0 ? null : dispatch.tradeId,
                slaDate: dispatch.slaDate,
                tehnicianId: dispatch.tehnicianId,
            }))
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar(error.response.data.Title, { variant: "error", action });
        }
        populateTable();
    };

    const handleChange = async (event: React.ChangeEvent<{ value: unknown }>, dispatch: DispatchVM) => {
        const dispatchClient = new DispatchClient();
        setTableLoading(true);
        try {
            await dispatchClient.put(new DispatchUpdateVM({
                id: dispatch.id!,
                locationId: dispatch.locationId!,
                statusId: event.target.value as number === 0 ? null : event.target.value as number,
                followUpDate: dispatch.followUpDate,
                workOrderNumber: dispatch.workOrderNumber,
                priorityId: dispatch.priorityId,
                tradeId: dispatch.tradeId === 0 ? null : dispatch.tradeId,
                slaDate: dispatch.slaDate,
                tehnicianId: dispatch.tehnicianId,
            }))
        } catch (error) {
            redirectIfSessionExpired(history, error)
            enqueueSnackbar(error.response.data.Title, { variant: "error", action });
        }
        populateTable();
    };

    const deleteDispatch = async (id: number) => {
        try {
            const dispatchClient = new DispatchClient();
            await dispatchClient.delete(id);
            setTableLoading(true);
            populateTable();
        } catch (error) {
            redirectIfSessionExpired(history, error)
        }
    }

    async function populateTable() {
        const dispatchClient = new DispatchClient();
        const dispatches = await dispatchClient.getDispatchFollowUpDates();

        const statusesClient = new DispatchStatusClient();
        const statuses = await statusesClient.getAll();

        const secondaryStatusesClient = new DispatchSecondaryStatusClient();
        const secondaryStatuses = await secondaryStatusesClient.getAll();

        clearFields();
        setDispatchesData(dispatches);
        setDispatches(dispatches.map(e => ({
            id: e.id,
            followUpDate:
                <div style={{ display: 'flex' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker autoOk clearable format="MM/dd/yyyy" margin="dense" value={e.followUpDate} onChange={(event) => handleDateChange(event, e)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <IconButton
                        size="small"
                        onClick={() => handleDateChange(null, e)}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>,
            workOrderNumber: e.workOrderNumber,
            customer: e.location.customer.company,
            status:
                <FormControl className={classes.formControl}>
                    <Select disableUnderline value={e.statusId} onChange={(event) => handleChange(event, e)}>
                        {statuses.map((e: DispatchStatusVM) => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                    </Select>
                </FormControl>
        })));
        setTableLoading(false);
        setStatuses(statuses);
        setSecondaryStatuses(secondaryStatuses);
    };

    const clearFields = () => {
        setDispatch(new DispatchVM({
            id: null,
            locationId: null,
            priorityId: null,
            tradeId: null,
            workOrderNumber: null,
            slaDate: null,
            tehnicianId: null,
            followUpDate: null,
            statusId: null
        }))
    }

    useEffect(() => {
        const fetchDataAsync = async () => {
            await populateTable();
            getLocations();
            getCustomers();
            getPriorities();
            getTrades();
            getUsers();
            getServiceRequestCodes();
        }
        fetchDataAsync()
    }, []);

    const setDispatchForEditing = (rowData) => {
        setDispatch(dispatchesData.filter(e => e.id === (rowData as { id: number }).id)[0]);
    }

    const openDispatchVisits = async (dispatchId: number) => {
        setVisitsSignaturesModal({ open: true, dispatchId: dispatchId });
    }

    const [visitsSignaturesModal, setVisitsSignaturesModal] = useState({ open: false, dispatchId: 0 });

    const visitsSignaturesModalBody = (
        <>
            <Visits setModalState={setVisitsSignaturesModal} dispatchId={visitsSignaturesModal.dispatchId} technicians={users} />
        </>
    );

    const tableActions = [
        rowData => ({
            icon: () => <DriveEtaIcon color='primary' />,
            onClick: (event, rowData: unknown) => { openDispatchVisits((rowData as { id: number }).id) },
            tooltip: "View Visits"
        }),
        rowData => ({
            icon: () => <Edit color='primary' />,
            onClick: (event, rowData: unknown) => { setDispatchForEditing(rowData) },
            tooltip: "Edit Dispatch"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { deleteDispatch((rowData as { id: number }).id) },
            tooltip: "Delete Dispatch"
        }),
    ]

    const editDispatch = async (props: DispatchUpdateVM) => {
        try {
            const dispatchClient = new DispatchClient();
            const dispatch = await dispatchClient.put(new DispatchUpdateVM({
                ...props
            }));
            clearFields();
            setTableLoading(false);
            await populateTable();
        }
        catch (err) {
            enqueueSnackbar(err.response.data.Title, { variant: "error", action });
        }
    }

    return (
        <>
            <Grid container classes={{ root: classes.root }}>
                <Grid item container xs={12} md={6}>
                    <section className={classes.root}>
                        <BasicTable
                            columns={columns}
                            title="FOLLOW UP / DUE DATE"
                            data={dispatches as []}
                            actions={tableActions}
                            components={{
                                Container: props => <Paper {...props} elevation={0} />,
                            }}
                            paging={true}
                            isLoading={tableIsLoading}
                        />
                    </section>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <section className={dispatch.id ? classes.dispatchCrud : classes.disabledDispatchCrud}>
                        <DispatchCRUDForm
                            locationId={dispatch.id ? dispatch.locationId : null}
                            trades={trades}
                            statuses={statuses}
                            secondaryStatuses={secondaryStatuses}
                            customers={customers}
                            locations={locations}
                            priorities={priorities}
                            technicians={users}
                            serviceRequestCodes={serviceRequestCodes}
                            dispatch={dispatch}
                            formAction={null}
                            formTitle='EDIT DISPATCH'
                            clearFields={clearFields}
                            formButton1Action={null}
                            formButton2Action={editDispatch}
                        />
                    </section>
                </Grid>
            </Grid>
            {visitsSignaturesModal && <FormDialog open={visitsSignaturesModal.open} body={visitsSignaturesModalBody} />}
        </>
    );
}