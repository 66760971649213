import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { LocationBaseVM, LocationClient, TaxRatesClient } from '../../brines-refrigerator-api';
import BasicTable from '../../components/common/table/BasicTable';
import { useDebouncedSearch } from '../../helpers/search';
import UserRole from '../../helpers/constants/userRole';
import { useHistory, useLocation } from 'react-router-dom';
import { redirectIfSessionExpired } from '../../components/common/redirect/RedirectOnSessionTimeout';
import { useSnackbar } from 'notistack';
import Edit from '@material-ui/icons/Edit';
import EditLocationTaxRates from './EditLocationTaxRates';
import TaxRateCRUDForm from './TaxRateCRUDForm';
import Delete from '@material-ui/icons/Delete';

const useStyles = makeStyles({
    root: {
        padding: '1rem 2.5rem'
    },
    marginBottom: {
        marginBottom: "2rem"
    }
});

const TaxRatesView = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const classes = useStyles();

    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    const role = userData.role.name;

    const locationColumns = [
        { title: '#', field: 'id' },
        { title: 'Location', field: 'name' },
        { title: 'ZIP', field: 'zip' }
    ];

    const taxRateColumns = [
        { title: '#', field: 'id' },
        { title: 'Name', field: 'name' },
        { title: 'Amount', field: 'amount' },
        { title: 'Type', field: 'taxRateType.name' },
        { title: 'State', field: 'state.name' }
    ];

    const location = useLocation();

    const { inputText, setInputText, search } = useDebouncedSearch((text: string) => searchLocations(text))

    const searchLocations = async (input: string) => {
        const client = new LocationClient();
        const locations = await client.getByFilter(input);

        return locations.map((e: LocationBaseVM) => ({ id: Number(e.id), location: e.name, zip: e.zip, isArchived: e.isArchived })).filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived)
    }

    useEffect(() => {
        const fetchDataAsync = async () => {
            await populateTaxRatesTable();
            await populateLocationsTable();
            if (location.state) {
                await fetchLocationForEditing((location.state as { locationId: number }).locationId);
            }
        }
        fetchDataAsync()
    }, []);

    const fetchLocationForEditing = async (locationId: number) => {
        const locationClient = new LocationClient();
        const result = await locationClient.getById(locationId);

        setLocationForEditing(new LocationBaseVM({
            id: result.id,
            name: result.name,
            zip: result.zip,
            isArchived: result.isArchived,
            customerId: result.customerId,
            stateId: result.stateId
        }))
    }

    function handleServerError(message) {
        if (message === undefined || message.response === undefined) {
            enqueueSnackbar("An error has occured", { variant: "error" });
            return;
        }

        for (let prop in message.response.data.errors) {
            let propErrors = message.response.data.errors[prop];

            propErrors.forEach(element => {
                enqueueSnackbar(element, { variant: "error" });
            });
        }
    }

    const [taxRates, setTaxRates] = useState([]);

    const populateTaxRatesTable = async () => {
        try {
            const taxRatesClient = new TaxRatesClient();
            const taxRates = await taxRatesClient.get();
            setTaxRates(taxRates);
        }
        catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    }


    async function populateLocationsTable() {
        try {
            const locationClient = new LocationClient();
            const locations = await locationClient.getByTeam();
            setLocations(locations.map((e: LocationBaseVM) => ({ id: Number(e.id), name: e.name, zip: e.zip, isArchived: e.isArchived, stateId: e.stateId })).filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived));
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
        setTableIsLoading(false);
    };

    const history = useHistory();

    const locationsTableActions = [
        rowData => ({
            icon: () => <Edit color='primary' />,
            onClick: (event, rowData: unknown) => { setLocationForEditing((rowData as LocationBaseVM)) },
            tooltip: "Edit location tax rates"
        }),
    ]

    const taxRatesTableActions = [
        rowData => ({
            icon: () => <Edit color='primary' />,
            onClick: (event, rowData: unknown) => { setTaxRateForEditing((rowData as { id: number }).id) },
            tooltip: "Edit location tax rate"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { deleteTaxRate((rowData as { id: number }).id) },
            hidden: rowData['isArchived'],
            tooltip: "Delete tax rate"
        })
    ]

    const deleteTaxRate = async (taxRateId: number) => {
        try {
            const taxRatesClient = new TaxRatesClient();
            await taxRatesClient.deleteTaxRate(taxRateId);
            enqueueSnackbar("Successfully deleted tax rate", { variant: "success" });
            await populateTaxRatesTable();
        }
        catch (error) {
            enqueueSnackbar("Error deleting tax rate", { variant: "error" });
        }
    }

    const setTaxRateForEditing = (taxRateId: number) => {
        setCurrentTaxRateId(taxRateId);
    }

    const [currentTaxRateId, setCurrentTaxRateId] = useState(null);

    //hooks for the edit tax rate crud form including all kinds of tax rates
    const [locationStateTaxRate, setLocationStateTaxRate] = useState(null);
    const [countyTaxRates, setCountyTaxRates] = useState([]);
    const [cityTaxRates, setCityTaxRates] = useState([]);
    const [zipCodeTaxRates, setZipCodeTaxRates] = useState([]);

    const setLocationForEditing = async (location: LocationBaseVM) => {
        clearLocationTaxRatesForm();
        setCurrentLocation(location);
        await fetchTaxRatesForLocation(location.stateId);
    }

    const clearLocationTaxRatesForm = () => {
        setLocationStateTaxRate(null);
        setCountyTaxRates([]);
        setCityTaxRates([]);
        setZipCodeTaxRates([]);
    }


    const fetchTaxRatesForLocation = async (stateId: number) => {
        const taxRatesClient = new TaxRatesClient();

        const stateTaxRate = await taxRatesClient.getStateTaxRate(stateId);
        setLocationStateTaxRate(stateTaxRate);

        const countyTaxRates = await taxRatesClient.getCountyTaxRates(stateId);
        setCountyTaxRates(countyTaxRates);

        const cityTaxRates = await taxRatesClient.getCityTaxRates(stateId);
        setCityTaxRates(cityTaxRates);

        const zipCodeTaxRates = await taxRatesClient.getZipCodeTaxRates(stateId);
        setZipCodeTaxRates(zipCodeTaxRates);
    }

    const [currentLocation, setCurrentLocation] = useState(null);

    const [tableIsLoading, setTableIsLoading] = useState(false);

    const [locations, setLocations] = useState([]);

    const clearTaxRateForm = () => {
        setCurrentTaxRateId(null);
    }

    const refreshTaxRatesTable = async () => {
        await populateTaxRatesTable();
    }

    return (
        <Grid className={classes.root} container>
            <Grid item container xs={12} spacing={4}>
                <Grid item xs={6}>
                    <div className={classes.marginBottom}>
                        <BasicTable
                            columns={taxRateColumns}
                            title="TAX RATES LIST"
                            data={taxRates as []}
                            actions={taxRatesTableActions}
                            paging={true}
                            isLoading={tableIsLoading || search.loading}
                            components={{
                                Container: props => <Paper {...props} elevation={0} />
                            }}
                        />
                    </div>
                    <TaxRateCRUDForm
                        taxRateId={currentTaxRateId}
                        cancel={clearTaxRateForm}
                        refreshTable={refreshTaxRatesTable}
                        taxRates={taxRates}
                    />
                </Grid>
                <Grid item container xs={6}>
                    <Grid item xs={12}>
                        <BasicTable
                            columns={locationColumns}
                            title="LOCATIONS LIST"
                            data={inputText ? search.result : locations as []}
                            actions={locationsTableActions}
                            components={{
                                Container: props => <Paper {...props} elevation={0} />,
                            }}
                            paging={true}
                            isLoading={tableIsLoading || search.loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {currentLocation && <EditLocationTaxRates
                            location={currentLocation ? currentLocation : null}
                            stateId={currentLocation ? currentLocation.stateId : null}
                            locationStateTaxRate={locationStateTaxRate}
                            countyTaxRates={countyTaxRates}
                            cityTaxRates={cityTaxRates}
                            zipCodeTaxRates={zipCodeTaxRates}
                            syncTaxData={fetchTaxRatesForLocation}
                        />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TaxRatesView;