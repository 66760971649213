import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { ServiceRequestCodeClient, ServiceRequestCodeAddVM } from '../../../brines-refrigerator-api';
import ConfirmDialog from '../../common/dialog/ConfirmationDialog';
import AddTable from '../../common/table/AddTable';
import { Color } from '@material-ui/lab/Alert';
import SnackbarNotification from '../../common/snackbar/SnackbarNotification';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../../helpers/validations';

const ServiceRequestCodes: React.FC = () => {
  const history = useHistory();

  const [serviceRequestCodes, setServiceRequestCodes] = useState([])
  const [idForDelete, setIdForDelete] = useState(0);
  const [dialogMessage, setDialogMessage] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<Color>()

  async function getServiceRequestCodes() {
    try {
      const requestCodesClient = new ServiceRequestCodeClient();
      const requestCodes = await requestCodesClient.get();
      setServiceRequestCodes(requestCodes)

    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error while getting service request codes', "error")
    }
  };

  useEffect(() => {
    getServiceRequestCodes();
  }, []);

  const addRequestCode = async (data: unknown) => {
    const resultName = (data as { name: string, description: string }).name
      ? validateText((data as { name: string, description: string }).name)
      : { error: true, errorMessage: 'Name can\'t be empty.' };

    const resultDescription = (data as { name: string, description: string }).description
      ? validateText((data as { name: string, description: string }).description)
      : { error: true, errorMessage: 'Description can\'t be empty.' };

    if (resultName.error) {
      setSnackBarState(true, resultName.errorMessage, "error")
    }
    if (resultDescription.error) {
      setSnackBarState(true, resultDescription.errorMessage, "error")
    }
    if (resultName.error || resultDescription.error) {
      return;
    }
    try {
      const requestCodesClient = new ServiceRequestCodeClient();

      await requestCodesClient.create(new ServiceRequestCodeAddVM({
        code: Number((data as { code: number, description: string }).code),
        description: (data as { code: number, description: string }).description
      }));

      setSnackBarState(true, 'Code added succesfully', "success")

      getServiceRequestCodes();
    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error while adding service request codes', "error")
    }
  }

  const deleteRequestCode = async (id: number) => {
    try {
      const requestCodesClient = new ServiceRequestCodeClient();
      await requestCodesClient.delete(id);
      getServiceRequestCodes()
      setSnackBarState(true, 'Code deleted succesfully', "success")
    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error while deleting service request codes', "error")
    }
  }
  const openDeleteDialogue = (id: number) => {
    setIdForDelete(id);
    setDialogMessage(`Are you sure you want to delete service request code #${id}?`);
    setConfirmOpen(true);
  }

  const serviceRequestCodesColums = [
    { title: 'Code', field: 'code' },
    { title: 'Description', field: 'description' }
  ];

  const setSnackBarState = (state: boolean, message: string, severity: Color) => {
    setSnackbarOpen(state)
    setSnackbarMessage(message)
    setSeverity(severity)
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  const tableTooltip = { addTooltip: "Enter Service Request Code" }

  return (
    <Grid item container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12}>
        <SnackbarNotification
          open={snackbarOpen}
          snackbarMessage={snackbarMessage}
          handleClose={handleClose}
          severity={severity}
        />
        <Paper elevation={0}>
          <AddTable
            width={'100%'}
            columns={serviceRequestCodesColums}
            title={"Service Request Codes"}
            add={addRequestCode}
            delete={openDeleteDialogue}
            data={serviceRequestCodes as []}
            search={false}
            localization={tableTooltip}
            deleteTooltip={"Delete Service Request Code"}
          />
          {confirmOpen && <ConfirmDialog
            title={"Delete service request code?"}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => { deleteRequestCode(idForDelete) }}
          >
            {dialogMessage}
          </ConfirmDialog>
          }
        </Paper>
      </Grid>
    </Grid>
  )
};

ServiceRequestCodes.displayName = 'ServiceRequestCodes';

export default ServiceRequestCodes;