import React, { useEffect, useState } from 'react';

import { Button, Grid, Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Snackbar } from '@material-ui/core';

import './ClockInOut.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory, useLocation } from 'react-router-dom';
import { ClockOutCreateVM, ClockOutAnswerCreateVM } from '../../../brines-refrigerator-api';
import Alert from '@material-ui/lab/Alert/Alert';
import * as client from '../../../helpers/offline/offline-api';

const TechClockOut: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState('success');
    const [disabled, setDisabled] = useState(false);
    const snackbarDuration = 5000;
    const [_getClockOutQuestions] = client.useGetClockOutQuestions()
    const [_visitLeaveSite] = client.useVisitLeaveSite()

    function handleBackClick() {
        history.goBack();
    }

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([null, null]);

    useEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = async () => {
        const questions = await _getClockOutQuestions();
        setQuestions(questions);
    }

    const submitAnswers = async () => {
        if (validateAnswers()) {
            try {
                const preparedAnswers = answers.map((x) => { return new ClockOutAnswerCreateVM({ clockOutQuestionId: x.questionId, answer: x.answer }) });

                const clockOut = new ClockOutCreateVM({
                    dispatchId: (location.state as { dispatchId: number }).dispatchId,
                    clockOutAnswers: preparedAnswers,
                    leaveSiteTime: new Date(),
                    visitId: (location.state as { visitId: number }).visitId,
                })

                // const visitsClient = new VisitsClient();

                setDisabled(true);

                //clockout is displayed to tech as "leaving site"
                _visitLeaveSite(clockOut);
                setOpen(true);
                setSnackbarState('success');
                setTimeout(handleBackClick, 2000);
            }
            catch (error) {
                setOpen(true);
                setSnackbarState('error');
            }
        } else {
            alert("Please submit all answers!");
        }
    }

    const validateAnswers = () => {
        //this is just some basic validation for good measure
        // return (answers.find(x => x === null));
        return true;
    }

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, questionId: number) => {
        const newAnswers = [...answers];
        newAnswers[index] = {};
        newAnswers[index].answer = event.target.value === "true";
        newAnswers[index].questionId = questionId;
        setAnswers([...newAnswers]);
    }

    const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    type Severity = "error" | "success" | "info" | "warning" | undefined;

    const RenderAlert = ({ alertType }) => {
        const severity: Severity = alertType;

        const AlertMessage = () => {
            if (severity === 'success') {
                return <span>Tech clocked OUT successfully!</span>
            } else if (severity === 'error') {
                return <span>Error clocking out!</span>
            }
        }

        return (
            <Snackbar open={open} autoHideDuration={snackbarDuration} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleCloseAlert} severity={severity}>
                    <AlertMessage />
                </Alert>
            </Snackbar>
        )
    }

    return (
        <div>
            <Button startIcon={<ArrowBackIosIcon />} onClick={handleBackClick} color="primary">Back</Button>
            <Grid className='clock-io' container>
                <Typography variant="body1" className='clock-io_desc'>
                    Please answer these questions before finishing work on the dispatch.
                </Typography>
                <Grid container item xs={12} className='clock-io_questions'>
                    {questions.map((x, index) => {
                        return (
                            <Grid key={x.id} container item xs={12} className='clock-io_question'>
                                <Grid item xs={12} className='clock-io_question-answers'>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className='clock-io_question-desc'>{x.text}</FormLabel>
                                        <RadioGroup aria-label={`answer${index}`} name={`answer${index}`} value={answers[index] ? answers[index].answer : null} onChange={(e) => { handleAnswerChange(e, index, x.id) }}>
                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <RenderAlert alertType={snackbarState} />
                    <Grid item xs={12}>
                        <Button className='clock-io_submit' type='submit' color='primary' variant='contained' size='large' disabled={disabled} onClick={submitAnswers} disableElevation>SUBMIT</Button>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )

}

export default TechClockOut;