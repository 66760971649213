import React, { useState, useEffect } from 'react';
import { Grid, TextField, Typography, makeStyles, createStyles, Paper, GridList, GridListTile, Button } from '@material-ui/core';
import { Color } from '@material-ui/lab/Alert';
import { LocationVM, StateVM } from '../../brines-refrigerator-api';
import SnackbarNotification from '../../components/common/snackbar/SnackbarNotification';
import { useHistory } from 'react-router-dom';
import { redirectIfSessionExpired } from '../../components/common/redirect/RedirectOnSessionTimeout';
import { useTextValidation, validateText } from '../../helpers/validations';
import * as client from '../../helpers/offline/offline-api';
import RoomIcon from '@material-ui/icons/Room';

interface TechLocationProps {
  dispatchId: number,
  dispatchLocationId: number
}

const useStyles = makeStyles(() =>
  createStyles({
    tileList: {
      maxHeight: "25rem"
    },
    marginTop: {
      marginTop: "1rem"
    },
    fontSize: {
      fontSize: "0.8rem"
    }
  })
);

export default function TechLocation(props: TechLocationProps) {
  const classes = useStyles();
  const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const history = useHistory();
  const [_addLocationNote] = client.useAddLocationNote()
  const [_getLocation] = client.useGetLocation()

  const [noteText, setNoteText] = useState('')
  const [locationNotes, setLocationNotes] = useState([])
  const [location, setLocation] = useState(new LocationVM({ addressLine1: '', addressLine2: '', city: '', zip: '', state: new StateVM({ name: '' }) }))

  const [noteInputProps, triggerNoteInputValidation, resetNoteInputProps] = useTextValidation();

  const getLocation = async () => {
    if (props.dispatchLocationId) {
      try {
        const locationForDisplay = await _getLocation(props.dispatchId, props.dispatchLocationId)
        setLocationNotes(locationForDisplay.notes)
        setLocation(locationForDisplay)
      } catch (error) {
        redirectIfSessionExpired(history, error)
        setSnackBarState(true, 'Error while geting Location notes.', "error")
      }
    }
  }

  const getLocationNotes = async () => {
    try {
      const locationForDisplay = await _getLocation(props.dispatchId, props.dispatchLocationId)
      setLocationNotes(locationForDisplay.notes)
    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error while geting Location notes.', "error")
    }
  }

  const validateNote = () => {
    const formValid = !validateText(noteText).error;
    if (!formValid) triggerNoteInputValidation(noteText, false);
    return formValid;
  }

  const addLocationNote = async () => {
    if (validateNote()) {
      try {
        await _addLocationNote(props.dispatchId, props.dispatchLocationId, noteText)
        getLocationNotes()
        setNoteText('')
        setSnackBarState(true, 'Note added successfully.', "success")
      } catch (error) {
        redirectIfSessionExpired(history, error)
        setSnackBarState(true, 'Error while adding note.', "error")
      }
    }
  }

  useEffect(() => {
    getLocation()
  }, [props]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<Color>()

  const setSnackBarState = (state: boolean, message: string, severity: Color) => {
    setSnackbarOpen(state)
    setSnackbarMessage(message)
    setSeverity(severity)
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const validateInput = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let noteText = e.target.value;
    setNoteText(noteText)
    if (noteInputProps.error) {
      triggerNoteInputValidation(noteText, false);
    }
  }

  const openMaps = (address: string) => {
    // on other mobile devices and computers, it redirects to Google Maps
    window.open(`http://maps.apple.com/maps?q=${address}`)
  }

  return (
    <div>
      <Grid container item xs={12}>
        <SnackbarNotification
          open={snackbarOpen}
          snackbarMessage={snackbarMessage}
          handleClose={handleClose}
          severity={severity}
        />
        <Typography variant="h3">Address</Typography>
        <Grid container item xs={12} >
          <Grid xs={6} item>
            {location.addressLine1}
            <Button color='primary' onClick={() => openMaps(location.addressLine1)} ><RoomIcon /></Button>
          </Grid>
          <Grid xs={6} item>
            {location.addressLine2}
            <Button color='primary' onClick={() => openMaps(location.addressLine2)} ><RoomIcon /></Button>
          </Grid>
          <Grid xs={12} item>
            <Typography>{`${location.city} ${location.state.name} ${location.zip}`}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.marginTop}>
          <Paper variant="outlined" square>
            <div>
              <GridList className={classes.tileList} cellHeight={160}>
                {locationNotes.map((tile) => (
                  <GridListTile key={tile.id} style={{ width: "100%" }} >
                    <div>
                      <Grid container alignItems="center" >
                        <Grid container item xs>
                          <Grid item xs={12}>
                            <Typography className={classes.fontSize} gutterBottom>Added at: {dateTimeFormat.format(tile.created)}</Typography>
                          </Grid>
                          <Typography className={classes.fontSize} gutterBottom>Added by: {tile.createdBy.userName}</Typography>
                        </Grid>
                      </Grid>
                      <Typography className={classes.marginTop} variant="body2">
                        {tile.text}
                      </Typography>
                    </div>
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </Paper>
          <TextField
            fullWidth={true}
            label="Add Note"
            multiline
            rows={4}
            variant="outlined"
            onChange={event => validateInput(event)}
            value={noteText}
            className={classes.marginTop}
            error={noteInputProps.error}
            onBlur={() => { triggerNoteInputValidation(noteText) }}
          />
          <span className="validation_error">
            {noteInputProps.errorMessage}
          </span>
          <Button
            onClick={() => addLocationNote()}
            variant="contained"
            color="primary"
            fullWidth={true}
            className={classes.marginTop}
          >
            Add Note
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};