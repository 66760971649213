import React, { useState, useEffect } from 'react';
import { PartClient, PartVM } from '../../../brines-refrigerator-api';
import { Grid, Paper, Button, Box, makeStyles } from '@material-ui/core';
import BasicTable from '../../../components/common/table/BasicTable';
import { MTableToolbar } from 'material-table';
import CancelButton from '../../../components/common/dialog/CancelButton';
import FormDialog from '../../../components/common/dialog/FormDialog';
import Delete from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import './DispatchParts.scss'
import ConfirmDialog from '../../../components/common/dialog/ConfirmationDialog';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  button: {
    marginBottom: "1em"
  },
  boxScroll: {
    overflowY: "auto",
    overflowX: "hidden"
  },
  partTable: {
    width: '60rem',
    height: '30rem'
  },
  addPartTableButton: {
    marginLeft: "70%"
  },
  highlight: {
    backgroundColor: '#3f51b5cc',
    '& .MuiTypography-h6': {
      color: '#ffffff',
    },
  }
});

const DispatchParts = (props) => {
  const { enqueueSnackbar } = useSnackbar();


  //DELETE CONFIRMATION DIALOGUE
  const [confirm, setConfirmOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [idForDelete, setIdForDelete] = useState(0);
  const [partsTableIsLoading, setTableLoading] = useState(true);

  function handleServerError(message) {
    if (message === undefined || message.response === undefined) {
      enqueueSnackbar("An error has occured", { variant: "error" });
      return;
    }

    for (let prop in message.response.data.errors) {
      let propErrors = message.response.data.errors[prop];

      propErrors.forEach(element => {
        enqueueSnackbar(element, { variant: "error" });
      });
    }
  }

  function confirmOpen() {
    setConfirmOpen(true);
  };

  function openDialogue(id: number, name: string) {

    setDialogTitle('Delete?')
    setDialogMessage(`Are you sure you want to remove ${name} from this dispatch?`)

    setIdForDelete(id);
    setConfirmOpen(true);
  };

  const classes = useStyles();

  // PARTS
  const [partsModal, setPartsModal] = useState(false);

  const openPartsModal = () => {
    setPartsModal(true);
  }

  const closePartsModal = () => {
    setPartsModal(false);
  }

  //GET PARTS
  const [partsList, setPartsList] = useState([])
  const [selectedParts, setSelectedParts] = useState([])

  const partsColumns = [
    { title: '#', field: 'id' },
    { title: 'Name', field: 'name' },
    { title: 'Manufacturer', field: 'manufacturer' },
  ];

  const getParts = async () => {
    try {
      const partsClient = new PartClient();
      const parts = await partsClient.get();
      setPartsList(parts.map((e: PartVM) => ({
        id: Number(e.id), name: e.name, manufacturer: e.manufacturer.name
      })));
      setTableLoading(false);
    } catch (error) {
      handleServerError(error)
    }
  }

  useEffect(() => {
    getParts();
  }, []);

  const partsToList = async (rows) => {
    await setSelectedParts(rows);
  }

  const addSelectedPartsToDispatch = async () => {
    props.addSelectedPart(selectedParts)
    closePartsModal();
    setPartsList(
      partsList.map(e => ({ ...e, tableData: { checked: false } }))
    )
    setTableLoading(false);
  };

  const dispatchPartsColumns = [
    { title: '#', field: 'id' },
    { title: 'Name', field: 'name' },
  ];

  const dispatchPartsTableActions = [
    rowData => ({
      icon: () => <Delete color='primary' />,
      onClick: (event, rowData: PartVM) => { openDialogue(rowData.id, rowData.name) },
      tooltip: "Delete Part"
    })
  ]

  const partsTable = (
    <div className="parts-modal">
      <Grid container className={classes.partTable}>
        <Grid item xs={12}>
          <BasicTable
            width={"100%"}
            title={"Add Parts"}
            data={partsList as []}
            columns={partsColumns}
            selection={true}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Toolbar: props => (
                <Grid container item xs={12} justify="flex-start" alignItems="center">
                  <Grid item xs={11}>
                    <MTableToolbar classes={{ highlight: classes.highlight }} {...props} />
                  </Grid>
                  <Grid item xs={1}>
                    <CancelButton onClick={closePartsModal} />
                  </Grid>
                </Grid>
              ),
            }}
            onSelectionChange={partsToList}
            paging={false}
            isLoading={partsTableIsLoading}
          />
        </Grid>
        <Grid className={classes.addPartTableButton} item xs={4}>
          <Button variant="contained" color="primary" disabled={!(selectedParts.length > 0)} onClick={addSelectedPartsToDispatch}>Add Selected To Dispatch</Button>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <div className='dispatch-parts_container '>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={openPartsModal}
        disabled={props.disabled}
      >
        Add Part
    </Button>
      {partsModal && <FormDialog open={partsModal} body={partsTable} />}

      <Box>
        <BasicTable
          columns={dispatchPartsColumns}
          title=" "
          data={props.dispatchTableParts as []}
          actions={dispatchPartsTableActions}
          components={{
            Container: props => <Paper {...props} elevation={0} />,
          }}
          search={false}
          paging={false}
          isLoading={props.isLoading}
        />
      </Box>
      {confirm && <ConfirmDialog
        title={dialogTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => { props.handlePartChange('delete', idForDelete) }}
      >
        {dialogMessage}
      </ConfirmDialog>
      }
    </div>
  )
};
export default DispatchParts;