import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import AddTable from "../../common/table/AddTable";
import { DocumentTypeClient, DocumentTypeVM, DocumentTypeAddVM } from "../../../brines-refrigerator-api";
import { useSnackbar } from 'notistack';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import { validateText } from "../../../helpers/validations";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        }
    }),
);

export default function DocumentTypes() {
    const classes = useStyles();
    const history = useHistory();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [documentTypes, setDocumentTypes] = useState([]);
    const columns = [
        { title: 'Name', field: 'name' },
        { title: 'Extension', field: 'extension' },
    ];

    async function deleteDocumentType(id: number) {
        const documentTypeClient = new DocumentTypeClient();
        await documentTypeClient.delete(id);
        populateTable()
    };

    async function addDocumentType(data: unknown) {
        const resultName = (data as { name: string, extension: string }).name
            ? validateText((data as { name: string, extension: string }).name)
            : { error: true, errorMessage: 'Name can\'t be empty.' };

        const resultExtension = (data as { name: string, extension: string }).extension
            ? validateText((data as { name: string, extension: string }).extension)
            : { error: true, errorMessage: 'Extension can\'t be empty.' };

        if (resultName.error) {
            enqueueSnackbar(resultName.errorMessage, { variant: "error" });
        }
        if (resultExtension.error) {
            enqueueSnackbar(resultExtension.errorMessage, { variant: "error" });
        }
        if (resultName.error || resultExtension.error) {
            return;
        }

        try {
            const documentTypeClient = new DocumentTypeClient();
            await documentTypeClient.post(
                new DocumentTypeAddVM({
                    name: (data as { name: string, extension: string }).name,
                    extension: (data as { name: string, extension: string }).extension
                })
            );
            populateTable()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    };

    async function populateTable() {
        try {
            const documentTypeClient = new DocumentTypeClient();
            const documentTypes = await documentTypeClient.get();
            setDocumentTypes(documentTypes.map((e: DocumentTypeVM) => ({ id: Number(e.id), name: e.name, extension: e.extension })));
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }

    };

    useEffect(() => {
        populateTable();
    }, []);

    function handleServerError(message) {
        if (message === undefined || message.response === undefined) {
            enqueueSnackbar("An error has occured", { variant: "error" });
            return;
        }

        for (let prop in message.response.data.errors) {
            let propErrors = message.response.data.errors[prop];

            propErrors.forEach(element => {
                enqueueSnackbar(element, { variant: "error" });
            });
        }
    }

    const tableTooltip = { addTooltip: "Enter Document Type" }

    return (
        <section className={classes.root}>
            <AddTable
                width={'50%'}
                columns={columns}
                title={"Document Types"}
                add={addDocumentType}
                delete={deleteDocumentType}
                data={documentTypes as []}
                search={true}
                localization={tableTooltip}
                deleteTooltip={'Delete Document Type'}
            />
        </section>
    );
}