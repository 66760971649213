import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { DispatchServiceRequestCodeVM } from '../../brines-refrigerator-api';

interface TechServiceRequestCodesProps {
  serviceRequestCodes: DispatchServiceRequestCodeVM[]
}

export default function TechServiceRequestCodes(props: TechServiceRequestCodesProps) {

  return (
    <div>
      <Grid container item xs={12}>
        {props.serviceRequestCodes.map((src) => (
          <Grid item xs={12}>
            <Typography>{src.serviceRequestCode.code} - {src.serviceRequestCode.description}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}