import React, { useReducer, useEffect, FormEvent } from 'react';
import { Grid, Typography, Paper, TextField, Button, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ManufacturerVM, PartVM } from '../../../brines-refrigerator-api';
import { validateText, useTextValidation } from '../../../helpers/validations';
import './PartsDetails.scss';

interface PartDetailsProps {
    clearFields: Function,
    part: PartVM,
    formAction: Function,
    manufacturers: ManufacturerVM[],
    formTitle: string,
    formButtonTitle: string
}

const useStyles = makeStyles({
    marginBottom: {
        marginBottom: 16
    },
    bordedSolid: {
        borderBottom: "2px solid rgb(0, 0, 0)",
        marginBottom: "1em"
    },
    partDetailsMargin: {
        marginBottom: "2em"
    }
});

function PartDetails(props: PartDetailsProps) {
    const classes = useStyles();
    //set state for Part Form
    const [state, setState] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            id: props.part.id,
            partNumber: props.part.number,
            partName: props.part.name,
            source: props.part.source,
            manufacturer: props.part.manufacturer,
            manufacturers: props.manufacturers,
            description: props.part.description,
            price: props.part.price
        },
    );

    const validateFields = (): boolean => {
        const resultName = triggerNameValidation(state.partName)
        const resultSource = triggerSourceValidation(state.source)
        const resultDescription = triggerDescriptionValidation(state.description)

        return (resultName || resultSource || resultDescription);
    }
    const [nameInputProps, triggerNameValidation, resetNameInputProps] = useTextValidation();
    const [descriptionInputProps, triggerDescriptionValidation, resetDescriptionInputProps] = useTextValidation();
    const [sourceInputProps, triggerSourceValidation, resetSourceInputProps] = useTextValidation();
    const [manufacturerInputProps, triggerManufacturerValidation, resetManufacturerInputProps] = useTextValidation();

    function executeFormAction(e) {
        e.preventDefault();

        if (validateFields()) {
            return;
        }

        const result = state.manufacturer.id !== 0 ? validateText(state.manufacturer.name) : { error: true, errorMessage: 'This field can\'t be empty.' };

        if (result.error) {
            triggerManufacturerValidation(state.manufacturer.name)
            return;
        }

        props.formAction(e, Number(state.id), state.manufacturer)
    }

    useEffect(() => {
        setState({
            id: props.part.id,
            partNumber: props.part.number,
            partName: props.part.name,
            source: props.part.source,
            manufacturer: props.part.manufacturer,
            manufacturers: props.manufacturers,
            description: props.part.description,
            price: props.part.price
        })
        resetNameInputProps()
        resetDescriptionInputProps()
        resetSourceInputProps()
        resetManufacturerInputProps()
    }, [props])

    //set selected Manufacturer from dropdown select
    const handleManufacturerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value === '') {
            setState({ manufacturer: new ManufacturerVM({ id: 0, name: '' }) })
            return;
        }
        resetManufacturerInputProps()
        const [{ id, name }] = state.manufacturers.filter(e => e.id === (event.target.value as number));
        setState({ manufacturer: new ManufacturerVM({ id, name }) })
    };

    const clearFields = () => {
        props.clearFields()
    }
    return (
        <Grid item xs={12} sm={12} className='user_crud_form'>
            <Paper elevation={0} />
            <Grid container spacing={1} className={classes.partDetailsMargin} >
                <Grid item xs={6} sm={6}>
                    <Typography variant="h2">{props.formTitle}</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Button onClick={clearFields} href="#text-buttons" color="primary">
                        Clear fields
            </Button>
                </Grid>
            </Grid>
            <Grid className={classes.bordedSolid} item container xs={12} sm={12} alignItems='center'>
                <Grid item xs={1}>
                    {/* <Checkbox color='primary'/> */}
                </Grid>
                <Grid item xs={11}>
                    {/* <Typography >Active</Typography> */}
                </Grid>
            </Grid>
            <Grid container justify='center'>
                <form onSubmit={(e: FormEvent) => executeFormAction(e)}>
                    <Grid xs={12} item container>
                        <Grid item xs={12} className={classes.marginBottom}>
                            <TextField
                                fullWidth={true}
                                type='number'
                                name='partNumber'
                                label='Part Number'
                                value={state.partNumber}
                                onChange={(e: any) => setState({ partNumber: e.target.value })}
                                variant='outlined' />
                        </Grid>
                        <Grid xs={12} item className={classes.marginBottom}>
                            <TextField
                                fullWidth={true} type='text'
                                name='partName'
                                label='Part Name'
                                value={state.partName}
                                onChange={(e: any) => {
                                    setState({ partName: e.target.value })
                                    if (nameInputProps.error) {
                                        triggerNameValidation(e.target.value)
                                    }
                                }}
                                error={nameInputProps.error}
                                onBlur={() => triggerNameValidation(state.partName)}
                                variant='outlined' />
                            <span className="validation_error">
                                {nameInputProps.errorMessage}
                            </span>
                        </Grid>
                        <Grid item xs={12} className={classes.marginBottom}>
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">Manufacturer</InputLabel>
                                <Select
                                    value={state.manufacturer.id !== 0 ? state.manufacturer.id : ''}
                                    onChange={handleManufacturerChange}
                                    label='Manufacturer'
                                >
                                    <MenuItem value="" disabled={true}>
                                        <em>Please select a Manufacturer</em>
                                    </MenuItem>
                                    {state.manufacturers.map((e: ManufacturerVM) => <MenuItem value={e.id}>{e.name}</MenuItem>)}
                                </Select>
                                <span className="validation_error">
                                    {manufacturerInputProps.errorMessage}
                                </span>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.marginBottom}>
                            <TextField
                                fullWidth={true}
                                type='text'
                                name='source'
                                label='Source'
                                value={state.source}
                                onChange={(e: any) => {
                                    setState({ source: e.target.value })
                                    if (sourceInputProps.error) {
                                        triggerSourceValidation(e.target.value)
                                    }
                                }}
                                error={sourceInputProps.error}
                                onBlur={() => triggerSourceValidation(state.source)}
                                variant='outlined' />
                            <span className="validation_error">
                                {sourceInputProps.errorMessage}
                            </span>
                        </Grid>
                        <Grid xs={12} item className={classes.marginBottom}>
                            <TextField
                                fullWidth={true}
                                multiline
                                rows={5}
                                type='text'
                                name='description'
                                value={state.description}
                                onChange={(e: any) => {
                                    setState({ description: e.target.value })
                                    if (descriptionInputProps.error) {
                                        triggerDescriptionValidation(e.target.value)
                                    }
                                }}
                                error={descriptionInputProps.error}
                                onBlur={() => triggerDescriptionValidation(state.description)}
                                label='Description'
                                variant='outlined' />
                            <span className="validation_error">
                                {descriptionInputProps.errorMessage}
                            </span>
                        </Grid>
                        <Grid xs={12} item className={classes.marginBottom}>
                            <TextField
                                fullWidth={true}
                                type='number'
                                name='price'
                                label='Price'
                                value={state.price}
                                onChange={(e: any) => setState({ price: e.target.value })}
                                variant='outlined' />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button type='submit' color='primary' variant='contained' size='large' fullWidth >{props.formButtonTitle}</Button>
                    </Grid>
                </form>
            </Grid>
            <Paper />
        </Grid>
    )
};

PartDetails.displayName = 'PartDetails';

export default PartDetails;
