import React, { useState } from 'react';
import { DispatchClient, DispatchTableVM, DispatchVM } from '../../../brines-refrigerator-api';
import BasicTable from '../../../components/common/table/BasicTable';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ConfirmDialog from '../../../components/common/dialog/ConfirmationDialog';
import SearchIcon from '@material-ui/icons/Search';
import SyncIcon from '@material-ui/icons/Sync';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LaunchIcon from '@material-ui/icons/Launch';

import './DispatchHistory.scss';
import { Button, Grid, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { useDebouncedSearch } from '../../../helpers/search';

interface dispatchHistoryTableProps {
    dispatches: DispatchVM[],
    setDispatchForEditing: Function,
    setDispatchForViewing: Function,
    deleteDispatch: Function,
    isLoading: boolean,
    syncBoardData: Function,
    openDispatchVisits: Function
}

const DispatchHistory = (props: dispatchHistoryTableProps) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

    const dispatchHistorycolumns = [
        { title: 'Id', field: 'id' },
        { title: 'Date', field: 'date' },
        { title: 'Location', field: 'locationName' },
        { title: 'Zip', field: 'locationZip' },
        { title: 'Equipment', field: 'equipment' }
    ];

    const tableActions = [
        rowData => ({
            icon: () => <DriveEtaIcon color='primary' />,
            onClick: (event, rowData: unknown) => { props.openDispatchVisits((rowData as { id: number }).id) },
            tooltip: "View Visits"
        }),
        rowData => ({
            icon: () => <LaunchIcon color='primary' />,
            onClick: (event, rowData: unknown) => { props.setDispatchForViewing((rowData as { id: number }).id) },
            tooltip: "View Dispatch"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { openDeleteDialogue((rowData as { id: number }).id) },
            hidden: rowData['isArchived'],
            tooltip: "Delete Dispatch"
        })
    ]

    const [idForDelete, setIdForDelete] = useState(0);
    const [dialogMessage, setDialogMessage] = useState('');

    const [confirmOpen, setConfirmOpen] = useState(false);

    const openDeleteDialogue = (id: number) => {
        setIdForDelete(id);
        setDialogMessage(`Are you sure you want to delete dispatch #${id}?`);
        setConfirmOpen(true);
    }

    const searchDispatches = async (input: string) => {
        const client = new DispatchClient();

        const dispatches = await client.getDispatchHistoryByFilter(input)
        return dispatches.map((e: DispatchTableVM) => ({
            key: Number(e.id),
            id: Number(e.id),
            date: e.created ? dateTimeFormat.format(e.created) : 'Unassigned',
            locationName: e.location.name,
            locationZip: e.location.zip,
            equipment: e.dispatchEquipments.length > 0 ? e.dispatchEquipments.map(x => x.equipment.model) : 'Unassigned'
        }))
    }
    const { inputText, setInputText, search } = useDebouncedSearch((text: string) => searchDispatches(text))

    const syncBoardData = async () => {
        props.syncBoardData()
    }

    return (
        <div className='dispatch-history-container'>
            <Grid container item xs={12}>
                <Grid item sm={6}>
                    <Typography variant="h1">Dispatches</Typography>
                </Grid>
                <Grid item sm={2}>
                    <Button onClick={syncBoardData} ><SyncIcon color="primary" /></Button>
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        id="input-with-icon-textfield"
                        label="Search"
                        value={inputText}
                        onChange={(e) => {
                            setInputText(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                </Grid>
            </Grid>
            <BasicTable
                columns={dispatchHistorycolumns}
                title={"Dispatches"}
                actions={tableActions}
                data={inputText ? search.result : props.dispatches as []}
                components={{
                    Container: props => <Paper {...props} elevation={0} />,
                    Toolbar: () => <></>,
                }}
                paging={true}
                isLoading={props.isLoading || search.loading}
            />
            {confirmOpen && <ConfirmDialog
                title={"Delete dispatch?"}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => { props.deleteDispatch(idForDelete) }}
            >
                {dialogMessage}
            </ConfirmDialog>
            }
        </div>
    )
}

export default DispatchHistory;