import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Accordion, AccordionDetails, Typography, CircularProgress, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { useHistory, useLocation } from "react-router-dom";
import { DispatchVM, PartVM, EquipmentVM } from '../../brines-refrigerator-api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useStyles } from "./TechDispatchViewStyle"
import TechDispatchDetails from '../../components/tech/TechDispatchDetails';
import SignatureSection from './Signature/SignatureSection';
import TechDocuments from '../../components/tech/TechDocuments';
import UploadButton from '../../components/common/upload/UploadButton';
import UploadButtonType from '../../helpers/constants/uploadButtonTypes';
import TechNotes from '../../components/tech/TechServiceNotes/TechNotes';
import AddNote from '../../components/common/note/AddNote';
import FormDialog from '../../components/common/dialog/FormDialog';
import DispatchPartsUsed from '../../components/tech/DispatchPartsUsed/DispatchPartsUsed';
import PartsItemsList from '../../components/tech/PartsItems/PartsItemsLists';
import TechContacts from './TechContacts';
import TechServiceRequestCodes from './TechServiceRequestCodes';
import TechLocation from './TechLocation';
import TechEquipment from './TechEquipment';
import TechEquipmentAdd from './TechEquipmentAdd';
import * as client from '../../helpers/offline/offline-api';
import TechVisits from './TechVisits';
import { useSnackbar } from 'notistack';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setCachedRequestsSync } from '../../global-state/actions/cachedRequestsActions';
import camera from '../../helpers/camera';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const AccordionSummary = withStyles({
  expandIcon: {
    position: 'absolute',
    left: '1%',
  }
})(MuiAccordionSummary);

export default function TechDispatch() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [_getDispatch] = client.useGetDispatch()
  const [_getDispatchNotes] = client.useGetDispatchNotes()
  const [_getServiceNotes] = client.useGetServiceNotes()
  const [_getDispatchDocuments] = client.useGetDispatchDocuments()
  const [_getDispatchParts] = client.useGetDispatchParts()
  const [_getDispatchEquipment] = client.useGetDispatchEquipment()
  const [_getDispatchItems] = client.useGetDispatchItems()
  const [_addDispatchNote] = client.useAddDispatchNote()
  const [_addServiceNote] = client.useAddServiceNote()
  const [_updateDispatchParts] = client.useUpdateDispatchParts()
  const [_addItemToDispatch] = client.useAddItemToDispatch()
  const [_updateDispatchEquipment] = client.useUpdateDispatchEquipment()
  const [_addDocument] = client.useAddDocument()
  const [_getVisits] = client.useGetVisits()
  const [_getClockInQuestions] = client.useGetClockInQuestions()
  const [_getClockOutQuestions] = client.useGetClockOutQuestions()
  const [_visitStartTravel] = client.useVisitStartTravel()
  const dispatchAction = useDispatch();
  const onlineState = useSelector(state => (state as { onlineStateReducer: { currState: { online: boolean } } }).onlineStateReducer.currState.online, shallowEqual);

  const options = { weekday: 'long', year: 'numeric', day: 'numeric', month: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);

  const optionsWithTime = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormatPhotoTitle = new Intl.DateTimeFormat('en-US', optionsWithTime);

  function handleBackClick() {
    history.goBack();
  }

  // ERROR HANDLING //
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [dispatch, setDispatch] = useState<DispatchVM>()
  const [loader, setLoader] = useState(true)
  const [dispatchId, setDispatchId] = useState(0)

  // GET DISPATCH WITH DISPATCH ID SENT FROM TECH VIEW
  async function getDispatch() {
    try {
      const dispatch = await _getDispatch(Number(location.state));
      setDispatchId(Number(location.state))
      setDispatch(dispatch)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  // DISPATCH NOTES
  const [dispatchNotes, setDispatchNotes] = useState([])

  async function getDispatchNotes() {
    try {
      const dispatchNotes = await _getDispatchNotes(Number(location.state), true);
      setDispatchNotes(dispatchNotes)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  // SERVICE NOTES
  const [serviceNotes, setServiceNotes] = useState([])

  async function getServiceNotes() {
    try {
      const serviceNotes = await _getServiceNotes(Number(location.state), true);
      setServiceNotes(serviceNotes)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  // DISPATCH DOCUMENTS
  const [dispatchDocuments, setDispatchDocuments] = useState([])

  async function getDispatchDocuments() {
    try {
      const documents = await _getDispatchDocuments(Number(location.state));
      setDispatchDocuments(documents)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  //VISITS
  const [visits, setVisits] = useState([]);
  const [questions, setQuestions] = useState([]);

  const getVisits = async () => {
    try {
      const visits = await _getVisits(Number(location.state));
      setVisits(visits);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  //QUESTIONS

  const getQuestions = async () => {
    const clockInQuestions = await _getClockInQuestions();
    const clockOutQuestions = await _getClockOutQuestions();

    setQuestions([clockInQuestions[0].text, clockInQuestions[1].text, clockOutQuestions[0].text, clockOutQuestions[1].text])
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      await getDispatch()
      await getDispatchNotes()
      await getVisits();
      await getQuestions();
      await getDispatchDocuments()
      await getServiceNotes()
      await checkClockInOutState();
      await getDispatchParts();
      await getDispatchEquipment();
      await getDispatchItems();
      setLoader(false)
    }
    fetchDataAsync()
  }, []);


  //CLOCK IN OUT
  const [ongoingVisitId, setOngoingVisitId] = useState(null);
  const [visitStatus, setVisitStatus] = useState(null);
  const checkClockInOutState = async () => {
    try {
      const visits = await _getVisits(Number(location.state));
      const unfinishedVisit = visits.find(x => !x.finished);
      if (unfinishedVisit) {
        const clockIn = unfinishedVisit.clockIn;
        clockIn.arrivedOnSite ? setVisitStatus("on-site") : setVisitStatus("travelled");
        setOngoingVisitId(unfinishedVisit.id);
      } else {
        setVisitStatus("not-travelled");
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  // PLACEHOLDERS FOR BUTTON ACTIONS
  const openNotes = (event) => {
    event.stopPropagation();
    alert("placeholder");
  }

  //ADD DISPATCH NOTE
  const [dispatchNoteModal, setDispatchNoteModal] = useState(false)

  const addDispatchNoteModal = (event) => {
    event.stopPropagation();
    setDispatchNoteModal(true)
  }

  const addDispatchNote = async (id: number, noteText: string) => {
    try {
      await _addDispatchNote(id, noteText);
      getDispatchNotes()
      setDispatchNoteModal(false)
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const dispatchAddNote = (
    <AddNote
      buttonAction={addDispatchNote}
      id={(Number(location.state))}
      noteText={''}
      title={"Add Note"}
      buttonText={"Add Note"}
      onClick={() => setDispatchNoteModal(false)}
      uid={null}
    />
  );

  //ADD SERVICE NOTE
  const [serviceNoteModal, setServiceNoteModal] = useState(false)


  const addServiceNoteModal = (event) => {
    event.stopPropagation();
    setServiceNoteModal(true)
  }

  const addServiceNote = async (id: number, noteText: string) => {
    try {
      await _addServiceNote(id, noteText);
      getServiceNotes()
      setServiceNoteModal(false)
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }
  const serviceAddNote = (
    <AddNote
      buttonAction={addServiceNote}
      id={(Number(location.state))}
      noteText={''}
      title={"Add Note"}
      buttonText={"Add Note"}
      onClick={() => setServiceNoteModal(false)}
      uid={null}
    />
  );
  //============================//

  // DISPATCH PARTS //
  const [addPartsModal, setAddPartsModal] = useState(false)
  const [dispatchParts, setDispatchParts] = useState([])

  const closePartsModal = () => {
    setAddPartsModal(false)
  }

  const getDispatchParts = async () => {
    try {
      const dispatchParts = await _getDispatchParts(Number(location.state))
      setDispatchParts(dispatchParts)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const updateDispatchParts = async (selectedParts: PartVM[]) => {
    //take the existing part
    const existingPart = dispatch.dispatchParts.map(e => e.part);
    //copy into new part
    const newParts = existingPart.slice(0, existingPart.length);
    //add each piece of part unless it's already added before
    selectedParts.forEach(elem => {
      if (!existingPart.some(ele => ele.id === elem.id))
        newParts.push(elem);
    });

    try {
      const mappedParts = newParts.map(elem => elem.id);
      await _updateDispatchParts(dispatch.id, mappedParts)
      await getDispatchParts()
      await getDispatch()
      enqueueSnackbar("Updated dispatch parts successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const addParts = (event) => {
    event.stopPropagation();
    setAddPartsModal(true)
  }

  const [dispatchItems, setDispatchItems] = useState([])

  const getDispatchItems = async () => {
    try {
      const dispatchitems = await _getDispatchItems(Number(location.state));
      setDispatchItems(dispatchitems);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const addItemToDispatch = async (itemDescription: string) => {
    try {
      await _addItemToDispatch((Number(location.state)), itemDescription)
      getDispatchItems()
      enqueueSnackbar("Added dispatch item successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const dispatchPartsUsed = (
    <DispatchPartsUsed
      dispatch={dispatch}
      closePartsModal={closePartsModal}
      updateDispatchParts={updateDispatchParts}
      addItemToDispatch={addItemToDispatch}
    />

  );
  //==================================//

  /// DISPATCH EQUIPMENT //
  const [addEquipmentModal, setAddEquipmentModal] = useState(false)
  const [dispatchEquipment, setDispatchEquipment] = useState([])


  const closeEquipmentModal = () => {
    setAddEquipmentModal(false)
  }

  const getDispatchEquipment = async () => {
    try {
      const dispatchEquipment = await _getDispatchEquipment(Number(location.state))
      setDispatchEquipment(dispatchEquipment);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const updateDispatchEquipment = async (selectedEquipment: EquipmentVM[]) => {
    //take the existing part
    const existingEquipment = dispatch.dispatchEquipments.map(e => e.equipment);
    //copy into new part
    const newEquipment = existingEquipment.slice(0, existingEquipment.length);
    //add each piece of part unless it's already added before
    selectedEquipment.forEach(elem => {
      if (!existingEquipment.some(ele => ele.id === elem.id))
        newEquipment.push(elem);
    });

    try {
      const mappedEquipment = newEquipment.map(elem => elem.id);
      await _updateDispatchEquipment(dispatch.id, mappedEquipment)
      await getDispatchEquipment()
      await getDispatch()
      enqueueSnackbar("Updated dispatch equipment successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const addEquipment = (event) => {
    event.stopPropagation();
    setAddEquipmentModal(true)
  }

  const dispatchEquipmentUsed = (
    <TechEquipmentAdd
      closeEquipmentModal={closeEquipmentModal}
      updateDispatchEquipment={updateDispatchEquipment}
    />
  );

  //==================================//

  const [cameraOn, setCameraOn] = React.useState(false);

  const toggleCameraOn = () => {
    if (!cameraOn) {
      camera.startCamera('video', 'offscreen-canvas')
    }
    setCameraOn((prev) => !prev);
  };

  const addDocument = async (files: File[]) => {
    try {
      for (let file of files) {
        await _addDocument(dispatchId, file)
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    getDispatchDocuments()
  }

  const takeAndUploadPhoto = async (url: string) => {
    try {
      const file = await urlToFile(url, `Photo-${dateTimeFormatPhotoTitle.format(new Date()).replaceAll('/', '-')}.png`, 'image/png')
      await _addDocument(dispatchId, file)
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    getDispatchDocuments()
  }

  const urlToFile = async (url: string, filename: string, mimeType: string) => {
    const data = await fetch(url);
    const buffer = await data.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  }

  const openAddSignatureView = () => {
    history.push({
      pathname: "/tech-dispatch-add-signature",
      state: { dispatchIdFromTechDispatchView: location.state }
    });
  }

  if (loader) {
    return (
      <div >
        <Grid className={classes.loaderRoot} justify="center" container>
          <CircularProgress />
        </Grid>
      </div>
    );
  }

  const changeVisitStatusHandler = async () => {
    const thereAreUnsynchedVisits = await client.areThereUnsynchedVisits()
    if (thereAreUnsynchedVisits && onlineState) {
      dispatchAction(setCachedRequestsSync(true))
      return;
    }
    if (visitStatus == "on-site") {
      history.push({
        pathname: "/tech-dispatch-clockout",
        state: { dispatchId: dispatch.id, visitId: ongoingVisitId }
      });
    } else if (visitStatus == "travelled") {
      history.push({
        pathname: "/tech-dispatch-clockin",
        state: { dispatchId: dispatch.id, visitId: ongoingVisitId }
      });
    } else {
      await techStartTravel();
      // refresh visits section
      await getVisits();
    }
  }

  const techStartTravel = async () => {
    try {
      const ongoingVisitId = await _visitStartTravel(dispatch.id);
      setOngoingVisitId(ongoingVisitId);
      setVisitStatus("travelled");
      enqueueSnackbar("Traveling towards site successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const renderChangeVisitStatusButtonText = () => {
    if (visitStatus == "not-travelled") return "TRAVEL";
    if (visitStatus == "travelled") return "ENTER SITE";
    if (visitStatus == "on-site") return "LEAVE";
  }

  const renderVisitStatusText = () => {
    if (visitStatus == "travelled") return "You are travelling towards the dispatch site.";
    if (visitStatus == "on-site") return "You are on site. Enter a signature before leaving. ";
  }

  const canUserLeaveSite = () => {
    if (dispatch.signatures.length !== 0) {
      const time = dispatch.signatures[dispatch.signatures.length - 1].created;
      const now = new Date();
      const diff = (now.valueOf() - time.valueOf()) / 60000;
      return diff < 30;
    }
    return false;
  }

  return (
    <div className={classes.root}>

      <Button startIcon={<ArrowBackIosIcon />} onClick={handleBackClick} color="primary">Back</Button>
      <Grid container>
        <Grid className={classes.header} item xs={12}>
          <Typography align="center" >{dateTimeFormat.format(dispatch.followUpDate)}</Typography>
          <Typography variant="h3" align="center"> DISPATCH WO# {dispatch.workOrderNumber}</Typography>
          <Typography variant="h2" align="center">{renderVisitStatusText()}</Typography>

          <Button className={classes.clockOutBtn} variant="contained" color="primary" disabled={!canUserLeaveSite() && visitStatus == "on-site"} onClick={changeVisitStatusHandler}>
            {renderChangeVisitStatusButtonText()}
          </Button>

        </Grid>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" className={`${classes.heading} ${classes.alignText}`}>Location</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              <TechLocation dispatchId={dispatch.id} dispatchLocationId={dispatch.locationId} />
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="h3" className={classes.heading}>Contacts</Typography>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              <TechContacts dispatchId={dispatch.id} dispatchLocationId={dispatch.locationId} />
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="h3" className={classes.heading}>Dispatch Details</Typography>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              <TechDispatchDetails dispatch={dispatch} />
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="h3" className={classes.heading}>Visits</Typography>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              <TechVisits questions={questions} visits={visits} />
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography variant="h3" className={classes.heading}>Service Request Codes ({dispatch.dispatchServiceRequestCodes.length})</Typography>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {
                dispatch.dispatchServiceRequestCodes.length === 0
                  ? 'No codes to display.'
                  : <TechServiceRequestCodes serviceRequestCodes={dispatch.dispatchServiceRequestCodes} />
              }
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h3" className={`${classes.heading} ${classes.alignText}`}>Dispatch Notes ({dispatchNotes.length})</Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={(event) => addDispatchNoteModal(event)}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary">
                  Add Note</Button>
              </Grid>
              {dispatchNoteModal && <FormDialog open={dispatchNoteModal} body={dispatchAddNote} />}
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {
                dispatchNotes.length === 0
                  ? 'No notes to display.'
                  : <TechNotes notes={dispatchNotes} />
              }
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h3" className={`${classes.heading} ${classes.alignText}`}>Service notes ({serviceNotes.length})</Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={(event) => addServiceNoteModal(event)}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary">
                  Add Note</Button>
              </Grid>
              {serviceNoteModal && <FormDialog open={serviceNoteModal} body={serviceAddNote} />}
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {
                serviceNotes.length === 0
                  ? 'No notes to display.'
                  : <TechNotes notes={serviceNotes} />
              }
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h3" className={`${classes.heading} ${classes.alignText}`}>Equipment ({dispatchEquipment.length})</Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={(event) => addEquipment(event)}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary">
                  Add Equipment</Button>
              </Grid>
              {addEquipmentModal && <FormDialog open={addEquipmentModal} body={dispatchEquipmentUsed} />}

            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {
                dispatchEquipment.length === 0
                  ? 'No equipment to display.'
                  : <TechEquipment dispatchEquipment={dispatchEquipment} />
              }
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h3" className={`${classes.heading} ${classes.alignText}`}>Parts / Items ({dispatchParts.length}/{dispatchItems.length})</Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={(event) => addParts(event)}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary">
                  Add Parts/Item</Button>
              </Grid>
              {addPartsModal && <FormDialog open={addPartsModal} body={dispatchPartsUsed} />}
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails>
              {
                dispatchParts.length === 0 && dispatchItems.length === 0
                  ? 'No parts or items to display.'
                  : <PartsItemsList dispatchParts={dispatchParts} dispatchItems={dispatchItems} />
              }
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h3" className={`${classes.heading} ${classes.alignText}`}>Documents ({dispatchDocuments.length})</Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <UploadButton
                  buttonType={UploadButtonType.TechAddDocument}
                  onDropAction={addDocument}
                  size="small"
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <Grid container className={classes.summaryContent}>
            <AccordionDetails style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={12}>
                  <FormGroup >
                    <FormControlLabel
                      control={<Switch color="primary" checked={cameraOn} onChange={toggleCameraOn} />}
                      label="Camera"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} style={{ display: cameraOn ? 'initial' : 'none' }}>
                  <video id='video' className={classes.videoSection}></video>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.takePhotoButton}
                    startIcon={<PhotoCameraIcon />}
                    onClick={() => takeAndUploadPhoto(camera.takeSnapshot())}
                  >Take and upload a photo</Button>
                </Grid>
                <Grid item xs={12}>
                  {
                    dispatchDocuments.length === 0
                      ? 'No documents to display.'
                      : <TechDocuments dispatchDocuments={dispatchDocuments} />
                  }
                </Grid>
                <canvas id='offscreen-canvas' style={{ display: 'none' }}></canvas>
              </Grid>
            </AccordionDetails>
          </Grid>
        </Accordion>

        <Accordion style={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h3" className={`${classes.heading} ${classes.alignText}`}>
                  Signatures ({dispatch.signatures.length})
                  </Typography>
              </Grid>
              <Grid className={classes.alignButtonRight} item xs={6}>
                <Button
                  onClick={openAddSignatureView}
                  onFocus={(event) => event.stopPropagation()}
                  color="primary">
                  Add Signature</Button>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {
              dispatch.signatures.length === 0
                ? 'No signatures to display.'
                : <SignatureSection signatures={dispatch.signatures} />
            }
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  );
}

