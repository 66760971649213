enum TechAction {
    ADD_LOCATION_NOTE = 'ADD_LOCATION_NOTE',
    CHANGE_DISPATCH_STATUS = 'CHANGE_DISPATCH_STATUS',
    ADD_DISPATCH_NOTE = 'ADD_DISPATCH_NOTE',
    ADD_SERVICE_NOTE = 'ADD_SERVICE_NOTE',
    UPDATE_DISPATCH_EQUIPMENT = 'UPDATE_DISPATCH_EQUIPMENT',
    ADD_EQUIPMENT_NOTE = 'ADD_EQUIPMENT_NOTE',
    UPDATE_DISPATCH_PARTS = 'UPDATE_DISPATCH_PARTS',
    ADD_ITEM_TO_DISPATCH = 'ADD_ITEM_TO_DISPATCH',
    ADD_DOCUMENT = 'ADD_DOCUMENT',
    ADD_SIGNATURE = 'ADD_SIGNATURE',
    VISIT_START_TRAVEL = 'VISIT_START_TRAVEL',
    VISIT_ENTER_SITE = 'VISIT_ENTER_SITE',
    VISIT_LEAVE_SITE = 'VISIT_LEAVE_SITE'
}
export default TechAction;