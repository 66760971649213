import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/Login/Login';
import MyAccount from './pages/MyAccount/MyAccount';
import Admin from '../src/pages/Admin/Admin';
import CustomerView from '../src/pages/Customer/CustomerView';
import LocationView from '../src/pages/Location/LocationView';
import Equipment from './pages/Equipment/Equipment';
import DispatchView from './pages/Dispatch/Dispatch';
import FollowUpView from '../src/pages/FollowUp/FollowUpView';
import { DispatchBoardView } from './pages/Board/DispatchBoardView';
import './App.scss';
import TechView from './pages/TechView/TechView';
import TechDispatch from './pages/TechDispatch/TechDispatchView';
import { AddSignatureView } from './pages/TechDispatch/Signature/AddSignatureView';
import TechClockIn from './components/tech/ClockInOut/ClockIn';
import TechClockOut from './components/tech/ClockInOut/ClockOut';
import Invoice from './pages/Invoice/Invoice';
import CompletedDispatchesView from './pages/CompletedDispatch/CompletedDispatchesView';
import InvoiceList from './pages/Invoice/InvoiceList';
import Header from './components/Header';
import PreviewInvoice from './pages/Invoice/PreviewInvoice/PreviewInvoice';
import UserActionLogs from './pages/UserActionLog/UserActionLogs';
import OfflineIcon from './components/common/offline/OfflineIcon';
import * as client from './helpers/offline/offline-api';
import UserRole from './helpers/constants/userRole';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { setOnlineState } from './global-state/actions/onlineStateActions';
import { setCachedRequestsSync } from './global-state/actions/cachedRequestsActions';
import TaxRatesView from './pages/TaxRates/TaxRatesView';
import TaxRatesReport from './pages/Reporting/TaxRatesReport';
import TechDispatchHistory from './pages/TechDispatchHistory/TechDispatchHistory';
import * as signalR from "@microsoft/signalr";
import { NotificationClient } from './brines-refrigerator-api';
import NotificationDialog from './components/common/dialog/NotificationDialog';

/**10 seconds */
const OFFLINE_CHECK_INTERVAL_TIME = 10000;

let userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');

const App = () => {
    const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
    const [notificationDialogData, setNotificationDialogData] = useState({ id: 0, text: "" });
    const notificationClient = new NotificationClient();
    const [connectionId, setConnectionId] = useState("");

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("/notificationHub")
        .build();

    connection.on("Notify", (notification: { id: 0, text: "" }) => {
        setNotificationDialogData(notification);
        setOpenNotificationDialog(true);
    });

    const closeNotificationDialog = async () => {
        await notificationClient.delete(notificationDialogData.id)
        setOpenNotificationDialog(false);
        setNotificationDialogData({ id: 0, text: "" });
    }

    const getConnectionId = () => {
        return connectionId;
    }

    useEffect(() => {
        if (userData && userData.user) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && isTechLoggedIn()) {
            (async () => {
                try {
                    await connection.start();
                    setConnectionId(connection.connectionId)
                } catch (e) {
                    console.error(e.toString());
                }
            })();
        }
        if (isAuthenticated && !cachedRequestsState) {
            dispatch(setCachedRequestsSync(true))
        }
    }, [isAuthenticated]);

    const setUserAuthenticated = (authenticated: boolean) => {
        setIsAuthenticated(authenticated);
    }

    //if passing other props to login use props as an argument for the arrow function and destructure them below in the component declaration
    const renderLogin = () => (
        <Login setUserAuthenticated={setUserAuthenticated} />
    )

    const ProtectedRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
            isAuthenticated
                ? <Component {...props} />
                : <Redirect to='/' />
        )} />
    );

    const isTechLoggedIn = (): boolean => {
        // ensure that we have the latest data from session storage
        userData = JSON.parse(sessionStorage.getItem('userData') || '{}');
        return isAuthenticated
            ? userData
                ? userData.role.name === UserRole.Technician
                : false
            : false;
    }

    const onlineState = useSelector(state => (state as { onlineStateReducer: { currState: { online: boolean } } }).onlineStateReducer.currState.online, shallowEqual);
    const prevOnlineState = useSelector(state => (state as { onlineStateReducer: { prevState: { online: boolean } } }).onlineStateReducer.prevState.online, shallowEqual);
    const cachedRequestsState = useSelector(state => (state as { cachedRequestsReducer: { currState: { sync: boolean } } }).cachedRequestsReducer.currState.sync, shallowEqual);
    const prevcachedRequestsState = useSelector(state => (state as { cachedRequestsReducer: { prevState: { sync: boolean } } }).cachedRequestsReducer.prevState.sync, shallowEqual);
    const dispatch = useDispatch();
    const [getDataForOffline] = client.useCachingForOffline()

    useEffect(() => {
        if (!prevOnlineState && onlineState && isTechLoggedIn()) {
            // from offline to online
            try {
                dispatch(setCachedRequestsSync(true))
            } catch (error) {
                console.log(error.message)
            }
        } else if (prevOnlineState && !onlineState && isTechLoggedIn()) {
            // from online to offline
            setTimeout(checkInternetAcces, OFFLINE_CHECK_INTERVAL_TIME)
        }
    }, [onlineState]);

    useEffect(() => {
        if (!prevcachedRequestsState && cachedRequestsState) {
            try {
                tryToFireCachedRequests()
            } catch (error) {
                console.log(error.message)
            }
        } else if (prevcachedRequestsState && !cachedRequestsState) {
            setFireRequests(false)
        }
    }, [cachedRequestsState]);

    const checkInternetAcces = async () => {
        // if tech is not logged in, we don't want the offline icon to show up
        let result = true;
        if (isTechLoggedIn()) {
            result = await client.ping();
        } else {
            return;
        }
        dispatch(setOnlineState(result))
        if (!result && isTechLoggedIn()) {
            setTimeout(checkInternetAcces, OFFLINE_CHECK_INTERVAL_TIME)
        }
    }

    const tryToFireCachedRequests = async () => {
        const requests = (await client.getCachedRequests()).map(e => ({
            id: e.uid,
            action: e.actionType,
            payload: e.payload,
            timestamp: dateTimeFormat.format(e.timestamp),
            tableData: { checked: true }
        }))
        if (requests.length > 0 && isTechLoggedIn()) {
            try {
                await client.fireQueuedRequests()
                getDataForOffline()
            } catch (error) {
                console.log(error.message)
            }
        }
        dispatch(setCachedRequestsSync(false))
    }
    const [fireRequests, setFireRequests] = useState(false);

    return (
        <BrowserRouter>
            {fireRequests}
            <NotificationDialog open={openNotificationDialog} handleClose={closeNotificationDialog} text={notificationDialogData.text} />
            {isAuthenticated && <Header setUserAuthenticated={setUserAuthenticated} getConnectionId={getConnectionId} />}
            {isTechLoggedIn() && <OfflineIcon isOffline={!onlineState} />}
            <Switch>
                {!isAuthenticated
                    ? <Route exact path='/' render={renderLogin} />
                    : onlineState
                        ? <Route exact path='/' component={MyAccount} />
                        : <Route exact path='/' component={TechView} />}
                <ProtectedRoute path='/my-account' component={MyAccount} />
                <ProtectedRoute path='/admin' component={Admin} />
                <ProtectedRoute path='/customers' component={CustomerView} />
                <ProtectedRoute path='/locations' component={LocationView} />
                <ProtectedRoute path='/equipment' component={Equipment} />
                <ProtectedRoute path='/dispatch' component={DispatchView} />
                <ProtectedRoute path='/completed-dispatches' component={CompletedDispatchesView} />
                <ProtectedRoute path='/dispatch-board' component={DispatchBoardView} />
                <ProtectedRoute path='/invoices' component={InvoiceList} />
                <ProtectedRoute path='/invoice' component={Invoice} />
                <ProtectedRoute path='/preview-invoice' component={PreviewInvoice} />
                <ProtectedRoute path='/tech-view' component={TechView} />
                <ProtectedRoute path='/tech-dispatch' component={TechDispatch} />
                <ProtectedRoute path='/tech-dispatch-history' component={TechDispatchHistory} />
                <ProtectedRoute path='/tech-dispatch-add-signature' component={AddSignatureView} />
                <ProtectedRoute path='/tech-dispatch-clockin' component={TechClockIn} />
                <ProtectedRoute path='/tech-dispatch-clockout' component={TechClockOut} />
                <ProtectedRoute path='/follow-up' component={FollowUpView} />
                <ProtectedRoute path='/reporting' component={TaxRatesReport} />
                <ProtectedRoute path='/logs' component={UserActionLogs} />
                <ProtectedRoute path='/tax-rates' component={TaxRatesView} />
            </Switch>
        </BrowserRouter>
    )
}

export default App;
