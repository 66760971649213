import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SwaggerException } from "../../../brines-refrigerator-api";

export default class CustomDispatchDocumentsClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    create(dispatchDocument: DispatchDocumentAddVM | undefined) : Promise<void> {
        let url_ = this.baseUrl + "/api/v1/DispatchDocuments/Create";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = new FormData();
        content_.append('dispatchId', dispatchDocument.dispatchId.toString());
        content_.append('name', dispatchDocument.name);
        content_.append('file', dispatchDocument.file, dispatchDocument.file.name);
        content_.append('isConfidential', dispatchDocument.isConfidential.toString());

        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_
        };

        return this.instance.request(options_).then((_response: AxiosResponse) => {
            return this.processCreate(_response);
        });
    }

    protected processCreate(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new SwaggerException(message, status, response, headers, null);
}

export interface IDispatchDocumentAddVM {
    dispatchId: number;
    name: string;
    isConfidential?: boolean;
    file: File;
}

export class DispatchDocumentAddVM {
    dispatchId: number;
    name!: string;
    isConfidential?: boolean;
    file: File;

    constructor(data?: IDispatchDocumentAddVM) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.dispatchId = _data["dispatchId"];
            this.name = _data["name"];
            this.isConfidential = _data["isConfidential"];
            this.file = _data["file"];
        }
    }

    static fromJS(data: any): DispatchDocumentAddVM {
        data = typeof data === 'object' ? data : {};
        let result = new DispatchDocumentAddVM();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["dispatchId"] = this.dispatchId;
        data["name"] = this.name;
        data["isConfidential"] = this.isConfidential;
        data["file"] = this.file;
        return data;
    }
}
