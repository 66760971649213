import React, { useState, Fragment } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, TextField, Tabs, Tab, Button } from "@material-ui/core";
import SignatureCanvas from 'react-signature-canvas'
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSnackbar, SnackbarKey } from "notistack";
import * as client from '../../../helpers/offline/offline-api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "fit-content",
        },
        body: {
            '& > *': {
                margin: theme.spacing(1),
                width: "95vw",
            },
        },
        canvas: {
            outline: 'black 0.0625rem solid',
            width: "95vw",
            height: "40vh",
        }
    }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

export function AddSignatureView() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [tabValue, setTabValue] = useState(0);
    const [signatureName, setSignatureName] = useState('');
    const [signatureTextfield, setSignatureTextfield] = useState('');
    const [signaturePad, setSignaturePad] = useState<SignatureCanvas>();
    const mimeType = 'image/png';
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [_addSignature] = client.useAddSignature()

    function handleClick() {
        history.goBack();
    }

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue)
        if (newValue === 0) {
            setSignatureTextfield('')
        }
    };

    const action = (key: SnackbarKey) => (
        <Fragment>
            <Button onClick={() => { closeSnackbar(key) }}>
                Dismiss
            </Button>
        </Fragment>
    );

    const submit = async () => {
        switch (tabValue) {
            case 0:
                if (signaturePad.isEmpty() || signatureName === '') {
                    if (signaturePad.isEmpty()) {
                        enqueueSnackbar("Signature can't be empty.", { variant: "error", action });
                    }
                    if (signatureName === '') {
                        enqueueSnackbar("Name can't be empty.", { variant: "error", action });
                    }

                } else {
                    await _addSignature(
                        (location.state as { dispatchIdFromTechDispatchView: number }).dispatchIdFromTechDispatchView,
                        signatureName,
                        '',
                        await urlToFile(signaturePad.getTrimmedCanvas().toDataURL(mimeType), `${signatureName}.png`, mimeType)
                    )
                    enqueueSnackbar("Successfully added signature.", { variant: "success", action });
                    history.goBack();
                }
                break;
            case 1:
                if (signatureTextfield === '' || signatureName === '') {
                    if (signatureTextfield === '') {
                        enqueueSnackbar("Signature can't be empty.", { variant: "error", action });
                    }
                    if (signatureName === '') {
                        enqueueSnackbar("Name can't be empty.", { variant: "error", action });
                    }
                } else {
                    await _addSignature(
                        (location.state as { dispatchIdFromTechDispatchView: number }).dispatchIdFromTechDispatchView,
                        signatureName,
                        signatureTextfield,
                        null
                    )
                    enqueueSnackbar("Successfully added signature.", { variant: "success", action });
                    history.goBack();
                }
                break;
            default:
                break;
        }
    }

    const urlToFile = async (url: string, filename: string, mimeType: string) => {
        const data = await fetch(url);
        const buffer = await data.arrayBuffer();
        return new File([buffer], filename, { type: mimeType });
    }

    return (
        <div className={classes.root}>
            <Button startIcon={<ArrowBackIosIcon />} onClick={handleClick} color="primary">Back</Button>
            <div className={classes.body}>
                <Typography variant="h3">Add Signature</Typography>
                <TextField label="Name" variant="outlined" value={signatureName} onChange={(e) => setSignatureName(e.target.value)} />
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Signature" />
                    <Tab label="Text" />
                </Tabs>
                <TabPanel value={tabValue} index={0} >
                    <SignatureCanvas
                        backgroundColor='white'
                        canvasProps={{ className: classes.canvas }}
                        ref={(ref) => { setSignaturePad(ref) }}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1} >
                    <TextField fullWidth label="Signature" variant="outlined" value={signatureTextfield} onChange={(e) => setSignatureTextfield(e.target.value)} />
                </TabPanel>
                <Button variant="contained" color="primary" onClick={submit}>Submit</Button>
            </div>
        </div>
    )
}