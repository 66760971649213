import React, { useState, useEffect, useReducer } from "react";
import { Grid, Paper } from "@material-ui/core";
import BasicTable from "../../components/common/table/BasicTable";
import { DispatchTableVM, DispatchClient, TradeClient, TradeVM, DispatchStatusClient, CustomerClient, LocationClient, PriorityClient, PriorityTypeVM, UserClient, ServiceRequestCodeClient, DispatchVM, DispatchUpdateVM, InvoiceClient, DispatchSecondaryStatusClient } from "../../brines-refrigerator-api";
import Edit from '@material-ui/icons/Edit';
import DispatchBoardCRUDForm from "../Board/DispatchBoardCRUDForm/DispatchBoardCRUDForm";
import { Color } from "@material-ui/lab/Alert";
import SnackbarNotification from "../../components/common/snackbar/SnackbarNotification";
import { useHistory } from "react-router-dom";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Visits from "../../components/dispatch/visitsModal/visits";
import FormDialog from "../../components/common/dialog/FormDialog";
import ConfirmDialog from "../../components/common/dialog/ConfirmationDialog";

interface DispatchBoardCRUDFormState {
  dispatch: DispatchVM
}

export default function CompletedDispatchesView() {
  const history = useHistory();


  const [trades, setTrades] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secondaryStatuses, setSecondaryStatuses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [serviceRequestCodes, setServiceRequestCodes] = useState([]);

  const [dispatches, setDispatches] = useState([]);
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const [tableIsLoading, setTableLoading] = useState(true);

  // ERROR HANDLING //
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<Color>()

  const setSnackBarState = (state: boolean, message: string, severity: Color) => {
    setSnackbarOpen(state)
    setSnackbarMessage(message)
    setSeverity(severity)
  }

  //confirm dialogue//
  const [confirm, setConfirmOpen] = useState(false);
  const [openedDispatchId, setOpenedDispatchId] = useState(null);
  const [openedLocationId, setOpenedLocationId] = useState(null);

  const redirectToInvoice = async (dispatchId: number) => {
    setOpenedDispatchId(dispatchId);

    const locationsClient = new LocationClient();
    const result = await locationsClient.checkTaxRatesSetByDispatch(dispatchId);

    setOpenedLocationId(result.id);

    if (result.areTaxRatesSet) {
      const invoiceClient = new InvoiceClient();
      const invoice = await invoiceClient.post(dispatchId);
      history.push({
        pathname: "/invoice",
        state: { invoiceId: invoice.id }
      });
    } else {
      setConfirmOpen(true);
    }
  }

  const confirmTaxRatesSetDialogue = async () => {
    history.push({
      pathname: "/tax-rates",
      state: { locationId: openedLocationId }
    });
  }

  const cancelTaxRatesSetDialogue = async () => {
    const invoiceClient = new InvoiceClient();
    const invoice = await invoiceClient.post(openedDispatchId);
    history.push({
      pathname: "/invoice",
      state: { invoiceId: invoice.id }
    });
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const [state, setState] = useReducer(
    (state: DispatchBoardCRUDFormState, newState: DispatchBoardCRUDFormState) => ({ ...state, ...newState }),
    {
      dispatch: new DispatchVM({
        id: null,
        created: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        followUpDate: null,
        statusId: null,
        secondaryStatusId: null
      })
    }
  );

  async function getTrades() {
    const tradesClient = new TradeClient();
    const trades = await tradesClient.getAll();
    trades.push(new TradeVM({
      id: 0,
      name: 'N/A',
      hexCode: 'BCBCBC'
    }));
    setTrades(trades);
  };

  async function getStatuses() {
    const statusesClient = new DispatchStatusClient();
    const statuses = await statusesClient.getAll();
    setStatuses(statuses);
  }

  async function getSecondaryStatuses() {
    const statusesClient = new DispatchSecondaryStatusClient();
    const statuses = await statusesClient.getAll();
    setSecondaryStatuses(statuses);
  }

  async function getCustomers() {
    const customersClient = new CustomerClient();
    const customers = await customersClient.getCustomersBase();
    setCustomers(customers);
  }

  async function getLocations() {
    const locationsClient = new LocationClient();
    const locations = await locationsClient.getLocationsDispatchHq();
    setLocations(locations);
  }

  async function getPriorities() {
    const prioritiesClient = new PriorityClient();
    const priorities = await prioritiesClient.get();
    priorities.push(new PriorityTypeVM({
      id: 0,
      name: 'N/A',
      hexCode: 'BCBCBC'
    }));
    setPriorities(priorities);
  }

  async function getUsers() {
    const usersClient = new UserClient();
    const users = await usersClient.users();
    setUsers(users);
  }

  const getServiceRequestCodes = async () => {
    const serviceRequestCodesClient = new ServiceRequestCodeClient();
    const serviceRequestCodes = await serviceRequestCodesClient.get();
    setServiceRequestCodes(serviceRequestCodes)
  }

  const populateCRUDForm = async () => {
    await getTrades();
    await getStatuses();
    await getSecondaryStatuses();
    await getCustomers();
    await getLocations();
    await getPriorities();
    await getUsers();
    await getServiceRequestCodes();
  }

  useEffect(() => {
    populateCRUDForm();
  }, [])


  const setDispatchForEditing = async (dispatchId: number) => {
    const dispatchClient = new DispatchClient()

    const selectedDispatch = await dispatchClient.getById(dispatchId);
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: selectedDispatch.id,
        locationId: selectedDispatch.locationId,
        created: selectedDispatch.created,
        priorityId: selectedDispatch.priorityId,
        tradeId: selectedDispatch.tradeId,
        workOrderNumber: selectedDispatch.workOrderNumber,
        slaDate: selectedDispatch.slaDate,
        tehnicianId: selectedDispatch.tehnicianId,
        followUpDate: selectedDispatch.followUpDate,
        statusId: selectedDispatch.statusId,
        secondaryStatusId: selectedDispatch.secondaryStatusId
      })
    })
  }

  const updateDispatch = async (props: DispatchUpdateVM) => {
    const dispatchClient = new DispatchClient()

    try {
      const dispatch = await dispatchClient.put(new DispatchUpdateVM({
        ...props
      }));
      await setDispatchForEditing(dispatch.id);
      getDispatches()
      clearFields()
      setSnackBarState(true, 'Dispatch updated successfully.', "success")
    }
    catch (err) {
      setSnackBarState(true, 'Error while updating dispatch.', "error")
    }
  }

  async function getDispatches() {
    try {
      const dispatchClient = new DispatchClient()

      const dispatches = await dispatchClient.get(undefined, 6, undefined);
      const formattedDispatches = dispatches.map((e: DispatchTableVM) => ({
        key: Number(e.id),
        id: Number(e.id),
        date: e.created ? dateTimeFormat.format(e.created) : 'Unassigned',
        locationName: e.location.name,
        locationZip: e.location.zip,
        equipment: e.dispatchEquipments.length > 0 ? e.dispatchEquipments.map(x => x.equipment.model) : 'Unassigned'
      }));
      setDispatches(formattedDispatches);
      setTableLoading(false);
    } catch (error) {
      setSnackBarState(true, 'Error while getting dispatch.', "error")
    }
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      await getDispatches();
    }
    fetchDataAsync()
  }, []);

  const openDispatchVisits = async (dispatchId: number) => {
    setVisitsSignaturesModal({ open: true, dispatchId: dispatchId });
  }

  const [visitsSignaturesModal, setVisitsSignaturesModal] = useState({ open: false, dispatchId: 0 });

  const visitsSignaturesModalBody = (
    <>
      <Visits setModalState={setVisitsSignaturesModal} dispatchId={visitsSignaturesModal.dispatchId} technicians={users} />
    </>
  );

  const completedDispatchColums = [
    { title: 'Id', field: 'id' },
    { title: 'Date', field: 'date' },
    { title: 'Location', field: 'locationName' },
    { title: 'Zip', field: 'locationZip' },
    { title: 'Equipment', field: 'equipment' }
  ];

  const tableActions = [
    rowData => ({
      icon: () => <DriveEtaIcon color='primary' />,
      onClick: (event, rowData: unknown) => { openDispatchVisits((rowData as { id: number }).id) },
      tooltip: "View Visits"
    }),
    rowData => ({
      icon: () => <Edit color='primary' />,
      onClick: (event, rowData: unknown) => { setDispatchForEditing((rowData as { id: number }).id) },
      tooltip: "Edit Dispatch"
    }),
  ]

  const clearFields = () => {
    setState({
      ...state,
      dispatch: new DispatchVM({
        id: null,
        locationId: null,
        priorityId: null,
        tradeId: null,
        workOrderNumber: null,
        slaDate: null,
        tehnicianId: null,
        followUpDate: null,
        statusId: null,
        secondaryStatusId: null
      })
    });
  }

  return (
    <div>
      <SnackbarNotification
        open={snackbarOpen}
        snackbarMessage={snackbarMessage}
        handleClose={handleClose}
        severity={severity}
      />
      <Grid container>
        <Grid item sm={6}>
          <BasicTable
            width={"100%"}
            title={"Completed Dispatches"}
            data={dispatches as []}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
            }}
            columns={completedDispatchColums}
            paging={true}
            isLoading={tableIsLoading}
            actions={tableActions}
          />
        </Grid>

        <Grid item sm={6}>
          <DispatchBoardCRUDForm
            trades={trades}
            statuses={statuses}
            secondaryStatuses={secondaryStatuses}
            customers={customers}
            locations={locations}
            priorities={priorities}
            technicians={users}
            serviceRequestCodes={serviceRequestCodes}
            dispatch={state.dispatch}
            formAction={updateDispatch}
            clearFields={clearFields}
            showGenerateInvoiceButton={true}
            redirectToInvoice={redirectToInvoice}
          />
        </Grid>
        {confirm && <ConfirmDialog
          title={"Set tax rates?"}
          open={confirm}
          setOpen={setConfirmOpen}
          onConfirm={() => { confirmTaxRatesSetDialogue() }}
          onCancel={() => { cancelTaxRatesSetDialogue() }}
        >
          {"Tax rates haven't been set for this dispatch's location. Would you like to set them?"}
        </ConfirmDialog>}
      </Grid>
      {visitsSignaturesModal && <FormDialog open={visitsSignaturesModal.open} body={visitsSignaturesModalBody} />}
    </div>
  );
}