import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField, withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { DispatchVM, DispatchStatusVM } from '../../brines-refrigerator-api';
import SnackbarNotification from '../common/snackbar/SnackbarNotification';
import { Color } from '@material-ui/lab/Alert/Alert';
import * as client from '../../helpers/offline/offline-api';

interface TechDispatchDetailsProps {
  dispatch: DispatchVM;
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#000000',
    },
  },
})(TextField);


const ListComponent: React.FC<{ backgroundColor: string, text?: string, textColor?: string }> = ({ backgroundColor, text, textColor }) => (
  <div className='priority_and_trades_form_add_data_container_pick_color_text'
    style={{
      backgroundColor,
      width: '100%',
      height: "2.375em",
      color: textColor
    }}>{text}</div>
)

export default function TechDispatchDetails(props: TechDispatchDetailsProps) {
  const priorityHexCode = props.dispatch.priorityId ? props.dispatch.priority.hexCode : '';
  const tradeyHexCode = props.dispatch.tradeId ? props.dispatch.trade.hexCode : '';
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState((props.dispatch.statusId) ? props.dispatch.statusId : 1)  //1 is unassigned === default
  const [_changeDispatchStatus] = client.useChangeDispatchStatus()
  const [_getStatuses] = client.useGetStatuses()

  const getStatuses = async () => {
    try {
      const statuses = await _getStatuses();
      setStatuses(statuses);
    } catch (error) {
      console.log(error.message);
    }
  }

  const ChangeDispatchStatus = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value as number)
    try {
      await _changeDispatchStatus(props.dispatch, event.target.value as number)
      setSnackBarState(true, 'Dispatch status changed.', "success")
    }
    catch (error) {
      setSnackBarState(true, 'Error changing dispatch status.', "error")
    }
  }

  useEffect(() => {
    getStatuses()
  }, [props]);

  /** error handling */
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<Color>()

  const setSnackBarState = (state: boolean, message: string, severity: Color) => {
    setSnackbarOpen(state)
    setSnackbarMessage(message)
    setSeverity(severity)
  }

  const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Grid container item xs={12}>
      <Grid xs={6} container item>
        <Grid xs={12} item>
          <CustomTextField
            disabled
            id="outlined-disabled"
            label="WO#"
            defaultValue={props.dispatch.workOrderNumber}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>

        <Grid xs={12} item>
          <CustomTextField
            disabled
            id="outlined-disabled"
            label="Technician"
            defaultValue={props.dispatch.tehnicianId}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>

        <Grid xs={12} item>
          <CustomTextField
            disabled
            id="outlined-disabled"
            label="Date Created"
            defaultValue={dateTimeFormat.format(props.dispatch.created)}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>

        <Grid xs={12} item>
          <FormControl variant="outlined" className='fw-select'>
            <InputLabel>Status</InputLabel>
            <Select
              fullWidth
              className='fw-select'
              label='Status'
              value={selectedStatus}
              onChange={(e) => ChangeDispatchStatus(e)}
            >
              {statuses.map((e: DispatchStatusVM) => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid xs={6} container item>
        <Grid xs={12} container item>
          <Grid xs={6} style={{ marginBottom: "10%" }}>
            <FormControl variant='outlined' className='fw-select'>
              <Typography>Priority</Typography>
              {
                <ListComponent backgroundColor={`#${priorityHexCode}`} />
              }
            </FormControl>
          </Grid>
          <Grid xs={6}>
            <FormControl variant='outlined' className='fw-select'>
              <Typography>Trades</Typography>
              {
                <ListComponent backgroundColor={`#${tradeyHexCode}`} />
              }
            </FormControl>
          </Grid>
        </Grid>

        <Grid xs={12} item >
          <CustomTextField
            disabled
            id="outlined-basic"
            label="SLA date"
            defaultValue={props.dispatch.slaDate ? dateTimeFormat.format(props.dispatch.slaDate) : ''}
            size="small"
            InputProps={{ disableUnderline: true }}
          />

        </Grid>
        <Grid xs={12} item >
          <CustomTextField
            disabled
            id="outlined-basic"
            label="Follow Up/Due date"
            defaultValue={props.dispatch.followUpDate ? dateTimeFormat.format(props.dispatch.followUpDate) : ''}
            size="small"
            InputProps={{ disableUnderline: true }}
          />
        </Grid>

        <SnackbarNotification
          open={snackbarOpen}
          snackbarMessage={snackbarMessage}
          handleClose={handleSnackBarClose}
          severity={severity}
        />
      </Grid>
    </Grid>
  );
}