import { Button, Checkbox, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Autocomplete, Color } from '@material-ui/lab';
import React, { useEffect, useReducer, useState } from 'react';
import { RoleClient, StateClient, UpdateUserVM, UserClient, UserCreateVM, UserNoteVM, UserNewPasswordVM } from '../../../../brines-refrigerator-api';
import { useTextValidation, usePasswordValidation, useEmailValidation, useSelectValidation } from '../../../../helpers/validations';
import SnackbarNotification from '../../../common/snackbar/SnackbarNotification';
import './UserCRUDForms.scss';

const useStyles = makeStyles({
    bottomBorder: {
        borderBottom: "2px solid rgb(97, 97, 97)"
    },
    marginTop: {
        marginTop: "0.6875rem"
    },
    crudMarginTop: {
        marginTop: "1em"
    },
    marginBottom: {
        marginBottom: "1.5em"
    }
});

interface CountryType {
    abbreviation: string;
    name: string;
    id: string;
}

interface UserFormProps {
    user: {
        id: number;
        role: string;
        userName: string;
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        state: any;
        active: boolean;
        note: string | undefined;
    },
    refreshUsersTable: Function
}

const UserCRUDForms = (props: UserFormProps) => {

    const classes = useStyles();
    const loggedInUser = JSON.parse(sessionStorage.getItem('userData') || '{}');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState<Color>()

    const setSnackBarState = (state: boolean, message: string, severity: Color) => {
        setSnackbarOpen(state)
        setSnackbarMessage(message)
        setSeverity(severity)
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    useEffect(() => {
        (async () => {
            const stateClient = new StateClient();
            const states = await stateClient.get();
            const rolesClient = new RoleClient();
            const roles = await rolesClient.getAll();
            setState({ states, roles, formsAreDisabled: true });
        })()
    }, []);

    const [formData, setState] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            id: 0,
            isActive: false,
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: '',
            note: '',
            password: '',
            states: [],
            roles: [],
            selectedState: { abbreviation: '', name: '', id: 0 },
            formDisabled: true
        },
    );

    useEffect(() => {
        if (props.user) {
            setState({
                id: props.user.id,
                isActive: props.user.active,
                userName: props.user.userName,
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                email: props.user.email,
                phoneNumber: props.user.phone,
                role: props.user.role,
                note: props.user.note ? props.user.note : '',
                selectedState: { abbreviation: props.user.state.abreviation, name: props.user.state.name, id: props.user.state.id },
                formDisabled: false
            });
        }
    }, [props.user])

    const addNewUser = async () => {
        if (validateAddUserFields()) {

            const email: string = addUserEmail;
            const password: string = addUserPassword;

            const user = new UserCreateVM({
                userName: email,
                email: email,
                password: password,
                role: addUserRole
            });

            try {
                const userClient = new UserClient();
                await userClient.create(user);
                setSnackBarState(true, 'Success adding user.', "success")
                props.refreshUsersTable();
            } catch (error) {
                setSnackBarState(true, 'Error adding user.', "error")
            }
        }
    }

    const updateUserProfile = async () => {
        if (validateAllFields()) {

            // const getRowVersion: any = await userClient.userById(id);
            const user = new UpdateUserVM({
                id: formData.id,
                userName: formData.userName,
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phoneNumber: formData.phoneNumber,
                isActive: formData.isActive,
                note: new UserNoteVM({
                    text: formData.note
                }),
                role: formData.role,
                stateId: formData.selectedState.id ? Number(formData.selectedState.id) : null
            });

            const userClient = new UserClient();

            await userClient.updateAdmin(user).then(() => {
                if (formData.password !== '') {
                    userClient.setPassword(new UserNewPasswordVM({
                        id: formData.id,
                        password: formData.password,
                        repeatPassword: formData.password
                    }));
                }
                setSnackBarState(true, 'Success updating user.', "success");
                resetUpdateUserFields();
                props.refreshUsersTable();
            }).catch(() => {
                setSnackBarState(true, 'Error updating user.', "error");
            });
        }
    }

    //VALIDATION
    const [usernameInputProps, triggerUsernameValidation, resetUsernameInputProps] = useTextValidation();
    const [passwordInputProps, triggerPasswordValidation, resetPasswordInputProps] = usePasswordValidation();
    const [emailInputProps, triggerEmailValidation, resetEmailInputProps] = useEmailValidation();

    const handleUsernameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setState({ userName: e.target.value });
        if (usernameInputProps.error) triggerUsernameValidation(formData.userName);
    };

    const handleEmailChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setState({ email: e.target.value });
        if (emailInputProps.error) triggerEmailValidation(formData.userEmail);
    };

    const validateAllFields = (): boolean => {
        const username = triggerUsernameValidation(formData.userName)
        const email = triggerEmailValidation(formData.email);

        const password = formData.password ? triggerPasswordValidation(formData.password) : false;

        return (!username && !email && !password);
    }

    const handlePasswordChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const passwordString = (e.target.value as string).trim();
        setState({ password: passwordString });
        if (passwordString) {
            if (passwordInputProps.error) triggerPasswordValidation(e.target.value);
        } else {
            resetPasswordInputProps();
        }
    }

    const [addUserPasswordInputProps, triggerAddUserPasswordValidation, resetAddUserPasswordInputProps] = usePasswordValidation();
    const [addUserEmailInputProps, triggerAddUserEmailValidation, resetAddUserEmailInputProps] = useEmailValidation();
    const [addUserRoleInputProps, triggerAddUserRoleValidation, resetAddUserRoleInputProps] = useSelectValidation();

    const validateAddUserFields = (): boolean => {
        const email = triggerAddUserEmailValidation(addUserEmail);
        const password = triggerAddUserPasswordValidation(addUserPassword);
        const role = triggerAddUserRoleValidation(addUserRole);


        return (!email && !password && !role);
    }

    const [addUserEmail, setAddUserEmail] = useState('');
    const [addUserPassword, setAddUserPassword] = useState('');
    const [addUserRole, setAddUserRole] = useState('Technician');

    const handleAddUserEmailChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const email = e.target.value as string;
        setAddUserEmail(email);
        if (addUserEmailInputProps.error) triggerAddUserEmailValidation(email);
    };

    const handleAddUserPasswordChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const passwordString = (e.target.value as string).trim();
        setAddUserPassword(passwordString);
        triggerAddUserPasswordValidation(passwordString);
    }

    const handleAddUserRoleChange = (event: React.ChangeEvent<{ value: unknown }>, value: string) => {
        setAddUserRole(value);
        if (addUserRoleInputProps.error) triggerAddUserRoleValidation();
    }

    const submitAddUser = async () => {
        await addNewUser();
        resetAddUserFields();
    }

    const submitUpdateUser = async () => {
        await updateUserProfile();
    }

    const resetAddUserFields = () => {
        setAddUserEmail('');
        setAddUserPassword('');
    }

    const resetUpdateUserFields = () => {
        setState({
            id: 0,
            isActive: false,
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            role: '',
            note: '',
            password: '',
            selectedState: { abbreviation: '', name: '', id: 0 },
            formDisabled: true
        });
    }

    return (
        <>
            <Grid container justify='center' alignItems='flex-start' spacing={1} className={`user_crud_forms_container ${classes.crudMarginTop}`}>
                <Grid item sm={6} xs={12} className='user_crud_form'>
                    <Paper className='user_crud_paper' elevation={0}>
                        <Typography variant="h2">Update User Profile</Typography>
                        <Grid container alignItems='center' className={`user_crud_checkbox_container ${classes.bottomBorder} ${classes.marginTop}`}>
                            <Checkbox
                                checked={formData.isActive !== "" && formData.isActive}
                                color='primary'
                                onChange={(e, value) => { setState({ isActive: value }) }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                disabled={formData.id ? formData.id === loggedInUser.user.id : true}
                            />
                            <Typography>Active</Typography>
                        </Grid>
                        <Grid container justify='center'>
                            <Grid container direction='column' justify='space-evenly' className='user_crud_form_fields'>
                                <Autocomplete
                                    disabled={formData.id === loggedInUser.user.id || formData.formDisabled}
                                    options={formData.roles.map((options: any) => options.name)}
                                    value={formData.role}
                                    onChange={(e: any, value: object) => setState({ role: value })}
                                    renderInput={(params) => <TextField {...params} label="Role" variant="outlined" />}
                                />
                                <TextField type='text' name='user_name' label='Username' variant='outlined' value={formData.userName}
                                    disabled={formData.formDisabled}
                                    onChange={handleUsernameChange}
                                    onBlur={() => { triggerUsernameValidation(formData.userName) }}
                                    error={usernameInputProps.error}
                                />
                                <span className={`validation_error ${classes.marginBottom}`} hidden={!usernameInputProps.error}>
                                    {usernameInputProps.errorMessage}
                                </span>

                                <TextField type='email' name='email' label='E-mail' variant='outlined'
                                    value={formData.email}
                                    disabled={formData.formDisabled}
                                    onChange={handleEmailChange}
                                    onBlur={() => { triggerEmailValidation(formData.email) }}
                                    error={emailInputProps.error}
                                />
                                <span className={`validation_error ${classes.marginBottom}`} hidden={!emailInputProps.error}>
                                    {emailInputProps.errorMessage}
                                </span>

                                <TextField type='text' name='first_name' label='First name' variant='outlined' value={formData.firstName ? formData.firstName : ''}
                                    disabled={formData.formDisabled}
                                    onChange={(e: any) => setState({ firstName: e.target.value })} />

                                <TextField type='text' name='last_name' label='Last name' variant='outlined' value={formData.lastName ? formData.lastName : ''}
                                    disabled={formData.formDisabled}
                                    onChange={(e: any) => setState({ lastName: e.target.value })} />

                                <TextField
                                    disabled={formData.formDisabled}
                                    type='tel'
                                    name='phone'
                                    label='Phone'
                                    variant='outlined'
                                    value={formData.phoneNumber !== undefined ? formData.phoneNumber : ''}
                                    onChange={(e: any) => setState({ phoneNumber: e.target.value })}
                                    inputProps={{ maxLength: 10 }}
                                />
                                <Autocomplete
                                    options={formData.states as CountryType[]}
                                    disabled={formData.formDisabled}
                                    getOptionLabel={(option) => { return `${option.name || formData.selectedState.name}` }}
                                    value={formData.selectedState.name}
                                    onChange={(e: any, value: any) => setState({
                                        selectedState: {
                                            abbreviation: value?.abbreviation,
                                            id: Number(value?.id),
                                            name: value?.name
                                        }
                                    })}
                                    renderOption={(option) => (
                                        <span>({option.abbreviation}) {option.name}</span>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="State"
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                                <TextField type='text' name='user_password' label='Password' variant='outlined' className='hide_password_input_field'
                                    disabled={formData.formDisabled}
                                    onChange={handlePasswordChange}
                                    onBlur={() => { if (formData.password) triggerPasswordValidation(formData.password) }}
                                    error={passwordInputProps.error}
                                    value={formData.password}
                                />
                                <span className={`validation_error ${classes.marginBottom}`} hidden={!passwordInputProps.error}>
                                    {passwordInputProps.errorMessage}
                                </span>
                                <TextField multiline rows={5} type='text' name='note' label='Note' variant='outlined'
                                    disabled={formData.formDisabled}
                                    onChange={(e: any) => setState({ userNote: e.target.value })}
                                    value={formData.userNote !== "" ? formData.userNote : ''} />
                            </Grid>
                            <Grid>
                                <Button type='submit' color='primary' variant='contained' size='large' onClick={submitUpdateUser} fullWidth disabled={formData.formDisabled}>UPDATE PROFILE</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item sm={6} xs={12} className='user_crud_form'>
                    <Paper className='user_crud_paper' elevation={0}>
                        <Typography variant="h2">Add New User</Typography>
                        <Grid container alignItems='center' className={`user_crud_checkbox_container ${classes.bottomBorder} ${classes.marginTop}`} /* style={{ background: '#f5f5f5' }}*/>
                            <Checkbox color='primary' inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Typography>Send invite email</Typography>
                        </Grid>
                        <Grid container justify='center'>
                            <Grid container direction='column' justify='space-evenly' className='user_crud_form_fields'>
                                <Autocomplete
                                    options={formData.roles.map((options: any) => options.name)}
                                    onChange={handleAddUserRoleChange}
                                    value={addUserRole}
                                    onBlur={() => { triggerAddUserRoleValidation(addUserRole) }}
                                    renderInput={(params) =>
                                        <>
                                            <TextField {...params} error={addUserRoleInputProps.error} label="Role" variant="outlined" />
                                            <span className="validation_error">
                                                {addUserRoleInputProps.errorMessage}
                                            </span>
                                        </>
                                    }
                                />
                                <TextField type='text' name='new_user_email' label='Email' variant='outlined'
                                    onChange={handleAddUserEmailChange}
                                    value={addUserEmail}
                                    onBlur={() => { triggerAddUserEmailValidation(addUserEmail) }}
                                    error={addUserEmailInputProps.error}
                                />
                                <span className={`validation_error ${classes.marginBottom}`} hidden={!addUserEmailInputProps.error}>
                                    {addUserEmailInputProps.errorMessage}
                                </span>

                                <TextField type='text' name='new_user_password' label='Password' variant='outlined' className='hide_password_input_field'
                                    onChange={handleAddUserPasswordChange}
                                    value={addUserPassword}
                                    onBlur={() => { triggerAddUserPasswordValidation(addUserPassword) }}
                                    error={addUserPasswordInputProps.error}
                                />
                                <span className={`validation_error ${classes.marginBottom}`} hidden={!addUserPasswordInputProps.error}>
                                    {addUserPasswordInputProps.errorMessage}
                                </span>
                            </Grid>
                            <Grid>
                                <Button color='primary' variant='contained' size='large' fullWidth onClick={submitAddUser}>ADD NEW USER</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <SnackbarNotification
                open={snackbarOpen}
                snackbarMessage={snackbarMessage}
                handleClose={handleClose}
                severity={severity}
            />
        </>
    );
}

export default UserCRUDForms;