import React from 'react';
import { makeStyles, Grid, Typography, Paper } from '@material-ui/core';
import { InvoiceVM } from '../../../brines-refrigerator-api';

interface InvoiceHeaderProps {
  invoice: InvoiceVM
}

const useStyles = makeStyles({
  root: {
    margin: "1rem 2.5rem"
  },
  companyInfo: {
    fontSize: "1.7rem",
    fontWeight: 100,
    color: "#6e6e6e"
  },
  header1Gray: {
    fontSize: "1.9rem",
    color: "#6e6e6e",
    fontWeight: 500
  },
  header2Gray: {
    fontSize: "1.55rem",
    color: "#6e6e6e",
    fontWeight: 500
  },
  header2Primary: {
    fontSize: "1.55rem",
    color: "#3f51b5",
    fontWeight: 500
  },
  header3Gray: {
    fontSize: "1.1rem",
    color: "#6e6e6e",
    fontWeight: 500
  },
  bodyText: {
    color: "#6e6e6e",
    fontSize: "1.1rem"
  },
  marginBottomMd: {
    marginBottom: "2rem"
  },
  dispatchInfoCard: {
    padding: "1rem",
    backgroundColor: "rgb(245, 246, 251)"
  }
});


const InvoiceHeader = (props: InvoiceHeaderProps) => {
  const classes = useStyles();
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

  return (
    <Grid container >
      <Grid item container xs={12} className={classes.marginBottomMd}>
        <Grid item container xs={6}>
          <Typography className={classes.companyInfo}>
            Brines Refrigeration HTG &amp; CLG <br />
            26400 Southfield Rd <br />
            Lathrup Village MI48076 <br />
            1-800-299-5509
          </Typography>
        </Grid>
        <Grid item container xs={6} justify="flex-end">
          <Grid item xs={12}>
            <Typography className={classes.header1Gray} align="right">
              Invoice preview
            </Typography>
          </Grid>
          <Grid item container xs={5}>
            <Grid item xs={6}>
              <Typography className={classes.header2Gray} align="right">
                Invoice date
                </Typography>
              <Typography className={classes.bodyText} align="right">
                {dateTimeFormat.format(props.invoice.serviceDate)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.header2Gray} align="right">
                Invoice #
                </Typography>
              <Typography className={classes.bodyText} align="right">
                {props.invoice.number}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <Typography className={classes.header2Primary}>
            Customer:
            </Typography>
          <Typography className={classes.bodyText}>
            {/* Customer info */}
            {props.invoice.customerName}
            <br />
            {props.invoice.headquarterAddress1}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.header2Primary}>
            Location:
          </Typography>
          <Typography className={classes.bodyText}>
            {/* Location info */}
            IVR #{props.invoice.ivr}<br />
            {props.invoice.locationAddress1} <br />
            {props.invoice.locationAddress2}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Typography className={classes.header2Gray}>Dispatch info</Typography>
      </Grid>
      <Grid item container xs={12} spacing={1} className={classes.marginBottomMd}>
        <Grid item xs={2} lg={1}>
          <Paper elevation={2} className={classes.dispatchInfoCard}>
            <Typography className={classes.header3Gray}>W.O. Number</Typography>
            <Typography className={classes.bodyText}>{props.invoice.workOrderNumber}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} lg={1}>
          <Paper elevation={2} className={classes.dispatchInfoCard}>
            <Typography className={classes.header3Gray}>Service date</Typography>
            <Typography className={classes.bodyText}>{dateTimeFormat.format(props.invoice.serviceDate)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} lg={1}>
          <Paper elevation={2} className={classes.dispatchInfoCard}>
            <Typography className={classes.header3Gray}>P.O. Number</Typography>
            <Typography className={classes.bodyText}>{props.invoice.workOrderNumber}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InvoiceHeader;