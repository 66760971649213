import React, { useReducer, useEffect, useState } from 'react';

import './DispatchCRUDForm.scss';
import { Box, Button, Grid, Tab, Tabs, TextField, Typography, makeStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';

import { DispatchVM, TradeVM, PriorityTypeVM, DispatchStatusVM, LocationVM, CustomerVM, UserVM, DispatchDocumentsClient, DispatchNotesClient, DispatchNoteCreateVM, ServiceNotesClient, ServiceNoteCreateVM, InternalNotesClient, InternalNoteCreateVM, InternalNoteUpdateVM, ServiceRequestCodeClient, DispatchServiceRequestCodesUpdateVM, PartClient, PartVM, DispatchPartsUpdateVM, EquipmentClient, DispatchEquipmentUpdateVM, EquipmentVM, ServiceRequestCodeVM, CustomerBaseVM, LocationDispatchHqVM, DocumentFileUpdateVM, UserBaseVM, DispatchSecondaryStatusVM } from '../../../brines-refrigerator-api';
import AddNote from '../../../components/common/note/AddNote';
import FormDialog from '../../../components/common/dialog/FormDialog';
import NoteDispatch from '../../../components/common/note/NoteDispatch';
import UserRole from '../../../helpers/constants/userRole';
import ServiceRequestCodes from '../ServiceRequestCodes/ServiceRequestCodes';
import DispatchParts from '../DispatchParts/DispatchParts';
import DispatchEquipment from '../DispatchEquipment/DispatchEquipment';
import DispatchDocuments from '../DispatchDocuments/DispatchDocuments';
import CustomDispatchDocumentsClient, { DispatchDocumentAddVM } from '../DispatchDocuments/DispatchDocumentsClient';
import ConfirmDialog from '../../../components/common/dialog/ConfirmationDialog';
import { EquipmentDocumentAddVM, UploadFileClient } from '../../../brines-refrigerator-api-extended';
import EquipmentChangeAction from '../../../helpers/constants/equipmentChangeActions';
import { useSnackbar } from 'notistack';
import { generateUID } from '../../../helpers/generateUID';
import DateType from '../../../helpers/constants/dateTypes';
import { useLocation } from 'react-router-dom';
import NoteTempVM from '../../../components/common/note/NoteTempVM';
import { useSelectValidation, validateSelect } from '../../../helpers/validations';
import DispatchLabor from '../DispatchLabor/DispatchLabor';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

interface FormProps {
    locationId: number,
    formTitle: string,
    trades: TradeVM[],
    priorities: PriorityTypeVM[],
    customers: CustomerBaseVM[],
    locations: LocationDispatchHqVM[],
    statuses: DispatchStatusVM[],
    secondaryStatuses: DispatchSecondaryStatusVM[],
    technicians: UserVM[],
    serviceRequestCodes: ServiceRequestCodeVM[],
    formAction: Function,
    clearFields: Function,
    formButton1Action: string,
    formButton2Action: Function,
    dispatch: DispatchVM,
}

interface DispatchFormStateType {
    id: number,
    locationId: number,
    location: LocationVM,
    priorityId: number,
    tradeId: number,
    workOrderNumber: number,
    technicianId: number,
    created: Date,
    followUpDate: Date,
    slaDate: Date,
    statusId: number,
    secondaryStatusId: number,
    customers: CustomerBaseVM[],
    tempCustomers: CustomerBaseVM[],
    locations: LocationDispatchHqVM[],
    tempLocations: LocationDispatchHqVM[],
    trades: TradeVM[],
    priorities: PriorityTypeVM[],
    statuses: DispatchStatusVM[],
    technicians: UserVM[]
}

const useStyles = makeStyles({
    button: {
        marginBottom: "1em"
    },
    boxScroll: {
        overflowY: "auto",
        overflowX: "hidden"
    },
    partTable: {
        width: '60rem',
        height: '30rem'
    },
    addPartTableButton: {
        marginLeft: "70%"
    }
});

const DispatchCRUDForm = (props: FormProps) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const classes = useStyles();
    const [state, setState] = useReducer(
        (state: DispatchFormStateType, newState: DispatchFormStateType) => ({ ...state, ...newState }),
        {
            id: props.dispatch.id,
            customers: props.customers,
            tempCustomers: props.customers,
            locations: props.locations,
            tempLocations: props.locations,
            trades: props.trades,
            priorities: props.priorities,
            statuses: props.statuses,
            statusId: props.dispatch.statusId,
            secondaryStatusId: props.dispatch.secondaryStatusId,
            locationId: props.dispatch.locationId,
            location: props.dispatch.location,
            priorityId: props.dispatch.priorityId,
            tradeId: props.dispatch.tradeId,
            workOrderNumber: props.dispatch.workOrderNumber,
            technicians: props.technicians,
            technicianId: props.dispatch.tehnicianId,
            followUpDate: props.dispatch.followUpDate,
            slaDate: props.dispatch.slaDate,
            created: props.dispatch.created
        }
    )

    // to check if there are ongoing changes on basic properties
    let initialDispatchValue = JSON.stringify(new DispatchVM({
        id: props.dispatch.id,
        locationId: props.dispatch.locationId,
        priorityId: props.dispatch.priorityId,
        tradeId: props.dispatch.tradeId,
        workOrderNumber: props.dispatch.workOrderNumber,
        tehnicianId: props.dispatch.tehnicianId,
        followUpDate: props.dispatch.followUpDate,
        slaDate: props.dispatch.slaDate,
        statusId: props.dispatch.statusId,
        secondaryStatusId: props.dispatch.secondaryStatusId
    }))
    const [initialSelectedRequestCodeOneValue, setInitialSelectedRequestCodeOneValue] = useState('')
    const [initialSelectedRequestCodeTwoValue, setInitialSelectedRequestCodeTwoValue] = useState('')
    const [initialSelectedRequestCodeThreeValue, setInitialSelectedRequestCodeThreeValue] = useState('')
    const [initialSelectedRequestCodeFourValue, setInitialSelectedRequestCodeFourValue] = useState('')
    const [initialDispatchEquipmentValue, setInitialDispatchEquipmentValue] = useState('')
    const initialDocumentsValue = '[]'
    const [initialDispatchDocumentsValue, setInitialDispatchDocumentsValue] = useState('')
    const [initialDispatchPartsValue, setInitialDispatchPartsValue] = useState('')
    const [initialDispatchNotesValue, setInitialDispatchNotesValue] = useState('')
    const [initialServiceNotesValue, setInitialServiceNotesValue] = useState('')
    const [initialInternalNotesValue, setInitialInternalNotesValue] = useState('')

    useEffect(() => {
        setState({
            id: props.dispatch.id,
            customers: props.customers,
            tempCustomers: props.customers,
            locations: props.locations,
            tempLocations: props.locations,
            trades: props.trades,
            priorities: props.priorities,
            statuses: props.statuses,
            statusId: props.dispatch.statusId,
            secondaryStatusId: props.dispatch.secondaryStatusId,
            locationId: props.dispatch.locationId ? props.dispatch.locationId : location.state ? (location.state as { dispatchIdFromTechDispatchView: number }).dispatchIdFromTechDispatchView : null,
            location: props.dispatch.location,
            priorityId: props.dispatch.priorityId,
            tradeId: props.dispatch.tradeId,
            workOrderNumber: props.dispatch.workOrderNumber,
            technicians: props.technicians,
            technicianId: props.dispatch.tehnicianId,
            followUpDate: props.dispatch.followUpDate,
            slaDate: props.dispatch.slaDate,
            created: props.dispatch.created
        })
        setDispatchEquipment([]);
        setEquipmentDocuments([])
        setDraftDispatchDocuments([]);
        setRemovedDocuments([]);
        clearTempNotes();
        clearValidation();
    }, [props])

    const [equipmentDocuments, setEquipmentDocuments] = useState<EquipmentDocumentAddVM[]>([]);

    //needed for switching between tabs
    const [tabValue, setTabValue] = useState(0);

    const [tableIsLoading, setTableLoading] = useState(true);

    function handleServerError(message) {
        if (message === undefined || message.response === undefined) {
            enqueueSnackbar("An error has occured", { variant: "error" });
            return;
        }

        for (let prop in message.response.data.errors) {
            let propErrors = message.response.data.errors[prop];

            propErrors.forEach(element => {
                enqueueSnackbar(element, { variant: "error" });
            });
        }
    }

    const handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const [prioritySelectOpen, setPrioritySelectOpen] = useState(false);
    const [tradeSelectOpen, setTradeSelectOpen] = useState(false);

    const handleColorChange = (event: React.ChangeEvent<{ value: unknown }>, table: string) => {
        if (table === 'priority') {
            setState({ ...state, priorityId: event.target.value as number });
        } else {
            setState({ ...state, tradeId: event.target.value as number });
        }
    };

    const ListComponent: React.FC<{ backgroundColor: string, text?: string, textColor?: string }> = ({ backgroundColor, text, textColor }) => (
        <><div className='priority_and_trades_form_add_data_container_pick_color_text'
            style={{
                backgroundColor,
                width: '100%',
                height: 38,
                color: textColor
            }}></div>
            <div style={{ whiteSpace: 'pre-wrap', marginLeft: '1rem' }}>{text}</div>
        </>
    )

    const handleCustomerChange = (event: React.ChangeEvent<{ value: unknown }>, value: CustomerVM) => {
        if (value) {
            const newTempLocations = state.locations.filter((option) => option.customerId === value.id);
            setState({ ...state, tempLocations: newTempLocations, locationId: null, location: null });
            setCustomer(state.customers.find((customer) => customer.id === value.id));
        } else {
            const newTempLocations = state.locations;
            setState({ ...state, tempLocations: newTempLocations, locationId: null, location: null });
            setCustomer(null);
        }
    }

    const handleLocationChange = (event: React.ChangeEvent<{ value: unknown }>, value: LocationVM) => {
        if (value) {
            setState({ ...state, locationId: value.id, location: value });
            setCustomer(state.customers.find(customer => customer.id === value.customerId));
        }
        else setState({ ...state, locationId: null, location: null });
        if (locationInputProps.error) {
            triggerLocationValidation(value);
        }
    }

    const handleWONumberChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState({ ...state, workOrderNumber: Number(event.target.value) });
    };

    const handleTechnicianChange = (event: React.ChangeEvent<{ value: unknown }>, value: UserVM) => {
        let selectedTech = value;
        //status 1 is unassigned, 2 is pending
        if (selectedTech) {
            setState({ ...state, technicianId: selectedTech.id, statusId: 2 });
        } else {
            setState({ ...state, technicianId: null, statusId: 1 });
        }
        if (technicianInputProps.error) validateTechnician();
    }

    const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const _statusId = event.target.value as number;
        setState({ ...state, statusId: _statusId as number, technicianId: _statusId === 1 ? null : state.technicianId });
        if (_statusId === 1) {
            resetTechnicianInputProps();
        }
    }

    const handleSecondaryStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const _secondaryStatusId = event.target.value as number;
        setState({ ...state, secondaryStatusId: _secondaryStatusId as number });
    }

    //customer is separated here as the form doesn't send any data in regards to the customer as that info is sent through location id itself
    //the customer info and select is only used to filter and find locations easier
    const [customer, setCustomer] = useState(null);

    // NOTES
    const [noteText, setNoteText] = useState('')
    const [modalState, setModalState] = useState(false);
    const [modalTitle, setModalTitle] = useState('Add Note')
    const [buttonText, setButtonText] = useState('Add Note')

    const openDispatchNoteModal = () => {
        setAddNoteModalState()
    };

    const openServiceNoteModal = () => {
        setAddNoteModalState()
    };

    const openInternalNoteModal = () => {
        setAddNoteModalState()
    };

    const closeModal = () => {
        setModalState(false)
        setNoteText('');
    }

    // ADD NOTES
    const addDispatchNote = async (_, noteText) => {
        setAddNoteButtonActionState(noteText);

        const uid = generateUID();
        const newNote = new NoteTempVM(null, null, null, null, null, noteText, true, uid);
        dispatchNotes.push(newNote);
        const newDispatchNotes = [...dispatchNotes];
        setDispatchNotes(newDispatchNotes);

        draftDispatchNotes.push(newNote);
        const newDraftNotes = [...draftDispatchNotes];
        setDraftDispatchNotes(newDraftNotes);

        setNoteText('');
        setModalState(false)
    }

    const addServiceNote = async (_, noteText) => {
        setAddNoteButtonActionState(noteText);

        const uid = generateUID();
        const newNote = new NoteTempVM(null, null, null, null, null, noteText, true, uid);
        serviceNotes.push(newNote);
        const newServiceNotes = [...serviceNotes];
        setServiceNotes(newServiceNotes);

        draftServiceNotes.push(newNote);
        const newDraftNotes = [...draftServiceNotes];
        setDraftServiceNotes(newDraftNotes);

        setNoteText('');
        setModalState(false)
    }

    const addInternalNote = (_, noteText) => {
        setAddNoteButtonActionState(noteText);

        const uid = generateUID();
        const newNote = new NoteTempVM(null, null, null, null, null, noteText, true, uid);
        internalNotes.push(newNote);
        const newInternalNotes = [...internalNotes];
        setInternalNotes(newInternalNotes);

        draftInternalNotes.push(newNote);
        const newDraftNotes = [...draftInternalNotes];
        setDraftInternalNotes(newDraftNotes);

        setNoteText('');
        setModalState(false)
    }

    const [dispatchNoteUIdForEditing, setDispatchNoteUIdForEditing] = useState('');
    const [serviceNoteUIdForEditing, setserviceNoteUIdForEditing] = useState('');
    const [internalNoteUIdForEditing, setInternalNoteUIdForEditing] = useState('');

    // ADD NOTE COMPONENTS
    const dispatchAddNote = (
        <AddNote
            buttonAction={modalTitle === "Edit Note" ? editDispatchNote : addDispatchNote}
            id={state.id}
            noteText={noteText}
            title={modalTitle}
            buttonText={buttonText}
            onClick={closeModal}
            uid={dispatchNoteUIdForEditing}
        />
    );

    const serviceAddNote = (
        <AddNote
            buttonAction={modalTitle === "Edit Note" ? editServiceNote : addServiceNote}
            id={state.id}
            noteText={noteText}
            title={modalTitle}
            buttonText={buttonText}
            onClick={closeModal}
            uid={serviceNoteUIdForEditing}
        />
    );

    const internalAddNote = (
        <AddNote
            buttonAction={modalTitle === "Edit Note" ? editInternalNote : addInternalNote}
            id={state.id}
            noteText={noteText}
            title={modalTitle}
            buttonText={buttonText}
            onClick={closeModal}
            uid={internalNoteUIdForEditing}
        />
    );

    async function editDispatchNoteCallback(id: number, noteText: string, uid: string) {
        setEditModalState(noteText);
        setDispatchNoteUIdForEditing(uid);
    }

    function editDispatchNote(_, noteText: string, uid: string) {
        const foundNote = dispatchNotes.find(x => x.uid === uid);
        const noteIndex = dispatchNotes.findIndex(x => x.uid === uid);
        dispatchNotes[noteIndex].text = noteText;
        dispatchNotes[noteIndex].lastModified = new Date();
        dispatchNotes[noteIndex].lastModifiedBy = new UserBaseVM({
            userName: userData.user.userName,
            email: userData.user.email
        })

        const newDispatchNotes = [...dispatchNotes];
        setDispatchNotes(newDispatchNotes);

        if (foundNote.draft) {
            const draftNoteIndex = draftDispatchNotes.findIndex(x => x.uid === uid);
            draftDispatchNotes[draftNoteIndex].text = noteText;

            setDraftDispatchNotes([...draftDispatchNotes]);
        } else {
            //was the note on the pile of non-draft notes to be edited before?
            const check = editedDispatchNotes.findIndex(x => x.id === foundNote.id);
            const tempEditedNotes = [...editedDispatchNotes];
            if (check !== -1) {
                //if so, find it and change the text to new one
                tempEditedNotes[check].text = noteText;
            } else {
                //else push a new element
                tempEditedNotes.push(new InternalNoteUpdateVM({
                    id: foundNote.id,
                    text: noteText
                }));
            }
            setEditedDispatchNotes(tempEditedNotes);
        }

        setModalState(false)
        setNoteText('')
    }

    async function editServiceNoteCallback(id: number, noteText: string, uid: string) {
        setEditModalState(noteText);
        setserviceNoteUIdForEditing(uid)
    }

    function editServiceNote(_, noteText: string, uid: string) {
        const foundNote = serviceNotes.find(x => x.uid === uid);
        const noteIndex = serviceNotes.findIndex(x => x.uid === uid);
        serviceNotes[noteIndex].text = noteText;
        serviceNotes[noteIndex].lastModified = new Date();
        serviceNotes[noteIndex].lastModifiedBy = new UserBaseVM({
            userName: userData.user.userName,
            email: userData.user.email
        })

        const newServiceNotes = [...serviceNotes];
        setServiceNotes(newServiceNotes);

        if (foundNote.draft) {
            const draftNoteIndex = draftServiceNotes.findIndex(x => x.uid === uid);
            draftServiceNotes[draftNoteIndex].text = noteText;

            setDraftServiceNotes([...draftInternalNotes]);
        } else {
            //was the note on the pile of non-draft notes to be edited before?
            const check = editedServiceNotes.findIndex(x => x.id === foundNote.id);
            const tempEditedNotes = [...editedServiceNotes];
            if (check !== -1) {
                //if so, find it and change the text to new one
                tempEditedNotes[check].text = noteText;
            } else {
                //else push a new element
                tempEditedNotes.push(new InternalNoteUpdateVM({
                    id: foundNote.id,
                    text: noteText
                }));
            }
            setEditedServiceNotes(tempEditedNotes);
        }

        setModalState(false)
        setNoteText('')
    }

    async function editInternalNoteCallback(id: number, noteText: string, uid: string) {
        setEditModalState(noteText);
        setInternalNoteUIdForEditing(uid)
    }

    function editInternalNote(_, noteText: string, uid: string) {
        const foundNote = internalNotes.find(x => x.uid === uid);

        const noteIndex = internalNotes.findIndex(x => x.uid === uid);
        internalNotes[noteIndex].text = noteText;
        internalNotes[noteIndex].lastModified = new Date();
        internalNotes[noteIndex].lastModifiedBy = new UserBaseVM({
            userName: userData.user.userName,
            email: userData.user.email
        })

        const newInternalNotes = [...internalNotes];
        setInternalNotes(newInternalNotes);

        if (foundNote.draft) {
            const draftNoteIndex = draftInternalNotes.findIndex(x => x.uid === uid);
            draftInternalNotes[draftNoteIndex].text = noteText;

            setDraftInternalNotes([...draftInternalNotes]);
        } else {
            //was the note on the pile of non-draft notes to be edited before?
            const check = editedInternalNotes.findIndex(x => x.id === foundNote.id);
            const tempEditedNotes = [...editedInternalNotes];
            if (check !== -1) {
                //if so, find it and change the text to new one
                tempEditedNotes[check].text = noteText;
            } else {
                //else push a new element
                tempEditedNotes.push(new InternalNoteUpdateVM({
                    id: foundNote.id,
                    text: noteText
                }));
            }
            setEditedInternalNotes(tempEditedNotes);
        }

        setModalState(false)
        setNoteText('')
    }

    function deleteDispatchNoteCallback(id: number, uid: string) {
        const foundNote = dispatchNotes.find(x => x.uid === uid);

        //remove the note from the overall note list
        const noteIndex = dispatchNotes.findIndex(x => x.uid === uid);

        dispatchNotes.splice(noteIndex, 1);
        //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
        const newNotes = [...dispatchNotes];

        setDispatchNotes(newNotes);

        if (foundNote.draft) {
            //if the specified note to remove is a draft we need to remove it from the draft notes list
            const draftNoteIndex = draftDispatchNotes.findIndex(x => x.uid === uid);
            draftDispatchNotes.splice(draftNoteIndex, 1);
            const newDraftNotes = [...draftDispatchNotes];
            setDraftDispatchNotes(newDraftNotes);
        } else {
            //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
            removedDispatchNotes.push(foundNote.id);

            //also, we need to check whether this note was scheduled for editing before and remove it accordingly
            const check = editedDispatchNotes.findIndex(x => x.id === id);

            if (check !== -1) {
                editedDispatchNotes.splice(check, 1);
                const newEditedNotes = [...editedDispatchNotes];
                setEditedDispatchNotes(newEditedNotes);
            }
        }
    }

    const updateDispatchNotes = async () => {
        const client = new DispatchNotesClient();

        //function responsible for turning temp into permanent state
        if (removedDispatchNotes.length > 0) {
            removedDispatchNotes.forEach(x => {
                client.delete(x);
            });
        }

        if (draftDispatchNotes.length > 0) {
            draftDispatchNotes.forEach(x => {
                client.create(new DispatchNoteCreateVM({
                    dispatchId: state.id,
                    text: x.text
                }));
            });
        }

        if (editedDispatchNotes.length > 0) {
            editedDispatchNotes.forEach(x => {
                client.update(x);
            });
        }

        setRemovedDispatchNotes([]);
        setDraftDispatchNotes([]);
        setEditedDispatchNotes([]);
    }

    function deleteServiceNoteCallback(id: number, uid: string) {
        const foundNote = serviceNotes.find(x => x.uid === uid);

        //remove the note from the overall note list
        const noteIndex = serviceNotes.findIndex(x => x.uid === uid);

        serviceNotes.splice(noteIndex, 1);
        //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
        const newNotes = [...serviceNotes];

        setServiceNotes(newNotes);

        if (foundNote.draft) {
            //if the specified note to remove is a draft we need to remove it from the draft notes list
            const draftNoteIndex = draftServiceNotes.findIndex(x => x.uid === uid);
            draftServiceNotes.splice(draftNoteIndex, 1);
            const newDraftNotes = [...draftServiceNotes];
            setDraftServiceNotes(newDraftNotes);
        } else {
            //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
            removedServiceNotes.push(foundNote.id);

            //also, we need to check whether this note was scheduled for editing before and remove it accordingly
            const check = editedServiceNotes.findIndex(x => x.id === id);

            if (check !== -1) {
                editedServiceNotes.splice(check, 1);
                const newEditedNotes = [...editedServiceNotes];
                setEditedServiceNotes(newEditedNotes);
            }
        }
    }

    const updateServiceNotes = async () => {
        const client = new ServiceNotesClient();

        //function responsible for turning temp into permanent state
        if (removedServiceNotes.length > 0) {
            removedServiceNotes.forEach(x => {
                client.delete(x);
            });
        }

        if (draftServiceNotes.length > 0) {
            draftServiceNotes.forEach(x => {
                client.create(new ServiceNoteCreateVM({
                    dispatchId: state.id,
                    text: x.text
                }));
            });
        }

        if (editedServiceNotes.length > 0) {
            editedServiceNotes.forEach(x => {
                client.update(x);
            });
        }

        setRemovedServiceNotes([]);
        setDraftServiceNotes([]);
        setEditedServiceNotes([]);
    }

    function deleteInternalNoteCallback(id: number, uid: string) {
        const foundNote = internalNotes.find(x => x.uid === uid);

        //remove the note from the overall note list
        const noteIndex = internalNotes.findIndex(x => x.uid === uid);

        internalNotes.splice(noteIndex, 1);
        //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
        const newNotes = [...internalNotes];

        setInternalNotes(newNotes);

        if (foundNote.draft) {
            //if the specified note to remove is a draft we need to remove it from the draft notes list
            const draftNoteIndex = draftInternalNotes.findIndex(x => x.uid === uid);
            draftInternalNotes.splice(draftNoteIndex, 1);
            const newDraftNotes = [...draftInternalNotes];
            setDraftInternalNotes(newDraftNotes);
        } else {
            //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
            removedInternalNotes.push(foundNote.id);

            //also, we need to check whether this note was scheduled for editing before and remove it accordingly
            const check = editedInternalNotes.findIndex(x => x.id === id);

            if (check !== -1) {
                editedInternalNotes.splice(check, 1);
                const newEditedNotes = [...editedInternalNotes];
                setEditedInternalNotes(newEditedNotes);
            }
        }
    }

    const updateInternalNotes = async () => {
        const client = new InternalNotesClient();

        //function responsible for turning temp into permanent state
        if (removedInternalNotes.length > 0) {
            removedInternalNotes.forEach(x => {
                client.delete(x);
            });
        }

        if (draftInternalNotes.length > 0) {
            draftInternalNotes.forEach(x => {
                client.create(new InternalNoteCreateVM({
                    dispatchId: state.id,
                    text: x.text
                }));
            });
        }

        if (editedInternalNotes.length > 0) {
            editedInternalNotes.forEach(x => {
                client.update(x);
            });
        }

        setRemovedInternalNotes([]);
        setDraftInternalNotes([]);
        setEditedInternalNotes([]);
    }

    const setEditModalState = (noteText: string) => {
        setModalState(true)
        setModalTitle("Edit Note")
        setButtonText("Edit Note")
        setNoteText(noteText)
    }
    const setAddNoteButtonActionState = (noteText: string) => {
        setNoteText(noteText);
        setModalTitle("Add Note")
        setButtonText("Add Note")
    }

    const setAddNoteModalState = () => {
        setModalState(true)
        setModalTitle("Add Note")
        setButtonText("Add Note")
    }

    //NOTES FOR DISPLAY

    const [dispatchNotes, setDispatchNotes] = useState([]);

    async function getDispatchNotes() {
        try {
            const notesClient = new DispatchNotesClient();
            const dispatchNotes = await notesClient.get(props.dispatch.id, true);
            const modifiedDispatchNotes = dispatchNotes.map((x) => new NoteTempVM(x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, generateUID()));
            setDispatchNotes(modifiedDispatchNotes);
            setInitialDispatchNotesValue(JSON.stringify(modifiedDispatchNotes));
        } catch (error) {
            handleServerError(error)
        }
    };

    useEffect(() => {
        if (props.dispatch.id) {
            getDispatchEquipment();
            getDispatchParts(props.dispatch.id);
            setTableLoading(false); // not sure about this but ok...
            getDispatchNotes();
            getServiceNotes();
            getInternalNotes();
            getDispatchServiceRequestCodes()
            getDispatchDocuments(props.dispatch.id);
        }
    }, [props.dispatch]);

    const [serviceNotes, setServiceNotes] = useState([]);

    async function getServiceNotes() {
        try {
            const serviceNotesClient = new ServiceNotesClient();
            const serviceNotes = await serviceNotesClient.get(props.dispatch.id, true);
            const modifiedServiceNotes = serviceNotes.map((x) => new NoteTempVM(x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, generateUID()));
            setServiceNotes(modifiedServiceNotes);
            setInitialServiceNotesValue(JSON.stringify(modifiedServiceNotes));
        } catch (error) {
            handleServerError(error)
        }
    }


    //notes for displaying(both actual and draft)
    const clearTempNotes = () => {
        setDraftInternalNotes([]);
        setDraftDispatchNotes([]);
        setDraftServiceNotes([]);

        setRemovedInternalNotes([]);
        setRemovedDispatchNotes([]);
        setRemovedServiceNotes([]);

        setEditedInternalNotes([]);
        setEditedDispatchNotes([]);
        setEditedServiceNotes([]);
    }

    const [internalNotes, setInternalNotes] = useState([]);

    //added draft notes(removed and edited draft notes are also done through this as for the back-end it makes no difference whether we edited a draft note)
    const [draftInternalNotes, setDraftInternalNotes] = useState([]);
    const [draftDispatchNotes, setDraftDispatchNotes] = useState([]);
    const [draftServiceNotes, setDraftServiceNotes] = useState([]);

    //removed non-draft notes
    const [removedInternalNotes, setRemovedInternalNotes] = useState([]);
    const [removedDispatchNotes, setRemovedDispatchNotes] = useState([]);
    const [removedServiceNotes, setRemovedServiceNotes] = useState([]);


    //edited non-draft notes
    const [editedInternalNotes, setEditedInternalNotes] = useState([]);
    const [editedDispatchNotes, setEditedDispatchNotes] = useState([]);
    const [editedServiceNotes, setEditedServiceNotes] = useState([]);

    async function getInternalNotes() {
        try {
            const internalNotesClient = new InternalNotesClient();
            const internalNotes = await internalNotesClient.get(props.dispatch.id, true);
            const modifiedInternalNotes = internalNotes.map((x) => new NoteTempVM(x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, generateUID()));
            setInternalNotes(modifiedInternalNotes);
            setInitialInternalNotesValue(JSON.stringify(modifiedInternalNotes))
        } catch (error) {
            handleServerError(error)
        }
    }

    // USER ROLE
    // Get user data from session storage
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    //get user role
    const role = userData.role.name;

    // SERVICE REQUEST CODES
    interface ServiceRequestCodes {
        id: number,
        description: string,
        code: number
    }

    const [selectedRequestCodeOne, setSelectedRequestCodeOne] = useState(null);
    const [selectedRequestCodeTwo, setSelectedRequestCodeTwo] = useState(null);
    const [selectedRequestCodeThree, setSelectedRequestCodeThree] = useState(null);
    const [selectedRequestCodeFour, setSelectedRequestCodeFour] = useState(null);

    const clearServiceRCodes = async () => {
        setSelectedRequestCodeOne(null)
        setSelectedRequestCodeTwo(null)
        setSelectedRequestCodeThree(null)
        setSelectedRequestCodeFour(null)
    }

    const getDispatchServiceRequestCodes = async () => {
        const serviceRequestCodesClient = new ServiceRequestCodeClient();
        const serviceRequestCodes = await serviceRequestCodesClient.getDispatchCodes(props.dispatch.id);
        const one = serviceRequestCodes !== undefined
            && serviceRequestCodes[0] !== undefined
            ? serviceRequestCodes[0].serviceRequestCode as ServiceRequestCodes : null;
        setSelectedRequestCodeOne(one);
        setInitialSelectedRequestCodeOneValue(JSON.stringify(one));

        const two = serviceRequestCodes !== undefined
            && serviceRequestCodes[1] !== undefined
            ? serviceRequestCodes[1].serviceRequestCode as ServiceRequestCodes : null;
        setSelectedRequestCodeTwo(two);
        setInitialSelectedRequestCodeTwoValue(JSON.stringify(two));

        const three = serviceRequestCodes !== undefined
            && serviceRequestCodes[2] !== undefined
            ? serviceRequestCodes[2].serviceRequestCode as ServiceRequestCodes : null;
        setSelectedRequestCodeThree(three);
        setInitialSelectedRequestCodeThreeValue(JSON.stringify(three));

        const four = serviceRequestCodes !== undefined
            && serviceRequestCodes[3] !== undefined
            ? serviceRequestCodes[3].serviceRequestCode as ServiceRequestCodes : null;
        setSelectedRequestCodeFour(four);
        setInitialSelectedRequestCodeFourValue(JSON.stringify(four));
    }

    const updateServiceRequestCodes = async () => {
        try {
            const serviceRequestCodesClient = new ServiceRequestCodeClient();

            let serviceRequestCodes = [selectedRequestCodeOne, selectedRequestCodeTwo, selectedRequestCodeThree, selectedRequestCodeFour]
            serviceRequestCodes = serviceRequestCodes
                .filter(x => x !== null)
                .map(x => x.id);

            await serviceRequestCodesClient.updateDispatchCodes(new DispatchServiceRequestCodesUpdateVM({ dispatchId: props.dispatch.id, serviceRequestCodes }));
        } catch (error) {
            handleServerError(error)
        }
    };

    const addClearDispatchHandler = async () => {
        if (props.formButton1Action === 'Add') {
            if (validateAddDispatch()) {
                //this one is called here as only this form knows about customer as explained before, other fields are reset within the state managed by main dispatchview 
                setCustomer(null);
                await props.formAction(
                    {
                        locationId: state.locationId,
                        priorityId: state.priorityId ? state.priorityId : null,
                        tradeId: state.tradeId ? state.tradeId : null,
                        workOrderNumber: state.workOrderNumber,
                        tehnicianId: state.technicianId,
                        followUpDate: state.followUpDate,
                        statusId: state.statusId,
                        secondaryStatusId: state.secondaryStatusId
                    }
                );
                clearValidation();
            }
        } else {
            //we need to clear the fields, remember we need to set the customer to null in here
            setCustomer(null);
            props.clearFields();
            clearServiceRCodes();
            setDispatchDocuments([]);
            setDraftDispatchDocuments([])
            setInternalNotes([]);
            setDispatchNotes([]);
            setServiceNotes([]);
            setDispatchParts([]);
            setDispatchEquipment([]);
            setEquipmentDocuments([]);
        }
    }

    const editDispatchHandler = async () => {
        if (validateUpdateDispatch()) {
            await updateInternalNotes();
            await updateServiceNotes();
            await updateDispatchNotes();
            await updateServiceRequestCodes();
            await updateDispatchEquipment();
            await updateDispatchParts();
            await submitDispatchDocuments();
            await uploadEquipmentDocuments();
            await updateIsConfidential()
            clearValidation();
            await props.formButton2Action(
                {
                    id: state.id,
                    locationId: state.locationId,
                    priorityId: state.priorityId,
                    tradeId: state.tradeId === 0 ? null : state.tradeId,
                    workOrderNumber: state.workOrderNumber,
                    tehnicianId: state.technicianId,
                    followUpDate: state.followUpDate,
                    slaDate: state.slaDate,
                    statusId: state.statusId === 0 ? null : state.statusId,
                    secondaryStatusId: state.secondaryStatusId
                }
            );
        }
    }
    const updateIsConfidential = async () => {
        const dispatchDocumentsClient = new DispatchDocumentsClient();

        for (const element of dispatchDocuments) {
            await dispatchDocumentsClient.update(new DocumentFileUpdateVM({
                id: element.id,
                isConfidential: element.isConfidential
            }))
        }
    }

    const areThereOngoingChanges = (): boolean => {
        return initialDispatchValue !== JSON.stringify(new DispatchVM({
            id: state.id,
            locationId: state.locationId,
            priorityId: state.priorityId,
            tradeId: state.tradeId,
            workOrderNumber: state.workOrderNumber,
            tehnicianId: state.technicianId,
            followUpDate: state.followUpDate,
            slaDate: state.slaDate,
            statusId: state.statusId,
            secondaryStatusId: state.secondaryStatusId
        }))
            || initialDispatchEquipmentValue !== JSON.stringify(dispatchEquipment)
            || initialDocumentsValue !== JSON.stringify(equipmentDocuments)
            || initialDispatchDocumentsValue !== JSON.stringify(dispatchDocuments)
            || initialDispatchPartsValue !== JSON.stringify(dispatchTableParts)
            || initialDispatchNotesValue !== JSON.stringify(dispatchNotes)
            || initialServiceNotesValue !== JSON.stringify(serviceNotes)
            || initialInternalNotesValue !== JSON.stringify(internalNotes)
            || initialSelectedRequestCodeOneValue !== JSON.stringify(selectedRequestCodeOne)
            || initialSelectedRequestCodeTwoValue !== JSON.stringify(selectedRequestCodeTwo)
            || initialSelectedRequestCodeThreeValue !== JSON.stringify(selectedRequestCodeThree)
            || initialSelectedRequestCodeFourValue !== JSON.stringify(selectedRequestCodeFour)
    }

    const uploadEquipmentDocuments = async () => {
        const uploadClient = new UploadFileClient();
        for (const document of equipmentDocuments) {
            await uploadClient.uploadEquipmentDocument(document);
        }
    }

    //PARTS
    const [dispatchTableParts, setDispatchParts] = useState([])

    async function getDispatchParts(id: number) {
        try {
            const partsClient = new PartClient();
            const dispatchParts = await partsClient.getDispatchParts(id)
            const modifiedParts = dispatchParts.map(e => e.part)
            setDispatchParts(modifiedParts)
            setInitialDispatchPartsValue(JSON.stringify(modifiedParts))
        } catch (error) {
            handleServerError(error)
        }
    }

    //DISPATCH EQUIPMENT
    const [dispatchEquipment, setDispatchEquipment] = useState([]);

    const getDispatchEquipment = async () => {
        try {
            const dispatchEquipmentClient = new EquipmentClient();
            const equipment = await dispatchEquipmentClient.getDispatchEquipment(props.dispatch.id);
            const modifiedEquipment = equipment.map(elem => elem.equipment)
            setDispatchEquipment(modifiedEquipment);
            setInitialDispatchEquipmentValue(JSON.stringify(modifiedEquipment))
        } catch (error) {
            handleServerError(error)
        }
    }

    const handleEquipmentChange = async (action: EquipmentChangeAction, payload: unknown) => {
        switch (action) {
            case EquipmentChangeAction.Delete:
                setDispatchEquipment(dispatchEquipment.filter(elem => elem.id !== payload));
                break;
            case EquipmentChangeAction.AddNote:
                console.log('adding note');
                break;
            case EquipmentChangeAction.UploadDocument:
                setEquipmentDocuments(equipmentDocuments.concat((payload as { equipmentId: number, files: File[] }).files.map(f => new EquipmentDocumentAddVM({
                    equipmentId: (payload as { equipmentId: number, files: File[] }).equipmentId,
                    file: f,
                    name: f.name,
                    isConfidential: false
                }))));
                break;
        }
    }

    const addSelectedEquipment = (selectedEquipment: EquipmentVM[]) => {
        //take the existing equipment
        const existingEquipment = dispatchEquipment;

        //copy into new equipment
        const newEquipment = existingEquipment.slice(0, existingEquipment.length);

        //add each piece of equipment unless it's already added before
        selectedEquipment.forEach(elem => {
            if (!existingEquipment.some(ele => ele.id === elem.id))
                newEquipment.push(elem);
        });

        //set the new equipment
        setDispatchEquipment(newEquipment);
    }

    const updateDispatchEquipment = async () => {
        try {
            const dispatchEquipmentClient = new EquipmentClient();

            //prepare the equipment for back-end endpoint
            const mappedEquipment = dispatchEquipment.map(elem => elem.id);
            await dispatchEquipmentClient.updateDispatchEquipment(new DispatchEquipmentUpdateVM({
                dispatchId: props.dispatch.id,
                equipment: mappedEquipment
            }));
        } catch (error) {
            handleServerError(error)
        }
    }

    const addSelectedPart = (selectedParts: PartVM[]) => {
        //take the existing part
        const existingPart = dispatchTableParts;

        //copy into new part
        const newParts = existingPart.slice(0, existingPart.length);

        //add each piece of part unless it's already added before
        selectedParts.forEach(elem => {
            if (!existingPart.some(ele => ele.id === elem.id))
                newParts.push(elem);
        });

        //set the new part
        setDispatchParts(newParts);
    }

    const updateDispatchParts = async () => {
        try {
            const partClient = new PartClient();
            const mappedParts = dispatchTableParts.map(elem => elem.id);
            const partsUpdate = await partClient.updateDispatchParts(new DispatchPartsUpdateVM({
                dispatchId: props.dispatch.id,
                parts: mappedParts
            }))
        } catch (error) {
            handleServerError(error)
        }
    }

    const handlePartChange = async (action, payload) => {
        setTableLoading(true);
        switch (action) {
            case 'delete':
                setDispatchParts(dispatchTableParts.filter(elem => elem.id !== payload));
                break;
        }
        setTableLoading(false);
    }

    //Dispatch documents
    const [dispatchDocuments, setDispatchDocuments] = useState([]);
    const [draftDispatchDocuments, setDraftDispatchDocuments] = useState([]);
    const [removedDocuments, setRemovedDocuments] = useState([]);

    //represents VM for both draft and non-draft documents
    interface IDispatchDocumentTempVM {
        dispatchId: number;
        id: string;
        name: string;
        type: string;
        relativePath: string;
        isConfidential: boolean;
        draft: boolean;
        modified: boolean;
        uid: string;
    }

    class DispatchDocumentTempVM implements IDispatchDocumentTempVM {
        dispatchId: number;
        id: string; //string so we can directly use this field to display draft when needed
        name: string;
        type: string;
        relativePath: string;
        isConfidential: boolean;
        draft: boolean;
        modified: boolean;
        uid: string;
        document?: DispatchDocumentAddVM;

        constructor(dispatchId, id, name, type, path, isConfidential, draft, modified, uid, document = null) {
            this.dispatchId = dispatchId;
            this.id = id;
            this.name = name;
            this.type = type;
            this.relativePath = path;
            this.isConfidential = isConfidential;
            this.draft = draft;
            this.modified = modified;
            this.uid = uid;
            this.document = document;
        }
    }

    const getDispatchDocuments = async (dispatchId: number) => {
        const documentClient = new DispatchDocumentsClient();
        const files = await documentClient.getDispatchDocuments(dispatchId);
        const modifiedFiles = files.map(x => {
            return new DispatchDocumentTempVM(
                x.dispatchId,
                x.documentFile.id,
                x.documentFile.name,
                x.documentFile.type.extension,
                x.documentFile.relativePath,
                x.documentFile.isConfidential,
                false,
                false,
                generateUID()
            );
        })

        setDispatchDocuments(modifiedFiles);
        setInitialDispatchDocumentsValue(JSON.stringify(modifiedFiles))
    }

    //renamed to submit because it's uploading and removing
    const submitDispatchDocuments = async () => {
        const customDocumentsClient = new CustomDispatchDocumentsClient();
        const documentsClient = new DispatchDocumentsClient();
        try {
            for (const x of removedDocuments) {
                await documentsClient.delete(x);
            }
            for (const x of draftDispatchDocuments) {
                x.document.name = `${x.name}.${x.document.file.name.split('.').pop()}`
                await customDocumentsClient.create(x.document);
            }
        } catch (error) {
            handleServerError(error)
        }
        setDraftDispatchDocuments([]);
        setRemovedDocuments([]);
    }

    const addDocuments = async (files: File[]) => {
        const newDraftDocuments = [...draftDispatchDocuments];

        for (let file of files) {
            const newDocumentTempVM = new DispatchDocumentTempVM(state.id, 'Draft', `${file.name.split('.').slice(0, -1).join('.')}`, file.name.split('.').pop(), null, false, true, false,
                generateUID(),
                new DispatchDocumentAddVM({
                    dispatchId: state.id,
                    file: file,
                    name: file.name,
                    isConfidential: false
                })
            );
            newDraftDocuments.push(newDocumentTempVM);
        }
        setDraftDispatchDocuments(newDraftDocuments);
    }

    const changeDraftDocName = (uid: number, newName: string) => {
        const draftDocumentIndex = draftDispatchDocuments.findIndex(x => x.uid === uid);
        draftDispatchDocuments[draftDocumentIndex].name = newName;
        setDraftDispatchDocuments([...draftDispatchDocuments]);
    }

    const setIsConfidentialDraft = (uid: number, draft: boolean) => {
        if (draft) {
            const draftDocumentIndex = draftDispatchDocuments.findIndex(x => x.uid === uid);

            draftDispatchDocuments[draftDocumentIndex].isConfidential = !draftDispatchDocuments[draftDocumentIndex].isConfidential
            draftDispatchDocuments[draftDocumentIndex].document.isConfidential = draftDispatchDocuments[draftDocumentIndex].isConfidential
            setDraftDispatchDocuments([...draftDispatchDocuments]);

        } else {
            const documentsTempIndex = dispatchDocuments.findIndex(x => x.uid === uid);

            dispatchDocuments[documentsTempIndex].isConfidential = !dispatchDocuments[documentsTempIndex].isConfidential
            setDispatchDocuments([...dispatchDocuments]);
        }
    }


    const deleteDocument = async (uid: number, draft: boolean, id: string) => {
        if (draft) {
            const draftDocumentIndex = draftDispatchDocuments.findIndex(x => x.uid === uid);
            draftDispatchDocuments.splice(draftDocumentIndex, 1);
            setDraftDispatchDocuments([...draftDispatchDocuments]);
        } else {
            const documentsTempIndex = dispatchDocuments.findIndex(x => x.uid === uid);
            dispatchDocuments.splice(documentsTempIndex, 1);
            setDispatchDocuments([...dispatchDocuments]);

            const newRemovedDocuments = [...removedDocuments];
            newRemovedDocuments.push(Number(id));
            setRemovedDocuments(newRemovedDocuments);
        }
    }

    //CANCEL DISPATCH MODAL
    const [confirm, setConfirmOpen] = useState(false);

    function confirmOpen() {
        if (areThereOngoingChanges()) {
            setConfirmOpen(true);
        } else {
            addClearDispatchHandler()
        }
    };

    const handleDateChange = async (e: Date, fieldName: DateType) => {
        if (!e) {
            setState({ ...state, [fieldName]: e })
        } else {
            e instanceof Date && !isNaN(e.getTime())
                ? setState({ ...state, [fieldName]: e })
                : setState({ ...state, [fieldName]: null })
        }
    }

    const [locationInputProps, triggerLocationValidation, resetLocationInputProps] = useSelectValidation();
    const [technicianInputProps, triggerTechnicianValidation, resetTechnicianInputProps] = useSelectValidation();

    const validateTechnician = () => {
        //if it's null it's treated same as unassigned
        const status = state.statusId ? state.statusId : 1;

        //due to validation being different from other examples and DRY separate function here
        if (status !== 1) {
            //if the status is not unassigned there should be an assigned technician
            //validate select
            triggerTechnicianValidation(state.technicianId, `For this dispatch status, select a technician.`)
            return validateSelect(state.technicianId).error;
        } else {
            resetTechnicianInputProps();
            return false;
        }
    }

    const validateAddDispatch = (): boolean => {
        //put other rules when adding dispatch here
        const formError =
            validateSelect(state.locationId).error


        if (formError) {
            triggerLocationValidation(state.locationId);
        }

        return !formError;
    }

    const clearValidation = () => {
        resetLocationInputProps();
        resetTechnicianInputProps();
    }

    const validateUpdateDispatch = (): boolean => {
        //put other rules for updating dispatch validation here

        //we don't use the .error here but directly the result of the return function
        //because there's no need to always call the validateSelect so it doesn't always return an object
        //instead validateTechnician takes care of it and gives back the boolean
        const formError = validateTechnician();

        return !formError;
    }

    return (
        <Grid className='dispatch-crud-form' container>
            <Grid container xs={12} item alignItems="center" className={`customer-location ${state.id ? 'disabled' : ''}`} spacing={1}>
                <Grid item xs={12} lg={2}>
                    <Typography variant="h2">{props.formTitle}</Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Autocomplete
                        options={state.customers}
                        getOptionLabel={(option) => option.company}
                        onChange={handleCustomerChange}
                        value={state.locationId ? state.customers.find(customer => customer.id === (state.locations.find(location => location.id === state.locationId)).customerId) : customer}
                        disabled={state.id ? true : false}
                        renderInput={
                            (params) => <TextField {...params} label="Customer" variant="outlined" />
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Autocomplete
                        options={state.tempLocations}
                        getOptionLabel={(option) => option.name}
                        value={state.locationId ? state.locations.find(location => location.id === state.locationId) || null : null}
                        onChange={handleLocationChange}
                        disabled={state.id ? true : false}
                        onBlur={() => { triggerLocationValidation(state.location) }}
                        renderInput={
                            (params) =>
                                <>
                                    <TextField {...params} label="Location" variant="outlined"
                                        error={locationInputProps.error}
                                    />
                                    <span className="validation_error">
                                        {locationInputProps.errorMessage}
                                    </span>
                                </>
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={2} className='btn-add-dispatch'>
                    {
                        props.formButton1Action === 'Add'
                            ? <Button type='submit' color='primary' variant='contained' size='large' disableElevation fullWidth onClick={addClearDispatchHandler}>
                                ADD
                              </Button>
                            : <Button endIcon={<CancelOutlinedIcon />} color='primary' variant='outlined' size='large' disableElevation fullWidth onClick={() => confirmOpen()}>
                                Cancel
                              </Button>
                    }
                </Grid>
            </Grid>
            <Grid xs={12} spacing={1} item container className='line'>
            </Grid>
            <Grid container spacing={4} className={`${state.id ? '' : 'form-disabled'}`}>
                <Grid item container xs={12} lg={4} alignContent='flex-start' className='dispatch-details'>
                    <Grid item xs={10} className='dispatch-details-title'>
                        <Typography variant="h3">{`Dispatch ${state.id ? `#${state.id}` : ''} details`}</Typography>
                    </Grid>
                    <Grid item justify='space-between' container xs={12} spacing={1} className='dispatch-details-inputs'>
                        <Grid item xs={6}>
                            <FormControl variant='outlined' className='fw-select'>
                                <InputLabel>Priority</InputLabel>
                                <Select
                                    className='fw-select'
                                    onChange={(e) => handleColorChange(e, 'priority')}
                                    value={state.priorityId ? state.priorityId : 0}
                                    label='Priority'
                                    name='priority_color'
                                    disabled={state.id ? false : true}
                                    onOpen={() => setPrioritySelectOpen(true)}
                                    onClose={() => setPrioritySelectOpen(false)}
                                >
                                    {
                                        props.priorities.filter(p => { if (state.id && state.priorityId) return p.id !== 0; else return p; }).map((e: PriorityTypeVM) =>
                                            <MenuItem key={e.id} value={e.id}>
                                                <ListComponent backgroundColor={`#${e.hexCode}`} text={prioritySelectOpen ? e.name : ''} />
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant='outlined' className='fw-select'>
                                <InputLabel>Class</InputLabel>
                                <Select
                                    className='fw-select'
                                    onChange={(e) => handleColorChange(e, 'trade')}
                                    label='Class*'
                                    value={state.tradeId ? state.tradeId : 0}
                                    name='trades_color'
                                    disabled={state.id ? false : true}
                                    onOpen={() => setTradeSelectOpen(true)}
                                    onClose={() => setTradeSelectOpen(false)}
                                >
                                    {
                                        props.trades.filter(t => { if (state.id && state.tradeId) return t.id !== 0; else return t; }).map((e: TradeVM) =>
                                            <MenuItem key={e.id} value={e.id}>
                                                <ListComponent backgroundColor={`#${e.hexCode}`} text={tradeSelectOpen ? e.name : ''} />
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField type='number' name='wo_number' fullWidth label='WO#' variant='outlined' onChange={handleWONumberChange}
                                disabled={state.id ? false : true}
                                value={state.workOrderNumber ? state.workOrderNumber : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={state.technicians}
                                getOptionLabel={(option) => option.userName}
                                onChange={handleTechnicianChange}
                                value={state.technicianId ? state.technicians.find(technician => technician.id === state.technicianId) : null}
                                onBlur={validateTechnician}
                                renderInput={(params) =>
                                    <>
                                        <TextField {...params} label="Technician" variant="outlined"
                                            error={technicianInputProps.error}
                                        />
                                        <span className="validation_error">
                                            {technicianInputProps.errorMessage}
                                        </span>
                                    </>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <span className='date-created'>Date created: {state.created ? dateTimeFormat.format(state.created) : dateTimeFormat.format(new Date())}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    autoOk
                                    clearable
                                    format="MM/dd/yyyy hh:mm a"
                                    label='SLA Date'
                                    value={state.slaDate ? state.slaDate : null}
                                    emptyLabel='mm/dd/yyyy'
                                    onChange={e => handleDateChange(e, DateType.SlaDate)}
                                    invalidDateMessage={null}
                                    allowKeyboardControl={false}
                                    inputVariant='outlined'
                                    disabled={state.id ? false : true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    clearable
                                    format="MM/dd/yyyy"
                                    label='Follow Up/Due Date'
                                    value={state.followUpDate ? state.followUpDate : null}
                                    emptyLabel='mm/dd/yyyy'
                                    onChange={e => handleDateChange(e, DateType.FollowUpDate)}
                                    invalidDateMessage={null}
                                    allowKeyboardControl={false}
                                    inputVariant='outlined'
                                    disabled={state.id ? false : true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className='fw-select'>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    fullWidth
                                    className='fw-select'
                                    label='Status'
                                    value={(state.statusId) ? state.statusId : 1} //1 is unassigned - default
                                    onChange={handleStatusChange}
                                    disabled={state.id ? false : true}
                                >
                                    {props.statuses.map((e: DispatchStatusVM) => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className='fw-select'>
                                <InputLabel>Secondary Status</InputLabel>
                                <Select
                                    fullWidth
                                    className='fw-select'
                                    label='Secondary Status'
                                    value={(state.secondaryStatusId) ? state.secondaryStatusId : 0}
                                    onChange={handleSecondaryStatusChange}
                                    disabled={state.id ? false : true}
                                >
                                    {props.secondaryStatuses.map((e: DispatchSecondaryStatusVM) => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* src = service request codes */}
                    <Grid item xs={12} >
                        <Typography variant="h3">Service Request Codes</Typography>
                        <Box>

                            <ServiceRequestCodes
                                disabled={state.id ? false : true}
                                serviceRequestCodes={props.serviceRequestCodes}
                                serviceRequestCodeOne={selectedRequestCodeOne}
                                serviceRequestCodeTwo={selectedRequestCodeTwo}
                                serviceRequestCodeThree={selectedRequestCodeThree}
                                serviceRequestCodeFour={selectedRequestCodeFour}
                                setSelectedRequestCodeOne={setSelectedRequestCodeOne}
                                setSelectedRequestCodeTwo={setSelectedRequestCodeTwo}
                                setSelectedRequestCodeThree={setSelectedRequestCodeThree}
                                setSelectedRequestCodeFour={setSelectedRequestCodeFour}
                            />

                        </Box>
                    </Grid>
                </Grid>

                {/* the state id checks should be included in one variable so it doesn't need to repeatedly check for the same thing -- performance thing */}
                <Grid item container xs={12} lg={8} alignContent='flex-start'>
                    {/* Dispatch tabs */}
                    <Grid item container xs={12} className={`tabs-container`} >
                        <Grid item xs={12} className={`form-tabs ${state.id ? '' : 'disabled'}`}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Dispatch notes" disabled={state.id ? false : true} />
                                <Tab label="Service notes" disabled={state.id ? false : true} />
                                <Tab label="Internal notes" disabled={state.id ? false : true} />
                                <Tab label="Equipment" disabled={state.id ? false : true} />
                                <Tab label="Parts" disabled={state.id ? false : true} />
                                <Tab label="Labor" disabled={state.id ? false : true} />
                                <Tab label="Documents" disabled={state.id ? false : true} />
                            </Tabs>
                        </Grid>
                        <Grid item className='form-tab-content'>
                            <TabPanel value={tabValue} index={0} >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    onClick={openDispatchNoteModal}
                                    disabled={state.id ? false : true}
                                >
                                    Add Note
                                </Button>
                                {modalState && <FormDialog open={modalState} body={dispatchAddNote} />}
                                <Box className={classes.boxScroll}>
                                    <NoteDispatch
                                        deleteNoteCallback={deleteDispatchNoteCallback}
                                        setNoteForEditing={editDispatchNoteCallback}
                                        notes={dispatchNotes}
                                    />
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    onClick={openServiceNoteModal}
                                    disabled={state.id ? false : true}
                                >
                                    Add Note
                                </Button>
                                <Box className={classes.boxScroll}>
                                    <NoteDispatch
                                        deleteNoteCallback={deleteServiceNoteCallback}
                                        setNoteForEditing={editServiceNoteCallback}
                                        notes={serviceNotes}
                                    />
                                </Box>
                                {modalState && <FormDialog open={modalState} body={serviceAddNote} />}
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                {role !== UserRole.Technician &&
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        onClick={openInternalNoteModal}
                                        disabled={state.id ? false : true}
                                    >
                                        Add Note
                                </Button>
                                }
                                {modalState && <FormDialog open={modalState} body={internalAddNote} />}
                                <Box className={classes.boxScroll}>
                                    {role !== UserRole.Technician && <NoteDispatch
                                        deleteNoteCallback={deleteInternalNoteCallback}
                                        setNoteForEditing={editInternalNoteCallback}
                                        notes={internalNotes}
                                    />
                                    }
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <DispatchEquipment
                                    equipment={dispatchEquipment}
                                    handleEquipmentChange={handleEquipmentChange}
                                    addSelectedEquipment={addSelectedEquipment}
                                    locationId={props.dispatch.locationId}
                                    disabled={state.id ? false : true}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <DispatchParts
                                    dispatchTableParts={dispatchTableParts}
                                    addSelectedPart={addSelectedPart}
                                    handlePartChange={handlePartChange}
                                    disabled={state.id ? false : true}
                                    isLoading={tableIsLoading}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <DispatchLabor dispatchId={state.id} disabled={state.id ? false : true} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <DispatchDocuments
                                    documents={dispatchDocuments.concat(draftDispatchDocuments)}
                                    addDocuments={addDocuments}
                                    deleteDocument={deleteDocument}
                                    disabled={state.id ? false : true}
                                    setIsConfidentialDraft={setIsConfidentialDraft}
                                    changeDraftDocName={changeDraftDocName}
                                />
                            </TabPanel>
                        </Grid>
                    </Grid>
                    {/* Dispatch tabs end */}

                    <Grid item xs={12} className={`update-dispatch ${state.id ? 'update-dispatch_visible' : ''}`}>
                        <Button type='submit' color='primary' variant='contained' size='large' onClick={editDispatchHandler} disableElevation fullWidth>UPDATE DISPATCH</Button>
                    </Grid>

                    {confirm && <ConfirmDialog
                        title={"Clear form?"}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={() => { addClearDispatchHandler() }}
                    >
                        {"You are about to clear all the form fields, any unsaved changes will be lost. Proceed?"}
                    </ConfirmDialog>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DispatchCRUDForm;