import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import AddEditTable2 from '../../components/common/table/AddEditTable2';
import { InvoiceClient, InvoiceLaborAddVM } from '../../brines-refrigerator-api';
import { validateText } from '../../helpers/validations';

interface InvoiceLaborProps {
    invoiceId: number,
    updateInvoiceTotal: Function
}

const InvoiceLabor = (props: InvoiceLaborProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const laborColumns = [
        {
            title: 'Technician', field: 'technicianName',
            validate: rowData => rowData.technicianName === ''
                ? { isValid: false, helperText: 'Name can\'t be empty' }
                : true
        },
        {
            title: 'Hourly rate', field: 'hourlyRate', type: 'numeric',
            validate: rowData => rowData.hourlyRate < 0
                ? { isValid: false, helperText: 'Hourly rate can\'t be negative' }
                : true
        },
        {
            title: 'Hours', field: 'hours', type: 'numeric',
            validate: rowData => rowData.hours < 0
                ? { isValid: false, helperText: 'Hours can\'t be negative' }
                : true
        },
        { title: 'Subtotal', field: 'subtotal', editable: false }
    ]

    const [laborList, setLaborList] = useState([]);
    const [laborTableLoading, setLaborTableLoading] = useState(false);

    const getInvoiceLabor = async () => {
        try {
            setLaborTableLoading(true);
            const invoiceClient = new InvoiceClient();
            const labor = await invoiceClient.getInvoiceLabor(props.invoiceId);
            setLaborList(labor);
            setLaborTableLoading(false);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setLaborTableLoading(false);
        }
    }

    const addInvoiceLabor = async (data) => {
        if (validateAllFields(data)) {
            return false;
        }
        try {
            const invoiceClient = new InvoiceClient();
            await invoiceClient.addInvoiceLabor([new InvoiceLaborAddVM({
                invoiceId: props.invoiceId,
                technicianName: data.technicianName,
                hourlyRate: Number(data.hourlyRate),
                hours: Number(data.hours)
            })]);
            getInvoiceLabor();
            props.updateInvoiceTotal();
            return true;
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            return false;
        }
    }

    const validateAllFields = (data: unknown): boolean => {
        const resultName = (data as { technicianName: string, hourlyRate: number, hours: number }).technicianName
            ? validateText((data as { technicianName: string, hourlyRate: number, hours: number }).technicianName)
            : { error: true, errorMessage: 'Name can\'t be empty.' };

        const resultHourlyRate = (data as { technicianName: string, hourlyRate: number, hours: number }).hourlyRate
            ? { error: false, errorMessage: null }
            : { error: true, errorMessage: 'Hourly Rate can\'t be empty.' };

        const resultHours = (data as { technicianName: string, hourlyRate: number, hours: number }).hours
            ? { error: false, errorMessage: null }
            : { error: true, errorMessage: 'Hours can\'t be empty.' };

        if (resultName.error) {
            enqueueSnackbar(resultName.errorMessage, { variant: "error" });
        }
        if (resultHourlyRate.error) {
            enqueueSnackbar(resultHourlyRate.errorMessage, { variant: "error" });
        }
        if (resultHours.error) {
            enqueueSnackbar(resultHours.errorMessage, { variant: "error" });
        }

        return resultName.error || resultHourlyRate.error || resultHours.error
    }

    const deleteInvoiceLabor = async (id: number) => {
        try {
            const invoiceClient = new InvoiceClient();
            await invoiceClient.deleteInvoiceLabor(id);
            getInvoiceLabor();
            props.updateInvoiceTotal();
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    useEffect(() => {
        getInvoiceLabor();
    }, []);

    return (
        <div>
            <AddEditTable2
                width="70%"
                title="Labor"
                columns={laborColumns}
                add={addInvoiceLabor}
                delete={deleteInvoiceLabor}
                data={laborList as unknown as []}
                search={false}
                isLoading={laborTableLoading}
            />
        </div>
    );
}

export default InvoiceLabor;