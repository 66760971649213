import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface ServiceRequestCodes {
  id: number,
  description: string,
  code: number,
}
interface ServiceRequestCodesProps {
  disabled: boolean,
  serviceRequestCodeOne: ServiceRequestCodes,
  serviceRequestCodeTwo: ServiceRequestCodes,
  serviceRequestCodeThree: ServiceRequestCodes,
  serviceRequestCodeFour: ServiceRequestCodes,
  serviceRequestCodes: ServiceRequestCodes[],
  setSelectedRequestCodeOne: Function,
  setSelectedRequestCodeTwo: Function,
  setSelectedRequestCodeThree: Function,
  setSelectedRequestCodeFour: Function
}

export default function ServiceRequestCodes(props: ServiceRequestCodesProps) {

  const filteredCodes = (): ServiceRequestCodes[] => {
    const elementsToBeExcluded = [props.serviceRequestCodeOne, props.serviceRequestCodeTwo, props.serviceRequestCodeThree, props.serviceRequestCodeFour]
      .filter(x => x !== null)
      .map(x => x.id);

    return props.serviceRequestCodes
      .filter(x => { return !elementsToBeExcluded.includes(x.id); })
  }

  const handleServiceCodeChange = (event: React.ChangeEvent<{ value: ServiceRequestCodes }>, value: ServiceRequestCodes) => {
    props.setSelectedRequestCodeOne(value)
  };

  const handleServiceCodeChangeTwo = (event: React.ChangeEvent<{ value: ServiceRequestCodes }>, value: ServiceRequestCodes) => {
    props.setSelectedRequestCodeTwo(value)
  };

  const handleServiceCodeChangeThree = (event: React.ChangeEvent<{ value: ServiceRequestCodes }>, value: ServiceRequestCodes) => {
    props.setSelectedRequestCodeThree(value)
  };

  const handleServiceCodeChangeFour = (event: React.ChangeEvent<{ value: ServiceRequestCodes }>, value: ServiceRequestCodes) => {
    props.setSelectedRequestCodeFour(value)
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          disabled={props.disabled}
          options={filteredCodes() as ServiceRequestCodes[]}
          getOptionLabel={(option) => `${option.code} ${option.description}`}
          onChange={handleServiceCodeChange}
          value={props.serviceRequestCodeOne}
          renderInput={(params) => <TextField {...params} label="Add Code" variant="standard"
          />}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disabled={props.disabled}
          options={filteredCodes()}
          getOptionLabel={(option) => `${option.code} ${option.description}`}
          onChange={handleServiceCodeChangeTwo}
          value={props.serviceRequestCodeTwo}
          renderInput={(params) => <TextField {...params} label="Add Code" variant="standard"
          />}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disabled={props.disabled}
          options={filteredCodes()}
          getOptionLabel={(option) => `${option.code} ${option.description}`}
          onChange={handleServiceCodeChangeThree}
          value={props.serviceRequestCodeThree}
          renderInput={(params) => <TextField {...params} label="Add Code" variant="standard"
          />}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disabled={props.disabled}
          options={filteredCodes()}
          getOptionLabel={(option) => `${option.code} ${option.description}`}
          onChange={handleServiceCodeChangeFour}
          value={props.serviceRequestCodeFour}
          renderInput={(params) => <TextField {...params} label="Add Code" variant="standard"
          />}
        />
      </Grid>
    </Grid>
  )
};