import React, { useReducer, useEffect } from 'react';
import { Grid, Typography, TextField, Button, FormControl, InputAdornment, IconButton } from '@material-ui/core';
import { EquipmentDocumentVM } from '../../brines-refrigerator-api';
import GetAppIcon from '@material-ui/icons/GetApp';

interface FormProps {
    title: string,
    formAction: Function,
    selectedEquipment: {
        id: number,
        manufacturer: { id: number, name: string },
        model: string,
        type: { id: number, name: string },
        serialNumber: string,
        roomLocation: string,
        notes?: '',
        customer_name: { id: number, company: string },
        customer_location: { id: number, name: string }
    },
    clearFields: Function,
    downloadDocument: Function
    documents: EquipmentDocumentVM[],
}

const EquipmentForm: React.FC<FormProps> = (props: FormProps) => {
    const { title, formAction, selectedEquipment, clearFields } = props;

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            equipment: {
                id: 0,
                manufacturer: { id: 0, name: '' },
                model: '',
                serialNumber: '',
                roomLocation: '',
                type: { id: 0, name: '' },
                notes: [{ createdBy: '', created: '', text: '' }],
                customer_name: { id: 0, company: '' },
                customer_location: { id: 0, name: '' }
            },
            formTitle: title,
        },
    );

    useEffect(() => {
        setState({
            ...state,
            formTitle: title
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        setState({
            ...state,
            equipment: {
                id: selectedEquipment.id,
                manufacturer: selectedEquipment.manufacturer,
                model: selectedEquipment.model,
                type: selectedEquipment.type,
                serialNumber: selectedEquipment.serialNumber,
                roomLocation: selectedEquipment.roomLocation,
                notes: selectedEquipment.notes,
                customer_name: selectedEquipment.customer_name,
                customer_location: selectedEquipment.customer_location
            },
            formTitle: title
        })
    }, [props.selectedEquipment]);

    const DocumentsFiles = () => {
        if (props.documents.length > 0) {
            return (
                <div className='document_files'>
                    {props.documents.map((document) =>
                        <FormControl fullWidth style={{ marginTop: '.5em' }} key={document.documentFileId}>
                            <TextField
                                type='text'
                                value={document.documentFile.name}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="download document"
                                                onClick={() => props.downloadDocument(document.documentFileId)}
                                            >
                                                <GetAppIcon color='primary' />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    )}
                </div>
            )
        } else {
            return (
                <div className='document_files'>
                    <Typography className='no_content'>No uploaded documents</Typography>
                </div>
            )
        }
    }

    function executeFormAction(e) {
        e.preventDefault();
        formAction(state.equipment.id)
    }

    return (
        <form onSubmit={e => executeFormAction(e)}>
            <Grid container className='divider'>
                <Grid container item xl={10} lg={9} sm={9} alignItems='center'>
                    <Grid item xl={3} lg={4} sm={4}>
                        <Typography className='title'>VIEW EQUIPMENT</Typography>
                    </Grid>
                    <Grid container item xl={9} lg={8} sm={8} xs={12} justify='space-evenly'>
                        <Grid container item xl={5} lg={5} sm={5} className='equipment_customer'>
                            <TextField
                                type='text'
                                label='Customer'
                                size='small'
                                name='customer_name'
                                value={state.equipment.customer_name.company}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid container item xl={5} lg={5} sm={5} className='equipment_location'>
                            <TextField
                                type='text'
                                label='Location'
                                size='small'
                                name='customer_location'
                                value={state.equipment.customer_location.name}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xl={2} lg={3} sm={3} justify='flex-end'>
                    <Button type='button' variant='outlined' color='primary' onClick={() => clearFields()}>Cancel</Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container item xl={4} lg={6} className='equipment_details'>
                    <Typography className='label' variant="h3">Equipment Details</Typography>
                    <Grid container item>
                        <TextField
                            type='text'
                            label='Manufacturer'
                            name='manufacturer'
                            value={state.equipment.manufacturer.name}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                            fullWidth
                        />
                        <TextField label='Model'
                            name='model'
                            value={state.equipment.model}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                            fullWidth
                        />
                        <TextField
                            label='Equipment Type'
                            name='type'
                            value={state.equipment.type.name}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                            fullWidth
                        />
                        <TextField label='Serial Number'
                            name='serialNumber'
                            value={state.equipment.serialNumber}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                            fullWidth
                        />
                        <TextField label='Equipment Location'
                            name='location'
                            value={state.equipment.roomLocation}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container item xl={4} lg={5} className='equipment_notes'>
                    <Typography className='label' variant="h3">Equipment Notes</Typography>
                    <Grid item container className='notes_container_textarea_display'>
                        {
                            (state.equipment.notes.length > 0) &&
                            state.equipment.notes.map(({ text, created, createdBy }) => (
                                <div className='notes_container_textarea_data_has_content'>
                                    <span>{createdBy ? createdBy.userName : 'Draft'}, {new Date(created).toLocaleDateString('en-US')}</span>
                                    <div style={{ marginTop: '.5em' }}>
                                        <span spellCheck>{text}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid container item xl={4} lg={6} className='equipment_documents'>
                    <Typography className='label' variant="h3">Documents</Typography>
                    <Grid style={{ width: '100%', height: '18em', marginTop: '1em' }}>
                        <DocumentsFiles />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify='flex-end' className='equipment_submit'>
                <Button type='submit' variant='contained' color='primary' style={{ width: '25em' }}>Edit equipment</Button>
            </Grid>
        </form>
    );
}

EquipmentForm.displayName = 'EquipmentForm';

export default EquipmentForm;