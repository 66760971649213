import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomerClient, DispatchClient, InvoiceClient, InvoiceLaborVM, InvoiceNotesClient, InvoicePartVM, InvoiceVM, UserBaseVM } from '../../../brines-refrigerator-api';
import './PreviewInvoice.scss';
import _ from 'lodash';
import { Button, CircularProgress } from '@material-ui/core';
import { downloadFile } from '../../../helpers/download';
import { useSnackbar } from 'notistack';

interface ServiceNoteEditVm {
  id?: number,
  dispatchId?: number,
  createdByCopyId?: number,
  createdByCopy?: UserBaseVM,
  createdCopy?: Date,
  lastModifiedByCopyId?: number,
  lastModifiedByCopy?: UserBaseVM,
  lastModifiedCopy?: Date,
  lastModifiedById?: number,
  lastModifiedBy?: UserBaseVM,
  lastModified?: Date,
  text?: string,
}

const PreviewInvoice = () => {
  const invoiceClient = new InvoiceClient();
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [invoice, setInvoice] = useState(new InvoiceVM({
    id: 0,
    parts: 0,
    labor: 0,
    materialTax: 0,
    laborTax: 0,
    number: '000000',
    trip: 0,
    tripTax: 0
  }));

  const [invoiceParts, setInvoiceParts] = useState<InvoicePartVM[]>([])
  const [laborList, setLaborList] = useState<InvoiceLaborVM[]>([]);

  useEffect(() => {
    getInvoice()
    getInvoiceParts()
    getInvoiceLabor()
    getServiceNotesAndSignatures()

  }, [location])

  const getInvoice = async () => {
    try {
      const invoice = await invoiceClient.getById((location.state as { invoiceId: number }).invoiceId);
      setInvoice(invoice);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const getInvoiceParts = async () => {
    try {
      const invoiceParts = await invoiceClient.getInvoiceParts((location.state as { invoiceId: number }).invoiceId)
      setInvoiceParts(invoiceParts);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const getInvoiceLabor = async () => {
    try {
      const labor = await invoiceClient.getInvoiceLabor((location.state as { invoiceId: number }).invoiceId);
      setLaborList(labor);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  //VISITS
  const [visits, setVisits] = useState({});

  const options = { weekday: 'long', year: 'numeric', day: 'numeric', month: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dispatchClient = new DispatchClient();
  const invoiceNotesClient = new InvoiceNotesClient();

  const viewSignature = async (fileId: number) => {
    try {
      const file = await dispatchClient.preview(fileId);
      return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => resolve(fileReader.result as string);
        fileReader.readAsDataURL(file.data);
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const getServiceNotesAndSignatures = async () => {
    try {
      const notes: ServiceNoteEditVm[] = await invoiceNotesClient.get((location.state as { invoiceId: number }).invoiceId, true)
      const dispatch = await dispatchClient.getById((location.state as { dispatchId: number }).dispatchId);
      let dict = {};

      notes.forEach(x => {
        if (!dict[x.createdCopy.toLocaleString('en-US', options)]) {
          dict[x.createdCopy.toLocaleString('en-US', options)] = { notes: [], signatures: [] };
        }
        dict[x.createdCopy.toLocaleString('en-US', options)].notes.push({ ...x })
      })

      for (const signature of dispatch.signatures) {
        if (!dict[signature.created.toLocaleString('en-US', options)]) {
          dict[signature.created.toLocaleString('en-US', options)] = { notes: [], signatures: [] };
        }
        const signaturePreview = await viewSignature(signature.documentFileId);
        dict[signature.created.toLocaleString('en-US', options)].signatures.push(signaturePreview)
      }

      setVisits(dict)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  function handleBackClick() {
    history.goBack();
  }

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const generatePdf = async () => {
    try {
      setIsGeneratingPdf(true);
      const response = await invoiceClient.generatePdf(invoice.id);
      setIsGeneratingPdf(false);
      await downloadFile(response.data, response.fileName);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setIsGeneratingPdf(false);
  }

  return (
    <div className='container' >
      {isGeneratingPdf && <CircularProgress size={100} className="progress" />}
      <div className="parts-page" >
        <table style={{ pageBreakInside: "avoid" }}>
          <thead style={{ pageBreakBefore: "always" }}>
            <tr>
              <td colSpan={7}>
                <div className="tittle-section">
                  <div className='flex-row'>
                    <div className='tittle'>
                      <h4 className="margin-block-header txt-left">Brines Refrigeration HTG & CLG</h4>
                      <h4 className="margin-block-header txt-left">26400 Southfield Rd</h4>
                      <h4 className="margin-block-header txt-left">Lathrup Village MI48076</h4>
                      <h4 className="margin-block-header txt-left">1-800-299-5509</h4>
                    </div>
                    <Button className="download-button" color='primary' variant='contained' size='small' onClick={generatePdf}>Download</Button>
                    <Button className="back-button" color='primary' variant='contained' size='small' onClick={handleBackClick}>Back</Button>
                    <div className='date'>
                      <h3 className="invoice-date-tittle margin-block-header txt-right margin-l-auto">Invoice</h3>
                      <div className="flex-row">
                        <h5 className="invoice-date margin-block-header txt-right margin-l-auto">Invoice date</h5>
                        <h5 className="invoice-work-order-tittle margin-block-header txt-right">Invoice #</h5>
                      </div>
                      <div className="flex-row">
                        <div>
                          <p className="invoice-date margin-block-header txt-right margin-l-auto">{dateTimeFormat.format(invoice.serviceDate)}</p>
                        </div>
                        <div>
                          <p className="invoice-work-order margin-block-header txt-right margin-l-auto">{invoice.number}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td className="width-20 txt-left" ><b>Bill to</b></td>
              <td className="width-20 txt-left"><b>Location</b></td>
              <td className="width-20" ><b>WO number</b></td>
              <td className="width-20" ><b>Service date</b></td>
              <td className="width-20" ><b>PO number</b></td>
            </tr>
            <tr>
              <td className="width-20 txt-left" >
                {invoice.customerName} {'\u00A0'}<br />
                {invoice.headquarterAddress1} {'\u00A0'}<br />
                {`${invoice.customerCity}, ${invoice.customerState}, ${invoice.customerZip}`}
              </td>
              <td className="width-20 txt-left" >
                {invoice.locationName} {'\u00A0'}<br />
                {invoice.locationAddress1} {'\u00A0'}<br />
                {invoice.locationAddress2} {'\u00A0'}<br />
                {`${invoice.locationCity}, ${invoice.locationState}, ${invoice.locationZip}`}
              </td>
              <td className="width-20" >{invoice.workOrderNumber} {'\u00A0'}</td>
              <td className="width-20" >{dateTimeFormat.format(invoice.serviceDate)} {'\u00A0'}</td>
              <td className="width-20" >{'\u00A0'}</td>
            </tr>
            <br />
          </thead>
          <tbody >
            <tr>
              <td colSpan={10}>
                <table>
                  <thead>
                    <tr className="table-cell-border"><td colSpan={10}><b>Parts</b></td></tr>
                    <tr>
                      <td className="table-cell-border width-15"><b>QTY</b></td>
                      <td className="table-cell-border width-55"><b>DESCRIPTION</b></td>
                      <td className="table-cell-border width-15"><b>PRICE EA</b></td>
                      <td className="table-cell-border width-15"><b>AMOUNT</b></td>
                    </tr>
                  </thead>
                  <tbody className="tbody-bottom-border" id="parts-table">
                    {invoiceParts.map(part => (
                      <tr className="table-row-border" key={part.id}>
                        <td className="td-border width-15 txt-center">{part.quantity}</td>
                        <td className="td-border width-55" >{part.description}</td>
                        <td className="td-border width-15 txt-right">{part.price.toFixed(2)}</td>
                        <td className="td-border width-15 txt-right">{part.subtotal.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr><td colSpan={10}>{'\u00A0'}</td></tr>
                  </tfoot>
                </table>
              </td>
            </tr>

            <tr>
              <td colSpan={10}>
                <table className="parts-total-table">
                  <tbody>
                    <tr>
                      <td>
                        <b className="ml-80">Total: {`$ ${invoice.parts.toFixed(2)}`}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <br />
            {/*================ LABOR TABLE ================== */}
            {laborList.length > 0 &&
              <>
                <tr>
                  <td colSpan={10}>
                    <table>
                      <tbody >
                        <tr>
                          <td colSpan={10}>
                            <table>
                              <thead>
                                <tr className="table-cell-border"><td colSpan={10}><b>Labor</b></td></tr>
                                <tr>
                                  <td className="table-cell-border width-50"><b>TECHNICIAN</b></td>
                                  <td className="table-cell-border width-20"><b>HOURLY RATE</b></td>
                                  <td className="table-cell-border width-15"><b>HOURS</b></td>
                                  <td className="table-cell-border width-20"><b>SUBTOTAL</b></td>
                                </tr>
                              </thead>
                              <tbody className="tbody-bottom-border">
                                {laborList.map(labor => (
                                  <tr className="table-row-border" key={labor.id}>
                                    <td className="td-border width-50 txt-center">{labor.technicianName}</td>
                                    <td className="td-border width-15 txt-right" >{labor.hourlyRate.toFixed(2)}</td>
                                    <td className="td-border width-15 txt-right" >{labor.hours}</td>
                                    <td className="td-border width-20 txt-right">{labor.subtotal.toFixed(2)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr><td colSpan={10}>{'\u00A0'}</td></tr>
                      </tfoot>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <b className="ml-80">Total: {`$ ${invoice.labor.toFixed(2)}`}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <br />
              </>
            }
            {/*=============== VISITS  ============== */}
            {_.isEmpty(visits) ? <></> :
              <>
                <tr>
                  <td colSpan={10}>

                    <table>

                      <tr>
                        <td colSpan={10}>
                          <table>
                            <thead>
                              <tr>
                                <td colSpan={10} className="table-cell-border width-15"><b>VISITS</b></td>
                              </tr>
                            </thead>
                            <tbody className="tbody-bottom-border">
                              {Object.keys(visits).map((date) => (
                                <>
                                  <tr className="table-row-border">
                                    <td className="table-cell-border" colSpan={10}><b>Tech visit: {date}</b></td>
                                  </tr>
                                  {_.range(Math.max(visits[date].notes.length, visits[date].signatures.length)).map((i) => (
                                    <>
                                      <tr>
                                        <td className="border-left"><p>
                                          {
                                            visits[date].notes[i].lastModifiedBy === undefined || visits[date].notes[i].lastModifiedBy === null
                                              ? visits[date].notes[i].lastModifiedByCopy === undefined || visits[date].notes[i].lastModifiedByCopy === null
                                                ? `Created by: ${visits[date].notes[i]?.createdByCopy.userName ?? ""}`
                                                : `Modified by: ${visits[date].notes[i]?.lastModifiedByCopy.userName ?? ""}`
                                              : `Modified by: ${visits[date].notes[i]?.lastModifiedBy.userName ?? ""}`
                                          }
                                        </p></td>
                                        <td><p>
                                          {
                                            visits[date].notes[i].lastModifiedBy === undefined || visits[date].notes[i].lastModifiedBy === null
                                              ? visits[date].notes[i].lastModifiedByCopy === undefined || visits[date].notes[i].lastModifiedByCopy === null
                                                ? `Created at: ${dateTimeFormat.format(visits[date].notes[i]?.createdCopy) ?? ""}`
                                                : `Modified at: ${dateTimeFormat.format(visits[date].notes[i]?.lastModifiedCopy) ?? ""}`
                                              : `Modified at: ${dateTimeFormat.format(visits[date].notes[i]?.lastModified) ?? ""}`
                                          }
                                        </p></td>
                                        <td className="border-right"><img src={visits[date].signatures[i]} style={{ height: "5em" }}></img></td>
                                      </tr>
                                      <tr>
                                        <td className="table-row-border" colSpan={8}>{`${visits[date].notes[i]?.text ?? ""}`} </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr><td colSpan={10}>{'\u00A0'}</td></tr>
                            </tfoot>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <br />
              </>
            }
            {/*=================== TOTALs TABLE  ===================*/}
            <tr>
              <td colSpan={10}>
                <table>
                  <tbody>
                    <tr>
                      <td colSpan={10}>
                        <table>
                          <thead>
                            <tr>
                              <td> &nbsp; </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr >
                              <td>
                                <b>Trip</b>
                              </td>
                              <td>
                              </td>
                              <td className='txt-right'>
                                <b>Trip Subtotal</b>
                              </td>
                            </tr>
                            <tr >
                              <td>
                                <p className="margin-block">{`$ ${invoice.trip}`} {'\u00A0'}</p>
                              </td>
                              <td>
                              </td>
                              <td className='txt-right'>
                                <p className="margin-block">{`$ ${(invoice.trip).toFixed(2)}`} {'\u00A0'}</p>
                              </td>
                            </tr>

                            <tr >
                              <td>
                                <b>Parts</b>
                              </td>
                              <td>
                              </td>
                              <td className='txt-right'>
                                <b>Material Subtotal</b>
                              </td>
                            </tr>
                            <tr >
                              <td>
                                <p className="margin-block">{`$ ${invoice.parts}`} {'\u00A0'}</p>
                              </td>
                              <td>
                              </td>
                              <td className='txt-right'>
                                <p className="margin-block">{`$ ${(invoice.parts).toFixed(2)}`} {'\u00A0'}</p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>Labor</b>
                              </td>
                              <td>
                              </td>
                              <td className='txt-right'>
                                <b>Labor Subtotal</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="margin-block">{`$ ${invoice.labor}`} {'\u00A0'}</p>
                              </td>
                              <td>
                              </td>
                              <td className='txt-right'>
                                <p className="margin-block">{`$ ${(invoice.labor).toFixed(2)}`} {'\u00A0'}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Taxes</b>
                              </td>
                              <td>
                              </td>
                              <td className="txt-right">
                                <b>Taxes</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {`$ ${(invoice.laborTax + invoice.tripTax + invoice.materialTax).toFixed(2)}`}
                              </td>
                              <td>
                              </td>
                              <td>
                                <p className="margin-block txt-right">
                                  {`$ ${(invoice.laborTax + invoice.tripTax + invoice.materialTax).toFixed(2)}`}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className='txt-right'>
                                <b>Total</b>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>
                                <p className="margin-block txt-right">{`$ ${(invoice.trip + invoice.tripTax + invoice.parts + invoice.labor + invoice.materialTax + invoice.laborTax).toFixed(2)}`}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div >
  );
}

export default PreviewInvoice;