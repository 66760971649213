import React from 'react';
import { Typography, GridList, GridListTile, makeStyles, createStyles, Paper, Grid } from '@material-ui/core';
import { UserBaseVM } from '../../../brines-refrigerator-api';

interface Note {
  id: number,
  created: Date,
  createdBy: UserBaseVM,
  lastModifiedBy: UserBaseVM,
  lastModified: Date,
  text: string,
}

interface TechNotesProps {
  notes: Note[]
}

const useStyles = makeStyles(() =>
  createStyles({
    tileList: {
      maxHeight: "25rem"
    },
    noteText: {
      marginTop: "1rem"
    },
    fontSize: {
      fontSize: "0.8rem"
    }
  })
);

export default function TechNotes(props: TechNotesProps) {
  const classes = useStyles();
  const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" square >
        <div>
          <GridList className={classes.tileList} cellHeight={160}>
            {props.notes.map((tile) => (
              <GridListTile key={tile.id} style={{ width: "100%" }} >
                <div>
                  <Grid container alignItems="center" >
                    <Grid container item xs>
                      <Grid item xs={12}>
                        {
                          tile.lastModifiedBy === undefined || tile.lastModifiedBy === null
                            ? <Typography className={classes.fontSize} gutterBottom>Added at: {dateTimeFormat.format(tile.created)}</Typography>
                            : <Typography className={classes.fontSize} gutterBottom>Modified at: {dateTimeFormat.format(tile.lastModified)}</Typography>
                        }
                      </Grid>
                      {
                        tile.lastModifiedBy === undefined || tile.lastModifiedBy === null
                          ? <Typography className={classes.fontSize} gutterBottom>Added by: {tile.createdBy.userName}</Typography>
                          : <Typography className={classes.fontSize} gutterBottom>Modified by: {tile.lastModifiedBy.userName}</Typography>
                      }
                    </Grid>
                  </Grid>
                  <Typography className={classes.noteText} variant="body2">
                    {tile.text}
                  </Typography>
                </div>
              </GridListTile>
            ))}
          </GridList>
        </div>
      </Paper>
    </Grid>
  )
};