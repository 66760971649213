import React, { useState } from 'react';

import './DispatchEquipment.scss';
import { Button, makeStyles, Box, Typography, Grid, } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { MTableToolbar } from 'material-table';
import Note from '@material-ui/icons/Note';
import Delete from '@material-ui/icons/Delete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ConfirmDialog from '../../../components/common/dialog/ConfirmationDialog';
import FormDialog from '../../../components/common/dialog/FormDialog';
import { EquipmentClient, EquipmentVM, EquipmentNoteCreateVM } from '../../../brines-refrigerator-api';
import tableIcons from '../../../components/common/table/tableIcons';
import EquipmentNotes from '../DispatchEquipmentNote/DispatchEquipmentNote';
import UploadButton from '../../../components/common/upload/UploadButton';
import UploadButtonType from '../../../helpers/constants/uploadButtonTypes';
import EquipmentChangeAction from '../../../helpers/constants/equipmentChangeActions';
import { useSnackbar } from 'notistack';
import { generateUID } from '../../../helpers/generateUID';
import NoteTempVM from '../../../components/common/note/NoteTempVM';


const useStyles = makeStyles({
    button: {
        marginBottom: "1em"
    },
    highlight: {
        backgroundColor: '#3f51b5cc',
        '& .MuiTypography-h6': {
            color: '#ffffff',
        },
    }
});

const dispatchHistorycolumns = [
    { title: 'S/N', field: 'serialNumber', sorting: false },
    { title: 'Model', field: 'model', sorting: false }
];

const addEquipmentColumns = [
    { title: 'S/N', field: 'serialNumber', sorting: false },
    { title: 'Manufacturer', field: 'manufacturer.name', sorting: false },
    { title: 'Model', field: 'model', sorting: false },
    { title: 'Room location', field: 'roomLocation', sorting: false }
]

const DispatchEquipment = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const tableActions = [
        (rowData: EquipmentVM) => ({
            icon: () => <UploadButton buttonType={UploadButtonType.Icon} onDropAction={(files: File[]) => addDocument(files, rowData.id)} />,
            onClick: () => { },
        }),
        rowData => ({
            icon: () => <Note color='primary' />,
            onClick: (event, rowData: EquipmentVM) => { openEquipmentNoteModal(rowData.id) }
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: EquipmentVM) => { openDialogue(rowData.id, rowData.model) }
        })
    ]

    function handleServerError(message) {
        if (message === undefined || message.response === undefined) {
            enqueueSnackbar("An error has occured", { variant: "error" });
            return;
        }

        for (let prop in message.response.data.errors) {
            let propErrors = message.response.data.errors[prop];

            propErrors.forEach(element => {
                enqueueSnackbar(element, { variant: "error" });
            });
        }
    }
    const classes = useStyles();

    //EQUIPMENT NOTES
    const [selectedEquipmentId, setSelectedEquipmentId] = useState(0);

    const [equipmentNoteModal, setEquipmentNoteModal] = useState(false)

    const [equipmentNotesForDisplay, setEquipmentNotesForDisplay] = useState([])

    const addDocument = (files: File[], id: number) => {
        props.handleEquipmentChange(EquipmentChangeAction.UploadDocument, { equipmentId: id, files })
    }

    //GET EQUIPMENT NOTES FOR DISPLAY
    const getEquipmentNotes = async (id: number) => {
        try {
            console.log("getting note");
            setSelectedEquipmentId(id)
            const equipmentClient = new EquipmentClient();
            const notes = await equipmentClient.getNotes(id, true);

            const _notes = notes.map(x => new NoteTempVM(
                x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, generateUID(), x.equipmentId))

            //we need to destructure and reinitialize the arr to force a rerender due to immutability

            setEquipmentNotesForDisplay([..._notes]);
        } catch (error) {
            handleServerError(error)
        }
    }

    const openEquipmentNoteModal = async (id: number) => {
        const newArr = new Array();
        setDraftEquipmentNotes([...newArr]);
        setRemovedEquipmentNotes([...newArr]);
        setEquipmentNotesForDisplay([...newArr]);
        getEquipmentNotes(id);
        setEquipmentNoteModal(true)
    }

    const closeEquipmentNoteModal = () => {
        setEquipmentNoteModal(false)
    }

    // ADD // EDIT // REMOVE NOTES
    const [removedEquipmentNotes, setRemovedEquipmentNotes] = useState([]);
    const [draftEquipmentNotes, setDraftEquipmentNotes] = useState([]);

    const addEquipmentNote = async (equipmentId, noteText) => {
        setSelectedEquipmentId(equipmentId);
        const uid = generateUID();
        const newNote = new NoteTempVM(null, new Date(), null, new Date(), null, noteText, true, uid, equipmentId);
        equipmentNotesForDisplay.push(newNote);
        const newEquipmentNotes = [...equipmentNotesForDisplay];
        setEquipmentNotesForDisplay(newEquipmentNotes);

        draftEquipmentNotes.push(newNote);
        const newDraftNotes = [...draftEquipmentNotes];
        setDraftEquipmentNotes(newDraftNotes);
    }

    const deleteEquipmentNoteCallback = (id: number, uid: string) => {
        const foundNote = equipmentNotesForDisplay.find(x => x.uid === uid);

        //remove the note from the overall note list
        const noteIndex = equipmentNotesForDisplay.findIndex(x => x.uid === uid);

        equipmentNotesForDisplay.splice(noteIndex, 1);
        //create a new array through destructuring as react would sometimes fail to re-render, because of react recognizing it to be completely the same(Even though it isn't)
        const newNotes = [...equipmentNotesForDisplay];

        setEquipmentNotesForDisplay(newNotes);

        if (foundNote.draft) {
            //if the specified note to remove is a draft we need to remove it from the draft notes list
            const draftNoteIndex = draftEquipmentNotes.findIndex(x => x.uid === uid);
            draftEquipmentNotes.splice(draftNoteIndex, 1);
            const newDraftNotes = [...draftEquipmentNotes];
            setDraftEquipmentNotes(newDraftNotes);
        } else {
            //otherwise it's a non-draft note and we need to add it to the list according to which back-end api calls are made to remove non-draft notes
            removedEquipmentNotes.push(foundNote.id);
        }
    }

    const updateEquipmentNotes = async () => {
        const client = new EquipmentClient();

        //function responsible for turning temp into permanent state
        for (const x of removedEquipmentNotes) {
            await client.deleteNote(x);
        };

        for (const x of draftEquipmentNotes) {
            await client.createNote(new EquipmentNoteCreateVM({
                equipmentId: x.pid,
                text: x.text
            }));
        }

        setDraftEquipmentNotes([]);
        setRemovedEquipmentNotes([]);
        setEquipmentNotesForDisplay([]);

        closeEquipmentNoteModal();
    }


    // EQUIPMENT NOTE MODAL BODY
    const equipmentNoteModalBody = (
        <EquipmentNotes
            notes={equipmentNotesForDisplay}
            deleteNoteCallback={deleteEquipmentNoteCallback}
            buttonAction={addEquipmentNote}
            button2Action={updateEquipmentNotes}
            buttonTitle={"Add Note"}
            closeModal={closeEquipmentNoteModal}
            equipmentId={selectedEquipmentId}
        />
    )

    //DELETE CONFIRMATION DIALOGUE
    const [confirm, setConfirmOpen] = useState(false);

    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [idForDelete, setIdForDelete] = useState(0);

    function confirmOpen() {
        setConfirmOpen(true);
    };

    function openDialogue(id: number, model: string) {

        setDialogTitle('Delete?')
        setDialogMessage(`Are you sure you want to remove ${model} from this dispatch?`)

        setIdForDelete(id);
        setConfirmOpen(true);
    };


    //ADD EQUIPMENT MODAL
    const [modalState, setModalState] = useState(false);
    const [equipment, setEquipment] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState([]);

    const getEquipment = async () => {
        try {
            const equipmentClient = new EquipmentClient();
            const equipment = await equipmentClient.getWithFilter(props.locationId);

            setEquipment(equipment);
        } catch (error) {
            handleServerError(error)
        }
    }

    const openAddEquipment = async () => {
        setModalState(true);
        await getEquipment();
    }

    const addSelectedEquipment = () => {
        props.addSelectedEquipment(selectedEquipment);

        setSelectedEquipment([]);
        setModalState(false);
    }

    const addEquipmentCloseHandler = () => {
        setModalState(false);
        setSelectedEquipment([]);
    }

    const addDispatchEquipmentModal = () => {

        return (
            <Grid className='equipment-modal' container xs={12}>
                <Grid item container xs={12}>
                    <Grid item xs={10}>
                        <Typography variant="h2">Add equipment</Typography>
                    </Grid>
                    <Grid item xs={2} justify="flex-end">
                        <Button endIcon={<CancelOutlinedIcon />} color='primary' size='medium' disableElevation fullWidth onClick={addEquipmentCloseHandler}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        columns={addEquipmentColumns}
                        title={""}
                        data={equipment as []}
                        icons={tableIcons}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            selection: equipment.length > 0,
                            paging: false
                        }}
                        components={{
                            Toolbar: props => (
                                <MTableToolbar classes={{ highlight: classes.highlight }} {...props} />
                            ),
                        }}
                        onSelectionChange={
                            (rows) => {
                                setSelectedEquipment(rows);
                            }
                        }
                    />
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        <Button color='primary' variant='contained' size='large' disabled={!(selectedEquipment.length > 0)} disableElevation fullWidth onClick={addSelectedEquipment}>ADD SELECTED</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <div className='dispatch-equipment_container'>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={async () => await openAddEquipment()}
                disabled={props.disabled}
            >
                Add Equipment
            </Button>
            <Box>
                <MaterialTable
                    columns={dispatchHistorycolumns}
                    title={""}
                    data={props.equipment as []}
                    components={{
                        Pagination: () => <div hidden={true}></div>
                    }}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        paging: false
                    }}
                    actions={tableActions}
                />
            </Box>
            {confirm && <ConfirmDialog
                title={dialogTitle}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => { props.handleEquipmentChange(EquipmentChangeAction.Delete, idForDelete) }}
            >
                {dialogMessage}
            </ConfirmDialog>
            }
            {modalState && <FormDialog open={modalState} body={addDispatchEquipmentModal()} />}
            {equipmentNoteModal && <FormDialog open={equipmentNoteModal} body={equipmentNoteModalBody} />}
        </div>
    )
}

export default DispatchEquipment;