import React, { useEffect, useReducer, ElementType } from 'react';
import { Grid, Button, InputAdornment, FormControl, Typography, TextField, InputBaseComponentProps } from '@material-ui/core';
import { CustomerVM, PhoneVM, HeadquarterVM } from '../../brines-refrigerator-api';
import { decimalNumberFormat, phoneNumberFormat } from '../../helpers/inputFormatters';
import UserRole from '../../helpers/constants/userRole';

interface FormProps {
    button: JSX.Element,
    customer: CustomerVM,
    formAction: Function
}

interface StateType {
    id?: number,
    company?: string,
    number?: string,
    active?: boolean,
    laborRate?: number,
    tripRate?: number,
    otRate?: number,
    holidayRate?: number,
    customerMarkup?: number,
    phones?: PhoneVM[],
    headquarterLocation: HeadquarterVM,
    date: Date,
}

export default function CustomerForm(props: FormProps) {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const [statee, setStatee] = useReducer(
        (state: StateType, newState: StateType) => ({ ...state, ...newState }),
        {
            id: props.customer.id,
            company: props.customer.company,
            active: props.customer.active,
            laborRate: props.customer.laborRate,
            tripRate: props.customer.tripRate,
            otRate: props.customer.otRate,
            holidayRate: props.customer.holidayRate,
            customerMarkup: props.customer.customerMarkup,
            phones: props.customer.phones,
            headquarterLocation: props.customer.headquarterLocation,
            date: props.customer.created
        },
    );

    useEffect(() => {
        setStatee({
            id: props.customer.id,
            company: props.customer.company,
            active: props.customer.active,
            laborRate: props.customer.laborRate,
            tripRate: props.customer.tripRate,
            otRate: props.customer.otRate,
            holidayRate: props.customer.holidayRate,
            customerMarkup: props.customer.customerMarkup,
            phones: props.customer.phones,
            headquarterLocation: props.customer.headquarterLocation,
            date: new Date()
        })
    }, [props])

    const executeFormAction = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        props.formAction(Number(statee.id))
    }

    // Get user data from session storage
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    //get user role
    const role = userData.role.name;

    return (
        <>
            <Grid className='customer_crud_form_title'>
                <Grid container spacing={1}>
                    <Grid item container xs={6} alignContent='center'>
                        <Typography><b>CUSTOMER</b></Typography>
                    </Grid>
                    <Grid item container xl={6} xs={6} justify='flex-end'>
                        {props.button}
                    </Grid>
                </Grid>
                <hr />
            </Grid>
            <form noValidate autoComplete="off"
                onSubmit={e => executeFormAction(e)}>
                <Grid container className='customer_form'>
                    <Grid item container xl={6} sm={6}>
                        <div className='customer_details'>
                            <Typography>Customer details</Typography>
                            <Grid item container>
                                <TextField
                                    label="Date Added"
                                    defaultValue={dateTimeFormat.format(statee.date)}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                                <TextField
                                    name='company'
                                    label="Company Name"
                                    value={statee.company}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                                <TextField
                                    name='address1'
                                    label="Address 1"
                                    value={statee.headquarterLocation ? (statee.headquarterLocation.addressLine1 ? statee.headquarterLocation.addressLine1 : null) : null}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                                <TextField
                                    name='address2'
                                    label="Address 2"
                                    value={statee.headquarterLocation ? (statee.headquarterLocation.addressLine2 ? statee.headquarterLocation.addressLine2 : null) : null}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                                <TextField
                                    name='city'
                                    label="City"
                                    value={statee.headquarterLocation ? (statee.headquarterLocation.city ? statee.headquarterLocation.city : null) : null}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                                <Grid item container>
                                    <Grid container spacing={1}>
                                        <Grid item xl={8} xs={6}>
                                            <FormControl required fullWidth>
                                                <TextField
                                                    name='state'
                                                    label="State"
                                                    value={statee.headquarterLocation ? (statee.headquarterLocation.state ? statee.headquarterLocation.state.name : null) : null}
                                                    InputProps={{
                                                        readOnly: true,
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xl={4} xs={6}>
                                            <TextField
                                                name='zip'
                                                label="ZIP"
                                                value={statee.headquarterLocation ? (statee.headquarterLocation.zip ? statee.headquarterLocation.zip : null) : null}
                                                InputProps={{
                                                    readOnly: true,
                                                    disableUnderline: true,
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item container xl={6} sm={6}>
                        <Grid className='customer_contacts' xl={12} sm={12}>
                            <Typography>Contacts</Typography>
                            {statee.phones[0] && <Grid item container>
                                <Grid item container xl={5} sm={5} xs={6} className='customer_contacts_phone'>
                                    <FormControl>
                                        <TextField
                                            label="Phone Type"
                                            value={statee.phones[0] ? statee.phones[0].phoneType.name : ""}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container xl={7} sm={7} className='customer_contacts_phone'>
                                    <TextField
                                        name='phone1'
                                        label="Phone"
                                        value={statee.phones[0] ? statee.phones[0].number : ""}
                                        InputProps={{
                                            inputComponent: phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                            readOnly: true,
                                            disableUnderline: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>}
                            {statee.phones[1] && <Grid item container>
                                <Grid item container xl={5} sm={5} xs={6} className='customer_contacts_phone'>
                                    <FormControl>
                                        <TextField
                                            label="Phone Type"
                                            value={statee.phones[1] ? statee.phones[1].phoneType.name : ""}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container xl={7} sm={7} className='customer_contacts_phone'>
                                    <TextField
                                        name='phone2'
                                        label="Phone"
                                        value={statee.phones[1] ? statee.phones[1].number : ""}
                                        InputProps={{
                                            inputComponent: phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                            readOnly: true,
                                            disableUnderline: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>}
                            {statee.phones[2] && <Grid item container>
                                <Grid item container xl={5} sm={5} xs={6} className='customer_contacts_phone'>
                                    <FormControl>
                                        <TextField
                                            label="Phone Type"
                                            value={statee.phones[2] ? statee.phones[2].phoneType.name : ""}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container xl={7} sm={7} className='customer_contacts_phone'>
                                    <TextField
                                        name='phone3'
                                        label="Phone"
                                        value={statee.phones[2] ? statee.phones[2].number : ""}
                                        InputProps={{
                                            inputComponent: phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                            readOnly: true,
                                            disableUnderline: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid container xl={12} className='customer_rates_grid'>
                        <Grid item container xl={12} justify='space-between' className='customer_rates' >
                            <Grid item container xl={12}>
                                <Typography>Rates</Typography>
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='laborRate'
                                    label="Labor"
                                    defaultValue={999.99}
                                    value={statee.laborRate}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='otRate'
                                    label="OT"
                                    defaultValue={999.99}
                                    value={statee.otRate}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='tripRate'
                                    label="Trip"
                                    defaultValue={999.99}
                                    value={statee.tripRate}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='holidayRate'
                                    label="Holiday"
                                    defaultValue={999.99}
                                    value={statee.holidayRate}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xl={2} sm={2} xs={6}>
                                <TextField
                                    className='customer_rates_input'
                                    name='customerMarkup'
                                    label="Markup"
                                    defaultValue={999.99}
                                    value={statee.customerMarkup}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={1}>
                    <Grid item container justify='flex-end'>
                        <Grid item lg={4} sm={6} xs={8}>
                            <Button type='submit' color='primary' variant='contained' size='large' fullWidth disabled={role === UserRole.Dispatcher || role === UserRole.Technician}>
                                Edit Customer
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
