import React from 'react';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

interface SnackbarNotificationProps {
  open: boolean,
  snackbarMessage: string,
  handleClose: Function,
  severity: Color,
  snackbarDuration?: number
}

export default function SnackbarNotification(props: SnackbarNotificationProps) {

  const snackbarDuration = props.snackbarDuration ? props.snackbarDuration : 4000;

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = () => {
    props.handleClose()
  };

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={snackbarDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={props.severity}>
        {props.snackbarMessage}
      </Alert>
    </Snackbar>
  )
}
