import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { EquipmentDocumentVM, SwaggerException, SignatureVM } from "./brines-refrigerator-api";

export class UploadFileClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    async uploadEquipmentDocument(documentFile: EquipmentDocumentAddVM | undefined): Promise<EquipmentDocumentVM[]> {
        let url_ = this.baseUrl + "/api/v1/Equipment/Upload";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = new FormData();
        content_.append('equipmentId', documentFile.equipmentId.toString());
        content_.append('name', documentFile.name);
        content_.append('isConfidential', documentFile.isConfidential ? 'true' : 'false');
        content_.append('file', documentFile.file, documentFile.file.name);

        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_
        };

        const _response = await this.instance.request(options_);
        return this.processCreate(_response);
    }

    protected processCreate(response: AxiosResponse): Promise<EquipmentDocumentVM[]> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            if (Array.isArray(resultData200)) {
                result200 = [] as any;
                for (let item of resultData200)
                    result200!.push(EquipmentDocumentVM.fromJS(item));
            }
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<EquipmentDocumentVM[]>(<any>null);
    }

    async addSignature(signature: SignatureAddVM | undefined): Promise<void> {
        let url_ = this.baseUrl + "/api/v1/Dispatch/AddSignature";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = new FormData();
        content_.append('dispatchId', signature.dispatchId.toString());
        content_.append('name', signature.name);
        content_.append('text', signature.text);
        if (signature.file) {
            content_.append('file', signature.file, signature.file.name);
        }

        let options_ = <AxiosRequestConfig>{
            data: content_,
            method: "POST",
            url: url_
        };

        const _response = await this.instance.request(options_);
        return this.processCreateSignature(_response);
    }

    protected processCreateSignature(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            if (Array.isArray(resultData200)) {
                result200 = [] as any;
                for (let item of resultData200)
                    result200!.push(SignatureVM.fromJS(item));
            }
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new SwaggerException(message, status, response, headers, null);
}

export class EquipmentDocumentAddVM implements IEquipmentDocumentAddVM {
    equipmentId!: number;
    name!: string;
    isConfidential?: boolean;
    file: File;

    constructor(data?: IEquipmentDocumentAddVM) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.equipmentId = _data["equipmentId"];
            this.name = _data["name"];
            this.isConfidential = _data["isConfidential"];
            this.file = _data["file"];
        }
    }

    static fromJS(data: any): EquipmentDocumentAddVM {
        data = typeof data === 'object' ? data : {};
        let result = new EquipmentDocumentAddVM();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["equipmentId"] = this.equipmentId;
        data["name"] = this.name;
        data["isConfidential"] = this.isConfidential;
        data["file"] = this.file;
        return data;
    }
}

export interface IEquipmentDocumentAddVM {
    equipmentId: number;
    name: string;
    isConfidential?: boolean;
    file: File;
}

export class SignatureAddVM implements ISignatureAddVM {
    dispatchId!: number;
    name!: string;
    text?: string;
    file?: File;
    created?: Date | undefined;

    constructor(data?: ISignatureAddVM) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.dispatchId = _data["dispatchId"];
            this.name = _data["name"];
            this.text = _data["text"];
            this.file = _data["file"];
            this.created = _data["created"] ? new Date(_data["created"].toString()) : <any>undefined;
        }
    }

    static fromJS(data: any): SignatureAddVM {
        data = typeof data === 'object' ? data : {};
        let result = new SignatureAddVM();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["dispatchId"] = this.dispatchId;
        data["name"] = this.name;
        data["text"] = this.text;
        data["file"] = this.file;
        data["created"] = this.created ? this.created.toISOString() : <any>undefined;
        return data;
    }
}

export interface ISignatureAddVM {
    dispatchId: number;
    name: string;
    text?: string;
    file?: File;
    created?: Date | undefined;
}