import React, { useEffect, useReducer, useState } from 'react';
import { Button, Grid, Container, Paper, Typography, TextField, InputAdornment } from '@material-ui/core';
import { LocationClient, LocationVM, CustomerClient, LocationAddVM, StateClient, ContactAddVM, ContactClient, LocationBaseVM, LocationNoteCreateVM, LocationUpdateVM, StateVM } from '../../brines-refrigerator-api';
import BasicTable from '../../components/common/table/BasicTable'
import LocationCRUDForm from '../../components/location/LocationCRUDForm'
import ConfirmDialog from '../../components/common/dialog/ConfirmationDialog';
import UserRole from '../../helpers/constants/userRole';
import Delete from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import { useHistory } from 'react-router-dom';
import './Location.scss';
import { useSnackbar } from 'notistack';
import { redirectIfSessionExpired } from '../../components/common/redirect/RedirectOnSessionTimeout';
import { useDebouncedSearch } from '../../helpers/search';
import SearchIcon from '@material-ui/icons/Search';
import LaunchIcon from '@material-ui/icons/Launch';
import LocationForm from '../../components/location/LocationForm';

interface StateType {
    formTitle: string,
    location: LocationVM,
    formAction: Function
}

export default function LocationView() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();

    // Get user data from session storage
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    //get user role
    const role = userData.role.name;

    function handleServerError(message) {
        if (message === undefined || message.response === undefined) {
            enqueueSnackbar("An error has occured", { variant: "error" });
            return;
        }

        for (let prop in message.response.data.errors) {
            let propErrors = message.response.data.errors[prop];

            propErrors.forEach(element => {
                enqueueSnackbar(element, { variant: "error" });
            });
        }
    }

    const addNewLocation = async (e: React.FormEvent<HTMLFormElement>, id: number, stateId: number, customerId: number, contacts: ContactAddVM[], notesToAdd: LocationNoteCreateVM[]) => {
        e.preventDefault();

        const form = e.currentTarget;
        const location = new LocationAddVM({
            name: form.locationName.value,
            addressLine1: form.addressLine1.value,
            addressLine2: form.addressLine2.value,
            city: form.city.value,
            zip: form.zip.value,
            ivr: form.ivr.value,
            emsNumber: form.emsNumber.value,
            active: true,
            pmInfo: form.pmInfo.value,
            stateId: stateId,
            customerId: customerId,
            contacts: contacts,
            notes: notesToAdd,
            laborRate: Number(form.laborRate.value),
            tripRate: Number(form.tripRate.value),
            otRate: Number(form.otRate.value),
            holidayRate: Number(form.holidayRate.value),
            locationMarkup: Number(form.locationMarkup.value),
        });

        try {
            const locationClient = new LocationClient();
            await locationClient.post(location);
            clearFields()
            setTableLoading(true);
            populateTable()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }

    }
    const [state, setState] = useReducer(
        (state: StateType, newState: StateType) => ({ ...state, ...newState }),
        {
            location: new LocationVM({
                id: null,
                name: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                zip: '',
                ivr: '',
                emsNumber: '',
                active: true,
                pmInfo: '',
                stateId: 0,
                state: new StateVM({
                    id: '0',
                    abbreviation: '',
                    name: ''
                }),
                customerId: 0,
                contacts: []
            }),
            formTitle: 'ADD LOCATION',
            formAction: addNewLocation
        },
    );

    const [locations, setLocations] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [states, setStates] = useState([]);
    const [tableIsLoading, setTableLoading] = useState(true);

    const editLocation = async (e: React.FormEvent<HTMLFormElement>, id: number, stateId: number, customerId: number, contacts: ContactAddVM[], notesToAdd: LocationNoteCreateVM[]) => {
        e.preventDefault();

        const contactClient = new ContactClient();
        contacts.forEach(async contact => {
            try {
                if (contact.hasOwnProperty('id')) {
                    await contactClient.put(contact);
                } else {
                    contact.locationId = id;
                    await contactClient.post(contact);
                }
            } catch (error) {
                redirectIfSessionExpired(history, error)
                handleServerError(error)
            }
        });

        const locationClient = new LocationClient();
        notesToAdd.forEach(async note => {
            try {
                locationClient.createNote(note);
            }
            catch (error) {
                redirectIfSessionExpired(history, error)
                handleServerError(error)

            }
        })

        const form = e.currentTarget;
        const location = new LocationUpdateVM({
            id: id,
            name: form.locationName.value,
            addressLine1: form.addressLine1.value,
            addressLine2: form.addressLine2.value,
            city: form.city.value,
            zip: form.zip.value,
            ivr: form.ivr.value,
            emsNumber: form.emsNumber.value,
            active: true,
            pmInfo: form.pmInfo.value,
            stateId: stateId,
            customerId: customerId,
            laborRate: Number(form.laborRate.value),
            tripRate: Number(form.tripRate.value),
            otRate: Number(form.otRate.value),
            holidayRate: Number(form.holidayRate.value),
            locationMarkup: Number(form.locationMarkup.value),
        });
        try {
            await locationClient.put(location);
            clearFields()
            setTableLoading(true);
            populateTable()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }

    }
    const button = <Button variant="outlined" onClick={(e: React.MouseEvent<HTMLElement>) => clearFields()} color='primary'>Cancel</Button>

    const columns = [
        { title: '#', field: 'id' },
        { title: 'Location', field: 'location' },
        { title: 'ZIP', field: 'zip' }
    ];

    function clearFields() {
        setState({
            ...state,
            formTitle: 'ADD LOCATION',
            formAction: addNewLocation,
            location: new LocationVM({
                id: null,
                name: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                zip: '',
                ivr: '',
                emsNumber: '',
                active: true,
                pmInfo: '',
                stateId: 0,
                state: new StateVM({
                    id: '0',
                    abbreviation: '',
                    name: ''
                }),
                customerId: 0,
                laborRate: 0,
                tripRate: 0,
                otRate: 0,
                holidayRate: 0,
                locationMarkup: 0,
                contacts: [],
                notes: []
            })
        })
    }

    async function setLocationForEditing(id: number) {
        const locationClient = new LocationClient();
        const location = await locationClient.getById(id);
        setState({
            ...state,
            formTitle: 'EDIT LOCATION',
            formAction: editLocation,
            location: new LocationVM({
                id: id,
                name: location.name,
                addressLine1: location.addressLine1,
                addressLine2: location.addressLine2,
                city: location.city,
                zip: location.zip,
                ivr: location.ivr,
                emsNumber: location.emsNumber,
                active: true,
                pmInfo: location.pmInfo,
                stateId: location.stateId,
                state: location.state,
                customerId: location.customerId,
                laborRate: location.laborRate,
                tripRate: location.tripRate,
                otRate: location.otRate,
                holidayRate: location.holidayRate,
                locationMarkup: location.locationMarkup,
                contacts: location.contacts,
                notes: location.notes
            })
        })
    };

    async function deleteLocation(id: number) {
        try {
            const locationClient = new LocationClient();
            await locationClient.toggleArchivedStatus(id);
            clearFields()
            setTableLoading(true);
            populateTable()

        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    };

    async function populateTable() {
        try {
            const locationClient = new LocationClient();
            const locations = await locationClient.getByTeam();
            setLocations(locations.map((e: LocationBaseVM) => ({ id: Number(e.id), location: e.name, zip: e.zip, isArchived: e.isArchived })).filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived));
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
        setTableLoading(false);
    };

    async function getCustomers() {
        try {
            const customerClient = new CustomerClient();
            const customers = await customerClient.getCustomersBase();
            setCustomers(customers);
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    };

    async function getStates() {
        try {
            const stateClient = new StateClient();
            const states = await stateClient.get();
            setStates(states);
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            await populateTable();
            getCustomers();
            getStates();
        }
        fetchDataAsync()
    }, []);

    const [confirm, setConfirmOpen] = useState(false);
    const [idForDelete, setidForDelete] = useState(0);

    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');

    function confirmOpen() {
        setConfirmOpen(true);
    };

    function openDialog(id: number, toArchive: boolean) {
        if (toArchive) {
            setDialogTitle('Delete?')
            if (userData.role.name === UserRole.Admin) {
                setDialogMessage('Are you sure you want to archive this Location?')
            } else {
                setDialogMessage('Are you sure you want to delete this Location?')
            }
        }
        else {
            setDialogTitle('Return?')
            setDialogMessage('Are you sure you want to restore this Location?')
        }
        setConfirmOpen(true);
        setidForDelete(id);
    };

    const openDispatchWithLocation = (locationId) => {
        history.push({
            pathname: "/dispatch",
            state: { dispatchIdFromTechDispatchView: locationId }
        });
    }

    const setLocationForViewing = async (id: number) => {
        const locationClient = new LocationClient();
        const location = await locationClient.getById(id);
        setState({
            ...state,
            formTitle: 'VIEW LOCATION',
            formAction: editLocation,
            location: new LocationVM({
                id: id,
                name: location.name,
                addressLine1: location.addressLine1,
                addressLine2: location.addressLine2,
                city: location.city,
                zip: location.zip,
                ivr: location.ivr,
                emsNumber: location.emsNumber,
                active: true,
                pmInfo: location.pmInfo,
                stateId: location.stateId,
                customerId: location.customerId,
                laborRate: location.laborRate,
                tripRate: location.tripRate,
                otRate: location.otRate,
                holidayRate: location.holidayRate,
                locationMarkup: location.locationMarkup,
                contacts: location.contacts,
                notes: location.notes
            })
        })
    }

    const tableActions = [
        rowData => ({
            icon: () => <AirportShuttleIcon color='primary' />,
            onClick: (event, rowData: unknown) => { openDispatchWithLocation((rowData as { id: number }).id) },
            tooltip: "Open Dispatch Within Location"
        }),
        rowData => ({
            icon: () => <LaunchIcon color='primary' />,
            onClick: (event, rowData: unknown) => { setLocationForViewing((rowData as { id: number }).id) },
            tooltip: "View Location"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { openDialog((rowData as { id: number }).id, true) },
            hidden: rowData['isArchived'] || role === UserRole.Dispatcher || role === UserRole.Technician,
            tooltip: "Delete Location"
        }),
        rowData => ({
            icon: () => <RestoreIcon color='primary' />,
            onClick: (event, rowData: unknown) => { openDialog((rowData as { id: number }).id, false) },
            hidden: !rowData['isArchived'] || role === UserRole.Dispatcher || role === UserRole.Technician,
            tooltip: "Restore Location"
        }),
    ]

    const searchLocations = async (input: string) => {
        const client = new LocationClient();
        const locations = await client.getByFilter(input);

        return locations.map((e: LocationBaseVM) => ({ id: Number(e.id), location: e.name, zip: e.zip, isArchived: e.isArchived })).filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived)
    }

    const { inputText, setInputText, search } = useDebouncedSearch((text: string) => searchLocations(text))

    return (
        <>
            <main className='location'>
                <Container maxWidth={false} >
                    <Grid container>
                        <Grid item xl={6} lg={6} md={6} xs={12} className='location_container'>
                            <div className='location_table'>
                                <Grid container item xs={12}>
                                    <Grid item sm={8}>
                                        <Typography variant="h1">Locations List</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Search"
                                            value={inputText}
                                            onChange={(e) => {
                                                setInputText(e.target.value)
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <BasicTable
                                    columns={columns}
                                    title="LOCATIONS LIST"
                                    data={inputText ? search.result : locations as []}
                                    actions={tableActions}
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />,
                                        Toolbar: () => <></>,
                                    }}
                                    paging={true}
                                    isLoading={tableIsLoading || search.loading}
                                />
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} xs={12}>
                            {state.formTitle !== 'VIEW LOCATION' && <div className='location_crud_form'>
                                <LocationCRUDForm
                                    title={state.formTitle}
                                    button={button}
                                    location={state.location}
                                    customers={customers}
                                    states={states}
                                    formAction={state.formAction}
                                />
                            </div>}
                            {
                                state.formTitle == 'VIEW LOCATION' && <div className='location_crud_form'>
                                    <LocationForm
                                        title={"VIEW LOCATION"}
                                        button={button}
                                        location={state.location}
                                        customers={customers}
                                        states={states}
                                        formAction={state.formAction}
                                        editLocation={setLocationForEditing}
                                    />
                                </div>}

                        </Grid>
                    </Grid>
                </Container>

                {confirm && <ConfirmDialog
                    title={dialogTitle}
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={deleteLocation}
                    param={idForDelete}
                >
                    {dialogMessage}
                </ConfirmDialog>
                }
            </main>
        </>
    );
}
