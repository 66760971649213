import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { EquipmentTypeClient, EquipmentTypeAddVM } from '../../../brines-refrigerator-api';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import AddTable from '../../common/table/AddTable';
import ConfirmDialog from '../../common/dialog/ConfirmationDialog';
import SnackbarNotification from '../../common/snackbar/SnackbarNotification';
import { Color } from '@material-ui/lab/Alert';
import { validateText } from '../../../helpers/validations';

const EquipmentType: React.FC = () => {
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [idForDelete, setIdForDelete] = useState(0);
  const [dialogMessage, setDialogMessage] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<Color>()
  const history = useHistory();

  async function getEquipmentTypes() {
    try {
      const eqTypeClient = new EquipmentTypeClient();
      const equipmentTypes = await eqTypeClient.get();

      setEquipmentTypes(equipmentTypes)
    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error while getting equipment types.', "error")
    }
  };

  useEffect(() => {
    getEquipmentTypes()
  }, []);

  const addEquipment = async (data: unknown) => {
    const result = (data as { name: string }).name ? validateText((data as { name: string }).name) : { error: true, errorMessage: 'This field can\'t be empty.' };
    if (result.error) {
      setSnackBarState(true, result.errorMessage, "error")
      return;
    }
    try {
      const eqTypeClient = new EquipmentTypeClient();
      await eqTypeClient.create(new EquipmentTypeAddVM({
        name: (data as { name: string }).name
      }));

      getEquipmentTypes()
      setSnackBarState(true, 'Equipment type added successfully.', "success")

    } catch (error) {
      setSnackBarState(true, 'Error while adding equipment type.', "error")
    }
  }

  const deleteEquipmentType = async (id: number) => {
    try {
      const eqTypeClient = new EquipmentTypeClient();
      await eqTypeClient.delete(id);

      setSnackBarState(true, 'Equipment type deleted.', "success")
      getEquipmentTypes()
    } catch (error) {
      setSnackBarState(true, 'Error while deleting equipment type.', "error")
    }
  }

  const equipmentTypesColums = [
    { title: 'Equipment Types', field: 'name' },
  ];

  const openDeleteDialogue = (id: number) => {
    setIdForDelete(id);
    setDialogMessage(`Are you sure you want to delete Equipment Type #${id}?`);
    setConfirmOpen(true);
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const setSnackBarState = (state: boolean, message: string, severity: Color) => {
    setSnackbarOpen(state)
    setSnackbarMessage(message)
    setSeverity(severity)
  }

  const tableTooltip = { addTooltip: "Enter Equipment Type" }

  return (
    <Grid item sm={12} container>
      <SnackbarNotification
        open={snackbarOpen}
        snackbarMessage={snackbarMessage}
        handleClose={handleClose}
        severity={severity}
      />
      <AddTable
        width={'100%'}
        columns={equipmentTypesColums}
        title={"Equipment Types"}
        add={addEquipment}
        delete={openDeleteDialogue}
        data={equipmentTypes as []}
        search={false}
        localization={tableTooltip}
        deleteTooltip={"Delete Equipment Type"}
      />
      {confirmOpen && <ConfirmDialog
        title={"Delete equipment type?"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => { deleteEquipmentType(idForDelete) }}
      >
        {dialogMessage}
      </ConfirmDialog>
      }
    </Grid>
  );
}

export default EquipmentType;