import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import "./CustomCard.scss"
import {
  MovableCardWrapper,
} from 'react-trello/dist/styles/Base'

interface CustomCardProps {
  id: number,
  onClick: Function,
  style: {
    backgroundColor: string;
    maxWidth: string;
    minWidth: string;
    filter: string;
  },
  locationName: string,
  workOrderNumber: string,
  city: string,
  state: string,
}


const useStyles = makeStyles(() =>
  createStyles({
    cardWrapper: {
      color: "white"
    },
    cardHeader: {
      borderBottom: "none"
    },
    cardContentText: {
      fontSize: "0.7rem",
      fontWeight: 500
    },
    cardDateContentText: {
      fontSize: "0.7rem",
      fontWeight: 500,
      whiteSpace: "break-spaces"
    }
  }),
);

export function CustomCard(props: CustomCardProps) {
  const classes = useStyles();

  return (
    <MovableCardWrapper
      data-id={props.id}
      onClick={props.onClick}
      style={props.style}
      className="card-wrapper"
    >
      <Grid container>
        <Grid item container>
          <Grid item sm={12}>
            <Typography className={isNaN(Date.parse(props.locationName)) ? classes.cardContentText : classes.cardDateContentText}>
              {props.locationName}
            </Typography>
          </Grid>
          {props.workOrderNumber && <Grid item sm={12}><Typography className={classes.cardContentText}>{props.workOrderNumber}</Typography></Grid>}
          {props.city && <Grid item sm={12}><Typography className={classes.cardContentText}>{`${props.city} ${props.state}`}</Typography></Grid>}
          {/* <Grid item sm={12}><Typography className={classes.cardContentText}>{props.state}</Typography> </Grid> */}
        </Grid>
      </Grid>
    </MovableCardWrapper>
  )
}