import { Box, Button, Grid, makeStyles, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { DispatchClient, DispatchDocumentsClient, DispatchNotesClient, EquipmentClient, InternalNotesClient, PartClient, ServiceNotesClient, UserVM } from '../../../brines-refrigerator-api';
import NoteTempVM from '../../../components/common/note/NoteTempVM';
import { useSnackbar } from 'notistack';
import DispatchNotesPreview from '../../../components/common/note/DispatchNotesPreview';
import DispatchLaborPreview from '../DispatchLabor/DispatchLaborPreview';
import DispatchPartsPreview from '../DispatchParts/DispatchPartsPreview';
import DispatchEquipmentPreview from '../DispatchEquipment/DispatchEquipmentPreview';
import DispatchDocumentsPreview from '../DispatchDocuments/DispatchDocumentsPreview';
import { DispatchDocumentAddVM } from '../DispatchDocuments/DispatchDocumentsClient';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

interface DispatchFormProps {
    dispatchId: number,
    locationId: number,
    setDispatchForEditing: Function,
    clearFields: Function,
    technicians: UserVM[],
}

const useStyles = makeStyles({
    button: {
        marginBottom: "1em"
    },
    boxScroll: {
        overflowY: "auto",
        overflowX: "hidden"
    },
    partTable: {
        width: '60rem',
        height: '30rem'
    }
});

const DispatchForm = (props: DispatchFormProps) => {
    const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
    const slaDateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [dispatch, setDispatch] = useState(null);
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const [dispatchNotes, setDispatchNotes] = useState([]);

    async function getDispatchNotes() {
        try {
            const notesClient = new DispatchNotesClient();
            const dispatchNotes = await notesClient.get(props.dispatchId, true);
            const modifiedDispatchNotes = dispatchNotes.map((x) => new NoteTempVM(x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, null));
            setDispatchNotes(modifiedDispatchNotes);
        } catch (error) {
            handleServerError(error)
        }
    };

    const [serviceNotes, setServiceNotes] = useState([]);

    async function getServiceNotes() {
        try {
            const serviceNotesClient = new ServiceNotesClient();
            const serviceNotes = await serviceNotesClient.get(props.dispatchId, true);
            const modifiedServiceNotes = serviceNotes.map((x) => new NoteTempVM(x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, null));
            setServiceNotes(modifiedServiceNotes);
        } catch (error) {
            handleServerError(error)
        }
    }

    const [internalNotes, setInternalNotes] = useState([]);

    async function getInternalNotes() {
        try {
            const internalNotesClient = new InternalNotesClient();
            const internalNotes = await internalNotesClient.get(props.dispatchId, true);
            const modifiedInternalNotes = internalNotes.map((x) => new NoteTempVM(x.id, x.created, x.createdBy, x.lastModified, x.lastModifiedBy, x.text, false, null));
            setInternalNotes(modifiedInternalNotes);
        } catch (error) {
            handleServerError(error)
        }
    }

    const [dispatchTableParts, setDispatchParts] = useState([])

    async function getDispatchParts(id: number) {
        try {
            const partsClient = new PartClient();
            const dispatchParts = await partsClient.getDispatchParts(id)
            const modifiedParts = dispatchParts.map(e => e.part)
            setDispatchParts(modifiedParts)
        } catch (error) {
            handleServerError(error)
        }
    }

    const [dispatchEquipment, setDispatchEquipment] = useState([]);

    const getDispatchEquipment = async () => {
        try {
            const dispatchEquipmentClient = new EquipmentClient();
            const equipment = await dispatchEquipmentClient.getDispatchEquipment(props.dispatchId);
            const modifiedEquipment = equipment.map(elem => elem.equipment)
            setDispatchEquipment(modifiedEquipment);
        } catch (error) {
            handleServerError(error)
        }
    }

    const [dispatchDocuments, setDispatchDocuments] = useState([]);

    //represents VM for both draft and non-draft documents
    interface IDispatchDocumentTempVM {
        dispatchId: number;
        id: string;
        name: string;
        type: string;
        relativePath: string;
        isConfidential: boolean;
        draft: boolean;
        modified: boolean;
        uid: string;
    }

    class DispatchDocumentTempVM implements IDispatchDocumentTempVM {
        dispatchId: number;
        id: string; //string so we can directly use this field to display draft when needed
        name: string;
        type: string;
        relativePath: string;
        isConfidential: boolean;
        draft: boolean;
        modified: boolean;
        uid: string;
        document?: DispatchDocumentAddVM;

        constructor(dispatchId, id, name, type, path, isConfidential, draft, modified, uid, document = null) {
            this.dispatchId = dispatchId;
            this.id = id;
            this.name = name;
            this.type = type;
            this.relativePath = path;
            this.isConfidential = isConfidential;
            this.draft = draft;
            this.modified = modified;
            this.uid = uid;
            this.document = document;
        }
    }

    const getDispatchDocuments = async (dispatchId: number) => {
        const documentClient = new DispatchDocumentsClient();
        const files = await documentClient.getDispatchDocuments(dispatchId);
        const modifiedFiles = files.map(x => {
            return new DispatchDocumentTempVM(
                x.dispatchId,
                x.documentFile.id,
                x.documentFile.name,
                x.documentFile.type.extension,
                x.documentFile.relativePath,
                x.documentFile.isConfidential,
                false,
                false,
                null
            );
        })

        setDispatchDocuments(modifiedFiles);
    }

    useEffect(() => {
        fetchDispatchData()
        getDispatchNotes()
        getServiceNotes()
        getInternalNotes()
        getDispatchParts(props.dispatchId)
        getDispatchEquipment()
        getDispatchDocuments(props.dispatchId)
    }, [props.dispatchId])

    function handleServerError(message) {
        if (message === undefined || message.response === undefined) {
            enqueueSnackbar("An error has occured", { variant: "error" });
            return;
        }

        for (let prop in message.response.data.errors) {
            let propErrors = message.response.data.errors[prop];

            propErrors.forEach(element => {
                enqueueSnackbar(element, { variant: "error" });
            });
        }
    }

    const fetchDispatchData = async () => {
        const dispatchClient = new DispatchClient();
        const dispatch = await dispatchClient.getById(props.dispatchId);
        setDispatch(dispatch);
    }

    const ListComponent: React.FC<{ backgroundColor: string, text?: string, textColor?: string }> = ({ backgroundColor, text, textColor }) => (
        <div className='priority_and_trades_form_add_data_container_pick_color_text'
            style={{
                backgroundColor,
                width: '100%',
                height: 38,
                color: textColor
            }}>{text}</div>
    )

    return (
        <Grid className='dispatch-crud-form' container>
            <Grid container xs={12} item className={`customer-location disabled`} spacing={1}>
                <Grid item xs={12} lg={2}>
                    <Typography variant="h2">View dispatch</Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField
                        label="Customer"
                        variant="outlined"
                        disabled={true}
                        value={dispatch ? dispatch.location.customer.company : ""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField
                        label="Location"
                        variant="outlined"
                        value={dispatch ? dispatch.location.name : ""}
                        disabled={true}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Button endIcon={<CancelOutlinedIcon />} color='primary' variant='outlined' disableElevation fullWidth onClick={() => props.clearFields()}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <Grid xs={12} spacing={1} item container className='line'>
            </Grid>
            <Grid container spacing={4} className={`${dispatch ? '' : 'form-disabled'}`}>
                <Grid item container xs={12} lg={4} alignContent='flex-start' className='dispatch-details'>
                    <Grid item xs={10} className='dispatch-details-title'>
                        <Typography variant="h3">{`Dispatch ${props.dispatchId ? `#${props.dispatchId}` : ''} details`}</Typography>
                    </Grid>
                    <Grid item justify='space-between' container xs={12} spacing={1} className='dispatch-details-inputs'>
                        <Grid item xs={6}>
                            Priority
                            <ListComponent backgroundColor={`#${dispatch ? dispatch.priority ? dispatch.priority.hexCode : null : null}`} />
                        </Grid>
                        <Grid item xs={6}>
                            Trade
                            <ListComponent backgroundColor={`#${dispatch ? dispatch.trade ? dispatch.trade.hexCode : null : null}`} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='wo_number' fullWidth label='WO#'
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                                value={dispatch ? dispatch.workOrderNumber ? dispatch.workOrderNumber : ' ' : ' '}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='technician'
                                label="Technician"
                                fullWidth
                                value={dispatch ? dispatch.tehnicianId ? props.technicians.find(technician => technician.id === dispatch.tehnicianId).userName : ' ' : ' '}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <span className='date-created'>Date created: {dispatch ? dispatch.created ? dateTimeFormat.format(dispatch.created) : dateTimeFormat.format(new Date()) : dateTimeFormat.format(new Date())}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="SLA Date"
                                fullWidth
                                value={dispatch ? dispatch.slaDate ? slaDateTimeFormat.format(dispatch.slaDate) : "MM/dd/yyyy" : "MM/dd/yyyy"}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Follow Up/Due Date"
                                fullWidth
                                value={dispatch ? dispatch.followUpDate ? dateTimeFormat.format(dispatch.followUpDate) : "MM/dd/yyyy" : "MM/dd/yyyy"}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Status"
                                fullWidth
                                value={dispatch ? dispatch.statusId ? dispatch.status.name : 'Unassigned' : 'Unassigned'}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Secondary Status"
                                fullWidth
                                value={dispatch ? dispatch.secondaryStatusId ? dispatch.secondaryStatus.name : 'Unassigned' : 'Unassigned'}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    {/* src = service request codes */}
                    <Grid item xs={12} >
                        <Typography variant="h3">Service Request Codes</Typography>
                        <Box>
                            {dispatch ? dispatch.dispatchServiceRequestCodes.map(src =>
                                <TextField
                                    value={`${src.serviceRequestCode.code} ${src.serviceRequestCode.description}`}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                />
                            ) : ''}
                        </Box>
                    </Grid>
                </Grid>

                {/* the state id checks should be included in one variable so it doesn't need to repeatedly check for the same thing -- performance thing */}
                <Grid item container xs={12} lg={8} alignContent='flex-start'>
                    {/* Dispatch tabs */}
                    <Grid item container xs={12} className={`tabs-container`} >
                        <Grid item xs={12} className={`form-tabs ${dispatch ? '' : 'disabled'}`}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Dispatch notes" disabled={dispatch ? false : true} />
                                <Tab label="Service notes" disabled={dispatch ? false : true} />
                                <Tab label="Internal notes" disabled={dispatch ? false : true} />
                                <Tab label="Equipment" disabled={dispatch ? false : true} />
                                <Tab label="Parts" disabled={dispatch ? false : true} />
                                <Tab label="Labor" disabled={dispatch ? false : true} />
                                <Tab label="Documents" disabled={dispatch ? false : true} />
                            </Tabs>
                        </Grid>
                        <Grid item className='form-tab-content'>
                            <TabPanel value={tabValue} index={0} >
                                <Box className={classes.boxScroll}>
                                    <DispatchNotesPreview notes={dispatchNotes} />
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Box className={classes.boxScroll}>
                                    <DispatchNotesPreview notes={serviceNotes} />
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <Box className={classes.boxScroll}>
                                    <DispatchNotesPreview notes={internalNotes} />
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <DispatchEquipmentPreview equipment={dispatchEquipment} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <DispatchPartsPreview dispatchTableParts={dispatchTableParts} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <DispatchLaborPreview dispatchId={props.dispatchId} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <DispatchDocumentsPreview documents={dispatchDocuments} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                    {/* Dispatch tabs end */}

                    {/* <Grid item xs={12} className={`update-dispatch ${state.id ? 'update-dispatch_visible' : ''}`}>
                        <Button type='submit' color='primary' variant='contained' size='large' onClick={editDispatchHandler} disableElevation fullWidth>UPDATE DISPATCH</Button>
                    </Grid> */}
                </Grid>
            </Grid>
            <Button color="primary" variant="contained" className="btn-edit-dispatch" onClick={() => { props.setDispatchForEditing(props.dispatchId) }}>EDIT DISPATCH</Button>
        </Grid>);
}

export default DispatchForm;
