import React, { useEffect, useState } from 'react';

import { createStyles, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { CustomerBaseVM, CustomerClient, TeamMemberCustomerAddVM, TeamMemberCustomersAddVM, TeamMemberCustomersDeleteVM, TeamsClient, TeamUpdateVM, TeamVM, } from '../../../brines-refrigerator-api';
import CustomersTransferList from './CustomersTransferList';
import { Color } from '@material-ui/lab/Alert';
import SnackbarNotification from '../../common/snackbar/SnackbarNotification';

interface TeamManagementProps {
    cancelTeamEdit: Function,
    team: TeamVM,
    newTeamName: string,
    triggerNameValidation: Function,
    refreshTable: Function
}

const CustomersManagment = (props: TeamManagementProps) => {

    useEffect(() => {
        if (props.team) {
            getCustomersAndMembers();
            setDisabled(false);
        } else {
            setDisabled(true);
            clearCustomersAndTeamCustomers();
        }
    }, [props.team]);

    const [availableCustomers, setAvailableCustomers] = useState([]);
    const [teamCustomers, setTeamCustomers] = useState([]);

    const getCustomersAndMembers = async () => {
        try {
            const teamsClient = new TeamsClient();
            const teamCustomers = (await teamsClient.getTeamMemberCustomers(props.team.id)).map(x => x.customer);
            setTeamCustomers(teamCustomers);

            const customersClient = new CustomerClient()
            const customers = (await customersClient.get()).map(x => new CustomerBaseVM({
                company: x.company,
                id: x.id,
                isArchived: x.isArchived
            }))

            const availableCustomers = customers.filter(x => {
                return teamCustomers.findIndex(y => y.id === x.id) === -1
            });

            setAvailableCustomers(availableCustomers);
        } catch (error) {
            setSnackBarState(true, 'Error while geting team customer members.', "error")
        }
    }

    const clearCustomersAndTeamCustomers = () => {
        setTeamCustomers([]);
        setAvailableCustomers([]);
    }

    const useStyles = makeStyles(() =>
        createStyles({
            titleRow: {
                height: "max-content",
                marginBottom: "2rem"
            },
        }),
    );

    const [disabled, setDisabled] = useState(true);

    const classes = useStyles();

    const [tempAddedCustomers, setTempAddedCustomers] = useState([]);
    const [tempRemovedCustomers, setTempRemovedCustomers] = useState([]);

    const validateUpdateTeam = () => {
        const nameError = props.triggerNameValidation(props.newTeamName)
        return !nameError;
    }

    const updateTeam = async () => {
        if (validateUpdateTeam()) {
            try {
                const teamsClient = new TeamsClient();
                let refreshTeam = false;

                //check if we need to update team name
                if (props.newTeamName !== props.team.name) {
                    await teamsClient.updateTeam(new TeamUpdateVM({
                        id: props.team.id,
                        name: props.newTeamName
                    }));
                    props.refreshTable();
                }

                if (tempAddedCustomers.length > 0) {
                    refreshTeam = true;
                    await teamsClient.addTeamMemberCustomers(new TeamMemberCustomersAddVM({
                        teamId: props.team.id,
                        teamMemberCustomers: tempAddedCustomers.map((x: CustomerBaseVM) => new TeamMemberCustomerAddVM({
                            customerId: x.id
                        }))
                    }));
                }

                if (tempRemovedCustomers.length > 0) {
                    refreshTeam = true;
                    await teamsClient.deleteTeamMemberCustomerByCustomerId(new TeamMemberCustomersDeleteVM({
                        teamId: props.team.id,
                        teamMemberCustomers: tempRemovedCustomers.map((x: CustomerBaseVM) => x.id)
                    }));
                }

                if (refreshTeam) {
                    getCustomersAndMembers();
                }
                setSnackBarState(true, 'Team customer members updated successfully.', "success")
            } catch (error) {
                setSnackBarState(true, 'Error while updating team customer members.', "error")
            }
        }
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState<Color>()

    const setSnackBarState = (state: boolean, message: string, severity: Color) => {
        setSnackbarOpen(state)
        setSnackbarMessage(message)
        setSeverity(severity)
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <Grid item container xs={12}>
            <SnackbarNotification
                open={snackbarOpen}
                snackbarMessage={snackbarMessage}
                handleClose={handleClose}
                severity={severity}
            />
            <Grid item container xs={12} className={classes.titleRow} alignItems="center" spacing={2}>
            </Grid>
            <Grid item container xs={12} alignItems="flex-start">
                <CustomersTransferList
                    disabled={disabled}
                    availableCustomers={availableCustomers}
                    teamCustomers={teamCustomers}
                    setTempAddedCustomers={setTempAddedCustomers}
                    setTempRemoveCustomers={setTempRemovedCustomers}
                />
            </Grid>
            <Button color='primary' variant='contained' size='large' fullWidth disableElevation onClick={updateTeam} disabled={disabled}>
                Update
            </Button>
        </Grid>
    )
}

export default CustomersManagment;