import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { UserBaseVM } from '../../../brines-refrigerator-api';
import { InputAdornment, TextField, Typography } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
        paper: {
            width: "100%",
            overflow: 'auto',
            maxHeight: "50rem"
        },
        buttonArrow: {
            margin: theme.spacing(0.5, 0),

        },
        peopleList: {
            width: "100%"
        },
        buttonContainer: {
            display: "flex"
        },
        h3: {
            marginBottom: "1.5rem"
        },
        searchField: {
            width: "100%",
            marginBottom: "1.5rem"
        }
    }),
);

function not(a: UserBaseVM[], b: UserBaseVM[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: UserBaseVM[], b: UserBaseVM[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

interface TeamsTransferListProps {
    availablePeople: UserBaseVM[],
    teamMembers: UserBaseVM[],
    disabled: boolean,
    setTempAddedMembers: Function,
    setTempRemovedMembers: Function
}

export default function TeamsTransferList(props: TeamsTransferListProps) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState<UserBaseVM[]>([]);
    const [left, setLeft] = React.useState<UserBaseVM[]>([]);
    const [right, setRight] = React.useState<UserBaseVM[]>([]);

    useEffect(() => {
        setLeft(props.availablePeople);
        setRight(props.teamMembers);
        props.setTempAddedMembers([]);
        props.setTempRemovedMembers([]);
        setLeftSearchString("");
        setRightSearchString("");
        setLeftSearchedItems(null);
        setRightSearchedItems(null);
    }, [props.availablePeople, props.teamMembers])

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: UserBaseVM) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const calculateDifferenceToInitialMemberState = (newTeamMembers) => {
        const initialMembers = props.teamMembers;

        //who isn't in team members who was in the initial members?
        const removedMembers = not(initialMembers, newTeamMembers);

        //who is in the new members who wasn't in the initial ones?
        const addedMembers = not(newTeamMembers, initialMembers);

        props.setTempRemovedMembers(removedMembers);
        props.setTempAddedMembers(addedMembers);
    }

    const handleCheckedRight = () => {
        const newRightItems = right.concat(leftChecked);
        setRight([...newRightItems]);

        const newLeftItems = not(left, leftChecked);
        setLeft(newLeftItems);

        setChecked(not(checked, leftChecked));
        if (leftSearchString) {
            const newItems = newLeftItems.filter(x => {
                const fullString = x.userName + x.firstName + x.lastName;
                return fullString.includes(leftSearchString);
            })
            setLeftSearchedItems([...newItems]);
        } else {
            setLeftSearchedItems(null);
        }
        if (rightSearchString) {
            const newItems = newRightItems.filter(x => {
                const fullString = `${x.firstName} ${x.lastName} ${x.userName}`.toLowerCase();
                return fullString.includes(rightSearchString.toLowerCase());
            })
            setRightSearchedItems([...newItems]);
        } else {
            setRightSearchedItems(null);
        }

        calculateDifferenceToInitialMemberState(newRightItems);
    };

    const handleCheckedLeft = () => {
        const newLeftItems = left.concat(rightChecked);
        setLeft([...newLeftItems]);

        const newRightItems = not(right, rightChecked);
        setRight(newRightItems);

        setChecked(not(checked, rightChecked));
        if (rightSearchString) {
            const newItems = newRightItems.filter(x => {
                const fullString = x.userName + x.firstName + x.lastName;
                return fullString.includes(rightSearchString);
            })
            setRightSearchedItems([...newItems]);
        } else {
            setRightSearchedItems(null);
        }
        if (leftSearchString) {
            const newItems = newLeftItems.filter(x => {
                const fullString = `${x.firstName} ${x.lastName} ${x.userName}`.toLowerCase();
                return fullString.includes(leftSearchString.toLowerCase());
            })
            setLeftSearchedItems([...newItems]);
        } else {
            setLeftSearchedItems(null);
        }

        calculateDifferenceToInitialMemberState(newRightItems);
    };

    const [leftSearchString, setLeftSearchString] = useState("");
    const [rightSearchString, setRightSearchString] = useState("");

    const [leftSearchedItems, setLeftSearchedItems] = useState(null);
    const [rightSearchedItems, setRightSearchedItems] = useState(null);

    const handleLeftSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
        const searchString = event.target.value as string;
        setLeftSearchString(searchString);

        const searchStringTrimmed = searchString.trim();
        if (searchStringTrimmed) {
            const newItems = left.filter(x => {
                const fullString = `${x.firstName} ${x.lastName} ${x.userName}`.toLowerCase();
                return fullString.includes(searchStringTrimmed.toLowerCase());
            })
            setLeftSearchedItems([...newItems]);
        } else {
            setLeftSearchedItems(null);
            setLeftSearchString("");
        }
    };

    const handleRightSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
        const searchString = event.target.value as string;
        setRightSearchString(searchString);

        const searchStringTrimmed = searchString.trim();
        if (searchStringTrimmed) {
            const newItems = right.filter(x => {
                const fullString = `${x.firstName} ${x.lastName} ${x.userName}`.toLowerCase();
                return fullString.includes(searchStringTrimmed.toLowerCase());
            })
            setRightSearchedItems([...newItems]);
        } else {
            setRightSearchedItems(null);
            setRightSearchString("");
        }
    };

    const customList = (items: UserBaseVM[]) => (
        <Paper className={classes.paper} elevation={2}>
            <List dense component="div" role="list">
                {items.map((value: UserBaseVM) => {
                    const labelId = `transfer-list-item-${value.id}-label`;
                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName} (${value.userName})`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Grid container spacing={2} className={classes.root} justify="space-between">
            <Grid item xs={5}>
                <Typography variant="h3" className={classes.h3}>Available people</Typography>
                <TextField
                    placeholder="Search/Filter"
                    id="standard-start-adornment"
                    className={classes.searchField}
                    disabled={props.disabled}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                    onChange={handleLeftSearch}
                    value={leftSearchString}
                />
                {customList(leftSearchedItems ? leftSearchedItems : left)}
            </Grid>
            <Grid item xs={2} className={classes.buttonContainer} alignItems="center">
                <Grid item container direction="column">
                    <Button
                        variant="contained"
                        size="small"
                        className={classes.buttonArrow}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                        color="primary"
                    >
                        SELECT{<ArrowRightIcon />}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className={classes.buttonArrow}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                        color="primary"
                    >
                        {<ArrowLeftIcon />}REMOVE
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Typography variant="h3" className={classes.h3}>Team members</Typography>
                <TextField
                    placeholder="Search/Filter"
                    id="standard-start-adornment"
                    className={classes.searchField}
                    disabled={props.disabled}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                    onChange={handleRightSearch}
                    value={rightSearchString}
                />
                {customList(rightSearchedItems ? rightSearchedItems : right)}
            </Grid>
        </Grid>
    );
}