import React from 'react';
import MaterialTable, { Action, Components, Options } from 'material-table';
import tableIcons from './tableIcons';

interface TableProps {
    width?: string,
    columns: { title: string; field: string; }[],
    title?: string,
    search?: boolean,
    data: [],
    actions?: (Action<object> | ((rowData: object) => Action<object>))[],
    selection?: boolean
    components?: Components
    onSelectionChange?: Function
    paging: boolean,
    isLoading: boolean,
    sorting?: boolean,
    pageSize?: number,
    fontSize?: number,
}

export default function BasicTable(props: TableProps) {
    return (
        <div style={{ width: props.width }}>
            <MaterialTable
                icons={tableIcons}
                title={props.title}
                columns={props.columns}
                data={props.data}
                actions={props.actions}
                options={{
                    actionsColumnIndex: -1,
                    search: props.search,
                    selection: props.selection,
                    paging: props.paging,
                    sorting: props.sorting,
                    pageSize: props.pageSize ? props.pageSize : 5,
                    rowStyle: {
                        fontSize: props.fontSize ? props.fontSize : 16
                    }
                }}
                components={props.components}
                onSelectionChange={(rows) => props.onSelectionChange(rows)}
                isLoading={props.isLoading}
            />
        </div>
    );
}
