import React, { } from 'react';
import { Paper, Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import './DispatchParts.scss'


const DispatchPartsPreview = (props) => {
    const dispatchPartsColumns = [
        { title: '#', field: 'id' },
        { title: 'Name', field: 'name' },
    ];

    return (
        <div className='dispatch-parts_container '>
            <Box>
                <MaterialTable
                    columns={dispatchPartsColumns}
                    title=" "
                    data={props.dispatchTableParts as []}
                    components={{
                        Container: props => <Paper {...props} elevation={0} />,
                    }}
                    options={{
                        search: false,
                        paging: false,
                        pageSize: props.pageSize ? props.pageSize : 5,
                        rowStyle: {
                            fontSize: props.fontSize ? props.fontSize : 16
                        }
                    }}
                />
            </Box>
        </div>
    )
};
export default DispatchPartsPreview;