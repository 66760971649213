import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import BasicTable from "../../components/common/table/BasicTable";
import { UserActionLogClient } from "../../brines-refrigerator-api";
import { redirectIfSessionExpired } from '../../components/common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

export default function FollowUpView() {
    const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    const history = useHistory();

    const [tableIsLoading, setTableLoading] = useState(true);

    const columns = [
        { title: 'User', field: 'user' },
        { title: 'Date and Time', field: 'timestamp' },
        { title: 'Action', field: 'action' },
        { title: 'Status', field: 'status', width: 15 },
    ];

    const [logs, setLogs] = useState([]);

    const getUserActionLogs = async () => {
        try {
            const userActionLogsClient = new UserActionLogClient();
            const userActionLogs = await userActionLogsClient.get();
            const mappedLogs = userActionLogs.map(log => ({
                user: log.createdBy.userName === '' ? log.createdBy.email : log.createdBy.userName,
                timestamp: dateTimeFormat.format(log.created),
                action: log.action,
                status: log.status
                    ? <Chip color="primary" variant="outlined" size="small" label="SUCCESS" />
                    : <Chip color="secondary" variant="outlined" size="small" label="FAILURE&nbsp;" />
            }))
            setLogs(mappedLogs)
            setTableLoading(false)
        } catch (error) {
            setTableLoading(false)
            redirectIfSessionExpired(history, error)
        }
    }

    useEffect(() => {
        getUserActionLogs();
    }, []);

    return (
        <BasicTable
            columns={columns}
            title="USER ACTIONS LOG"
            data={logs as []}
            actions={[]}
            components={{
                Container: props => <Paper {...props} elevation={0} />,
            }}
            paging={true}
            isLoading={tableIsLoading}
        />
    );
}