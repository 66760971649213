import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './global-state/store';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#000000"
    },
    h2: {
      fontSize: "1.3125rem",
      fontWeight: "bold",
      color: "#000000"
    },
    h3: {
      fontSize: "1.3125rem",
      fontWeight: 300,
      color: "#3f51b5"
    },
    h6: {
      fontSize: "1.3125rem",
      fontWeight: "bold",
      color: "#000000"
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={10}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
