import React, { useEffect, useReducer, useState } from 'react';
import { PartClient, PartVM, ManufacturerClient, ManufacturerVM, PartAddVM } from '../../../brines-refrigerator-api';
import PartDetails from '../../admin/PartsList/PartDetails';
import { Grid, Paper } from '@material-ui/core';
import BasicTable from '../../common/table/BasicTable';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ConfirmDialog from '../../common/dialog/ConfirmationDialog';
import { Color } from '@material-ui/lab/Alert';
import SnackbarNotification from '../../common/snackbar/SnackbarNotification';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';

interface StateType {
    part: PartVM,
}

export default function PartsListView() {
    const history = useHistory();
    const [formTitle, setFormTitle] = useState('Add Part');
    const [formButtonTitle, setFormButtonTitle] = useState('Add Part');

    //Set form to be empty
    const [state, setState] = useReducer(
        (state: StateType, newState: StateType) => ({ ...state, ...newState }),
        {
            part: new PartVM({
                id: 0,
                number: 0,
                name: '',
                source: '',
                manufacturer: new ManufacturerVM({ id: 0, name: "" }),
                description: '',
                price: 0
            }),
        },
    );

    const [manufacturers, setManufacturers] = useState([]);
    const [parts, setParts] = useState([]);

    async function populateTable() {
        try {
            const partsClient = new PartClient();
            const parts = await partsClient.get();
            setParts(parts)
        } catch (error) {
            redirectIfSessionExpired(history, error)
            setSnackBarState(true, 'Error while getting parts', 'error')
        }
    };

    useEffect(() => {
        populateTable()
    }, [state.part]);

    //Edit part form
    const editPart = async (e: React.FormEvent<HTMLFormElement>, id: number, manufacturer: ManufacturerVM) => {
        e.preventDefault();

        const form = e.currentTarget;
        const part = new PartVM({
            id: id,
            number: Number(form.partNumber.value),
            name: form.partName.value,
            source: form.source.value,
            manufacturer: manufacturer,
            description: form.description.value,
            price: Number(form.price.value)
        });
        try {
            const partClient = new PartClient();
            await partClient.put(part);

            setSnackBarState(true, 'Part edited successfully.', "success")

            clearFields()
            setFormTitle('Add Part');
            setFormButtonTitle('ADD Part');
            setFormAction(() => addPart)
        } catch (error) {
            redirectIfSessionExpired(history, error)
            setSnackBarState(true, 'Error while editing parts', "error")
        }
    }

    const addPart = async (e: React.FormEvent<HTMLFormElement>, id: number, manufacturer: ManufacturerVM) => {
        e.preventDefault();
        setFormTitle('Add Part');
        setFormButtonTitle('ADD Part');

        const form = e.currentTarget;
        const part = new PartAddVM({
            number: Number(form.partNumber.value),
            name: form.partName.value,
            source: form.source.value,
            manufacturerId: manufacturer.id,
            description: form.description.value,
            price: Number(form.price.value)
        });
        try {
            const partClient = new PartClient();
            await partClient.post(part);

            setSnackBarState(true, 'Part added successfully.', "success")

            clearFields()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            setSnackBarState(true, 'Error while adding part.', "error")

        }
    }

    async function getManufacturers() {
        try {
            const manufacturerClient = new ManufacturerClient();
            const manufacturers = await manufacturerClient.get();
            setManufacturers(manufacturers)
        } catch (error) {
            redirectIfSessionExpired(history, error)
            setSnackBarState(true, 'Error while getting manufacturers', "error")
        }
    };

    useEffect(() => {
        getManufacturers();
    }, []);

    function clearFields() {
        setState({
            ...state,
            part: new PartVM({
                id: 0,
                number: 0,
                name: '',
                source: '',
                manufacturer: new ManufacturerVM({ id: 0, name: "" }),
                description: '',
                price: 0
            })
        })
        setFormTitle('Add Part');
        setFormButtonTitle('Add Part');
        setFormAction(() => addPart)
    }

    async function getPartForEditing(id: number) {
        setFormTitle('Edit Part');
        setFormButtonTitle('Update Part');
        setFormAction(() => editPart);

        try {
            const partClient = new PartClient();
            const part = await partClient.getById(id)
            setState({ ...state, part })
        } catch (error) {
            redirectIfSessionExpired(history, error)
            setSnackBarState(true, 'Error while edditing parts', "error")
        }
    }

    const [formAction, setFormAction] = useState(() => addPart);

    const partsListColums = [
        { title: 'Number', field: 'number' },
        { title: 'Name', field: 'name' },
        { title: 'Manufacturer', field: 'manufacturer.name' },
        { title: 'Source', field: 'source' },
        { title: 'Description', field: 'description' },
        { title: 'Price', field: 'price' }
    ];

    const [idForDelete, setIdForDelete] = useState(0);
    const [dialogMessage, setDialogMessage] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);

    const openDeleteDialogue = (id: number) => {
        setIdForDelete(id);
        setDialogMessage(`Are you sure you want to delete Part #${id}?`);
        setConfirmOpen(true);
    }

    async function deletePart(selectedPartId: number) {
        const partClient = new PartClient();
        try {
            await partClient.delete(selectedPartId);

            setSnackBarState(true, 'Part deleted successfully.', "success")

            populateTable();
        } catch (error) {
            redirectIfSessionExpired(history, error)
            setSnackBarState(true, 'Error while deleting part.', "error")
        }
    }

    const tableActions = [
        rowData => ({
            icon: () => <Edit color='primary' />,
            onClick: (event, rowData: unknown) => { getPartForEditing((rowData as { id: number }).id) },
            tooltip: "Edit Part"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { openDeleteDialogue((rowData as { id: number }).id) },
            tooltip: "Delete Part"
        })
    ]

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState<Color>()

    const setSnackBarState = (state: boolean, message: string, severity: Color) => {
        setSnackbarOpen(state)
        setSnackbarMessage(message)
        setSeverity(severity)
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <section>
            <SnackbarNotification
                open={snackbarOpen}
                snackbarMessage={snackbarMessage}
                handleClose={handleClose}
                severity={severity}
            />
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <BasicTable
                        columns={partsListColums}
                        title={'Parts List'}
                        actions={tableActions}
                        data={parts as []}
                        components={{
                            Container: props => <Paper {...props} elevation={0} />,
                        }}
                        paging={true}
                        isLoading={false}
                    />
                    {confirmOpen && <ConfirmDialog
                        title={"Delete dispatch?"}
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={() => { deletePart(idForDelete) }}
                    >
                        {dialogMessage}
                    </ConfirmDialog>
                    }
                </Grid>
                <Grid item xs={4}>
                    <PartDetails
                        clearFields={clearFields}
                        part={state.part}
                        formAction={formAction}
                        manufacturers={manufacturers}
                        formTitle={formTitle}
                        formButtonTitle={formButtonTitle}
                    />
                </Grid>
            </Grid>
        </section>
    );
}
