import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    summaryContent: {
      width: "100vw"
    },
    loaderRoot: {
      flexDirection: "column",
      alignContent: "center",
      marginTop: "30%"
    },
    heading: {
      marginLeft: "2em",
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    alignText: {
      marginTop: "0.6em"
    },
    alignButtonRight: {
      display: "flex",
      justifyContent: "flex-end"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    clockOutBtn: {
      margin: "0.6em"
    },
    partsHeader: {
      marginBottom: "0.5em"
    },
    takePhotoButton: {
      display: 'flex',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      bottom: '4rem'
    },
    videoSection: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }),
);
export { useStyles }