import React, { forwardRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Grid, Button, Tooltip } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import { Icons } from 'material-table';
import { SvgIconProps } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';

interface TableProps {
    width: string,
    columns: { title: string; field: string; }[],
    title: string,
    add: Function,
    update: Function,
    delete: Function,
    data: [],
    search: boolean,
    isLoading?: boolean
}

export default function AddEditTable(props: TableProps) {
    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} htmlColor="#3f51b5" />),
        Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} htmlColor="#3f51b5" />),
        Edit: forwardRef((props: SvgIconProps, ref) => <Edit {...props} ref={ref} htmlColor="#3f51b5" />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    }

    return (
        <div style={{ width: props.width, border: '1px' }}>
            <MaterialTable
                icons={tableIcons}
                title={props.title}
                columns={props.columns}
                data={props.data}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise(async (resolve, reject) => {
                            const requestResultSuccess = await props.add(newData)
                            requestResultSuccess ? resolve(null) : reject()
                        }),
                    onRowUpdate: (newData) =>
                        new Promise((resolve) => {
                            props.update(newData)
                            resolve(null);
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            props.delete((oldData as { id: number }).id)
                            resolve(null);
                        })
                }}
                options={{
                    actionsColumnIndex: -1,
                    search: props.search,
                    paging: false
                }}
                // components={{
                //     Toolbar: propss => (
                //         <Grid container item xs={12} justify="flex-start" alignItems="center">
                //             <Grid item xs={10}>
                //                 <MTableToolbar {...propss} />
                //             </Grid>
                //             <Grid item container xs={2} alignContent="flex-end" alignItems="flex-end" justify="space-around">
                //                 <Tooltip title="Add Part from database">
                //                     <Button onClick={() => { props.add() }} variant="contained" color="primary">
                //                         Add Part From Database +
                //                     </Button>
                //                 </Tooltip>
                //             </Grid>
                //         </Grid>
                //     ),
                // }}
                isLoading={props.isLoading}
            />
        </div>
    );
}
