import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { ClockInQuestionsClient, ClockOutQuestionsClient, DispatchClient, UserVM, VisitsClient } from '../../../brines-refrigerator-api';
import CancelButton from '../../common/dialog/CancelButton';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';

interface VisitsProps {
  setModalState: Function,
  dispatchId: number,
  technicians: UserVM[],
}

const useStyles = makeStyles({
  signatureCell: {
    alignSelf: 'center'
  },
});

const Visits = (props: VisitsProps) => {
  const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const classes = useStyles();

  const setModalState = () => {
    props.setModalState(false);
  }

  const [technicians, setTechnicians] = useState({});
  const [visits, setVisits] = useState([]);
  const [questions, setQuestions] = useState({});
  const [signatures, setSignatures] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const clockInQClient = new ClockInQuestionsClient();
  const clockOutQClient = new ClockOutQuestionsClient();
  const dispatchClient = new DispatchClient();
  const visitsClient = new VisitsClient();

  const getQuestions = async () => {
    const clockInQuestions = await clockInQClient.get();
    const clockOutQuestions = await clockOutQClient.get();
    setQuestions([clockInQuestions[0].text, clockInQuestions[1].text, clockOutQuestions[0].text, clockOutQuestions[1].text])
  }

  const formatVisits = async () => {
    const visits = await visitsClient.getForDisplay(props.dispatchId)
    setVisits(visits.map(v => ({
      tech: technicians[v.techId],
      visitTime: formatTime(v.visitTime),
      status: v.finished ? formatIndicator('Finished') : v.clockIn.arrivedOnSite ? formatIndicator('Arrived') : formatIndicator('Traveling'),
      startedTravelingTime: dateTimeFormat.format(v.clockIn.startedTravelingTime),
      clockInTime: v.clockIn.arrivedOnSite ? dateTimeFormat.format(v.clockIn.clockInTime) : formatIndicator('Pending'),
      clockOutTime: v.clockOut ? dateTimeFormat.format(v.clockOut.clockOutTime) : formatIndicator('Pending'),
      answers: <>
        <Grid item container sm={12}>
          <Grid item sm={5}>
            Q1: {v.clockIn.arrivedOnSite ? formatAnswer(v.clockIn.clockInAnswers[0].answer) : formatIndicator('Pending')}
          </Grid>
          <Grid item sm={5}>
            Q2: {v.clockIn.arrivedOnSite ? formatAnswer(v.clockIn.clockInAnswers[1].answer) : formatIndicator('Pending')}
          </Grid>
        </Grid>
        <Grid item container sm={12}>
          <Grid item sm={5}>
            Q3: {v.clockOut ? formatAnswer(v.clockOut.clockOutAnswers[0].answer) : formatIndicator('Pending')}
          </Grid>
          <Grid item sm={5}>
            Q4: {v.clockOut ? formatAnswer(v.clockOut.clockOutAnswers[1].answer) : formatIndicator('Pending')}
          </Grid>
        </Grid>
      </>,
      signatures: signatures[v.clockIn.clockInTime.toLocaleDateString()]
        ? <Grid container sm={9}>
          {signatures[v.clockIn.clockInTime.toLocaleDateString()].map(s => isTimeInRange(
            s.created,
            v.clockIn.clockInTime,
            v.clockOut ? v.clockOut.clockOutTime : null
          )
            ? s.preview
            : null)}
        </Grid>
        : formatIndicator('Pending')
    })))
    setIsLoading(false)
  }

  const formatTime = (time: number) => {
    return <span><b>{Math.floor(time / 60)}</b>hrs <b>{time % 60}</b>mins</span>
  }

  const formatAnswer = (answer: boolean) => {
    return answer
      ? <Chip color="primary" variant="outlined" size="small" label="Yes" />
      : <Chip color="secondary" variant="outlined" size="small" label="No" />
  }

  const formatIndicator = (text: string) => {
    return <Chip variant="outlined" size="small" label={text} />
  }

  const formatTechnicians = () => {
    let technicians = {};
    props.technicians.forEach(tech => {
      technicians[tech.id] = tech.userName ?? tech.email
    });
    setTechnicians(technicians)
  }

  const isTimeInRange = (time: Date, startTime: Date, endTime: Date | null) => {
    const testHour = time.getHours();
    const testMinute = time.getMinutes();
    const testSecond = time.getSeconds();

    const startHour = startTime.getHours();
    const startMinute = startTime.getMinutes();
    const startSecond = startTime.getSeconds();

    const isEndTimeNull = endTime === null

    const endHour = isEndTimeNull ? testHour : endTime.getHours();
    const endMinute = isEndTimeNull ? testMinute : endTime.getMinutes();
    const endSecond = isEndTimeNull ? testSecond : endTime.getSeconds();

    //Create date object and set the time to that
    let startTimeObject = new Date();
    startTimeObject.setHours(startHour, startMinute, startSecond);

    //Create date object and set the time to that
    let endTimeObject = new Date(startTimeObject);
    endTimeObject.setHours(endHour, endMinute, endSecond);

    //Create date object and set the time to that
    let timeObject = new Date(startTimeObject);
    timeObject.setHours(testHour, testMinute, testSecond);

    return (timeObject > startTimeObject && (isEndTimeNull ? true : timeObject < endTimeObject))
  }

  const viewSignature = async (fileId: number) => {
    const file = await dispatchClient.preview(fileId);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(file.data);
    });
  }

  const formatSignatures = async () => {
    const dispatch = await dispatchClient.getById(props.dispatchId)
    let signatures = {};
    for (const signature of dispatch.signatures) {
      if (!signatures[signature.created.toLocaleDateString()]) {
        signatures[signature.created.toLocaleDateString()] = []
      }
      signatures[signature.created.toLocaleDateString()].push({
        created: signature.created,
        preview: <Grid className={classes.signatureCell} item sm={6}>
          {signature.text ?? <img src={await viewSignature(signature.documentFileId) as string} style={{ height: "5em", maxWidth: "20em" }}></img>}
        </Grid>
      })
    }
    setSignatures(signatures)
  }

  useEffect(() => {
    getQuestions()
    formatTechnicians()
    formatSignatures()
  }, [props.dispatchId])

  useEffect(() => {
    formatVisits()
  }, [technicians, signatures])

  const visitsColumns = [
    { title: 'Technician', field: 'tech', sorting: false },
    { title: 'Visit Time', field: 'visitTime', sorting: false },
    { title: 'Status', field: 'status', width: 15, sorting: false },
    { title: 'Started Traveling Time', field: 'startedTravelingTime', sorting: false },
    { title: 'ClockIn Time', field: 'clockInTime', sorting: false },
    { title: 'ClockOut Time', field: 'clockOutTime', sorting: false },
    { title: 'Answers', field: 'answers', sorting: false },
    { title: 'Signatures', field: 'signatures', sorting: false }
  ];

  return (
    <Grid container sm={12}>
      <Grid item sm={11}>
        <Typography variant="h3">Visits</Typography>
      </Grid>
      <Grid item sm={1} className='cancel_btn'>
        <CancelButton
          size="small"
          onClick={setModalState}
        />
      </Grid>
      <Grid item container sm={7} className='clockIn_questions'>
        <Grid item sm={6}><Typography><b>Q1</b> - {questions[0]}</Typography></Grid>
        <Grid item sm={6}><Typography><b>Q2</b> - {questions[1]}</Typography></Grid>
      </Grid>
      <Grid item container sm={7} className='clockOut_questions'>
        <Grid item sm={6}><Typography><b>Q3</b> - {questions[2]}</Typography></Grid>
        <Grid item sm={6}><Typography><b>Q4</b> - {questions[3]}</Typography></Grid>
      </Grid>
      <Grid item sm={12}>
        <MaterialTable
          columns={visitsColumns}
          title={""}
          data={visits as []}
          options={{
            search: false,
            paging: false
          }}
          components={{
            Toolbar: () => <></>,
          }}
          isLoading={isLoading}
        />
      </Grid>
    </Grid >
  );
}
export default Visits;