import { DispatchClient, EquipmentClient, PartClient, DispatchStatusClient, IDispatchOfflineTechDataVM, DispatchOfflineTechDataVM, EquipmentVM, PartVM, FileResponse, IEquipmentVM, IPartVM, IDispatchStatusVM, DispatchStatusVM, DispatchVM, DispatchNotesClient, ServiceNotesClient, DispatchDocumentsClient, DispatchItemsClient, LocationClient, DispatchNoteCreateVM, DispatchNoteVM, UserBaseVM, ServiceNoteCreateVM, ServiceNoteVM, DispatchPartsUpdateVM, DispatchPartVM, DispatchItemCreateVM, DispatchItemVM, DispatchEquipmentUpdateVM, DispatchEquipmentVM, EquipmentBaseVM, DispatchDocumentVM, DocumentFileVM, DispatchUpdateVM, LocationNoteCreateVM, LocationNoteVM, EquipmentNoteCreateVM, EquipmentNoteVM, SignatureVM, ClockOutVM, VisitVM, VisitsClient, VisitStartTravelVM, ClockInVM, ClockInAnswerCreateVM, ClockOutAnswerCreateVM, VisitClockInVM, ClockInQuestionsClient, IClockOutQuestionVM, IClockInQuestionVM, ClockInQuestionVM, ClockOutQuestionVM, ClockOutQuestionsClient, AuthClient, ClockOutCreateVM, LogInVM, UserVM, RoleClient, RoleVM } from '../../brines-refrigerator-api';
import { useSnackbar } from 'notistack';
import Dexie from 'dexie';
import TechAction from '../constants/techActions';
import CustomDispatchDocumentsClient, { DispatchDocumentAddVM } from '../../pages/Dispatch/DispatchDocuments/DispatchDocumentsClient';
import { generateNumberUID } from '../generateUID';
import { UploadFileClient, SignatureAddVM } from '../../brines-refrigerator-api-extended';
import { useDispatch } from 'react-redux';
import { setOnlineState } from '../../global-state/actions/onlineStateActions';
import UserRole from '../constants/userRole';

export class TechOfflineDatabase extends Dexie {
    // Declare implicit table properties.
    // (just to inform Typescript. Instanciated by Dexie in stores() method)
    // todo: maybe get one endpoint for caching all the stuff?
    dispatches: Dexie.Table<IDispatchOfflineTechDataVM, number>; // number = type of the primkey
    equipment: Dexie.Table<IEquipmentVM, number>;
    parts: Dexie.Table<IPartVM, number>;
    statuses: Dexie.Table<IDispatchStatusVM, number>;
    documents: Dexie.Table<ITableDocumentFileVM, number>;
    signatures: Dexie.Table<ITableDocumentFileVM, number>;
    actions: Dexie.Table<ITableTechActionVM, number>;
    clockInQuestions: Dexie.Table<IClockInQuestionVM, number>;
    clockOutQuestions: Dexie.Table<IClockOutQuestionVM, number>;
    //...other tables go here...

    constructor() {
        super("TechOfflineDatabase");
        this.version(1).stores({
            //declare properties for indexing here, consult dexie reference for detailed spec
            dispatches: '&id',
            equipment: '&id',
            parts: '&id',
            statuses: '&id',
            documents: '&id',
            signatures: '&id',
            actions: '&uid, actionType',
            clockInQuestions: '&id',
            clockOutQuestions: '&id'
            //...other tables go here...
        });
        // The following line is needed if your typescript
        // is compiled using babel instead of tsc:
        this.dispatches = this.table("dispatches");
        this.equipment = this.table("equipment");
        this.parts = this.table("parts");
        this.statuses = this.table("statuses");
        this.documents = this.table("documents");
        this.signatures = this.table("signatures");
        this.actions = this.table("actions");
        this.clockInQuestions = this.table("clockInQuestions");
        this.clockOutQuestions = this.table("clockOutQuestions");

        //for entities which are bound to axios VMs
        this.dispatches.mapToClass(DispatchOfflineTechDataVM);
        this.equipment.mapToClass(EquipmentVM);
        this.parts.mapToClass(PartVM);
        this.statuses.mapToClass(DispatchStatusVM);
        this.clockInQuestions.mapToClass(ClockInQuestionVM);
        this.clockOutQuestions.mapToClass(ClockOutQuestionVM);
    }
}

interface ITableDocumentFileVM {
    id: number,
    file: FileResponse
}

class TableDocumentFileVM implements ITableDocumentFileVM {
    id: number;
    file: FileResponse;

    constructor(data?: ITableDocumentFileVM) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.file = _data["file"];
        }
    }

    static fromJS(data: any): TableTechActionVM {
        data = typeof data === 'object' ? data : {};
        let result = new TableTechActionVM();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["file"] = this.file;
        return data;
    }
}

export interface ITableTechActionVM {
    actionType: TechAction,
    payload: unknown,
    //so that we can monitor the exact time at which the tech did something while he was offline
    timestamp: Date,
    uid: number
}

class TableTechActionVM implements ITableTechActionVM {
    id?: number;
    actionType: TechAction;
    payload: unknown;
    timestamp: Date;
    uid: number;

    constructor(data?: ITableTechActionVM) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.actionType = _data["actionType"];
            this.payload = _data["payload"];
            this.timestamp = _data["timestamp"]
            this.uid = _data["uid"];
        }
    }

    static fromJS(data: any): TableTechActionVM {
        data = typeof data === 'object' ? data : {};
        let result = new TableTechActionVM();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["actionType"] = this.actionType;
        data["payload"] = this.payload;
        data["timestamp"] = this.timestamp;
        data["uid"] = this.uid;
        return data;
    }
}

const isOffline = (errorMessage: string) => {
    return errorMessage.includes("Network Error") || !navigator.onLine
}

export const useIfOfflineDispatchState = (): [Function] => {
    const dispatch = useDispatch()
    const ifOfflineDispatchState = (error: any) => {
        if (isOffline(error.message as string)) {
            dispatch(setOnlineState(false))
        }
    }
    return [ifOfflineDispatchState]
}

/**ping server to check internet access */
export const ping = async () => {
    const authClient = new AuthClient();
    try {
        await authClient.ping()
        return true;
    } catch (error) {
        if (isOffline(error.message as string)) {
            return false;
        } else {
            return true;
        }
    }
}

const getCachedDispatch = async (id: number) => {
    const offlineDb = new TechOfflineDatabase();
    return await offlineDb.dispatches.get(id);
}

const getUser = () => {
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    return new UserBaseVM({
        email: userData.user.email,
        firstName: userData.user.firstName,
        id: userData.user.id,
        isActive: userData.user.isActive,
        lastName: userData.user.lastName,
        userName: userData.user.userName,
    })
}

export const useCachingForOffline = (): [Function] => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const dispatchClient = new DispatchClient();
    const statusesClient = new DispatchStatusClient();
    const equipmentClient = new EquipmentClient();
    const partsClient = new PartClient();
    const clockInQuestionsClient = new ClockInQuestionsClient();
    const clockOutQuestionsClient = new ClockOutQuestionsClient();

    const getDataForOffline = async () => {
        let notificationMessage = "Dispatch, equipment and part data cached for offline use.";
        try {
            // data for every dispatch assigned to a tech
            const dispatches = await dispatchClient.getTechnicianDataForOffline();
            const documents = [] as ITableDocumentFileVM[];
            const signatures = [] as ITableDocumentFileVM[];

            // we only have file Ids at this point,
            // so we need to get all the fileStreams
            // and store it for previews and downloads
            for (const dispatch of dispatches) {
                for (const document of dispatch.dispatchDocuments) {
                    const file = await dispatchClient.preview(document.documentFile.id);
                    documents.push({ id: document.documentFile.id, file: file });
                }
                for (const signature of dispatch.signatures) {
                    if (signature.text === null) {
                        const file = await dispatchClient.preview(signature.documentFileId);
                        signatures.push({ id: signature.documentFileId, file: file });
                    }
                }
            }

            // statuses list for changing status of the dispatch
            const statuses = await statusesClient.getAll();

            // equipment list for adding new equipment
            const equipment = await equipmentClient.getForOffline();

            // parts list for adding new parts
            const parts = await partsClient.get();

            const clockInQuestions = await clockInQuestionsClient.get();
            const clockOutQuestions = await clockOutQuestionsClient.get();

            // save data into IndexedDB
            const offlineDb = new TechOfflineDatabase();
            await offlineDb.dispatches.clear()
            offlineDb.dispatches.bulkPut(dispatches);
            await offlineDb.equipment.clear()
            offlineDb.equipment.bulkPut(equipment);
            await offlineDb.parts.clear()
            offlineDb.parts.bulkPut(parts);
            await offlineDb.statuses.clear()
            offlineDb.statuses.bulkPut(statuses);
            await offlineDb.documents.clear()
            offlineDb.documents.bulkPut(documents);
            await offlineDb.signatures.clear()
            offlineDb.signatures.bulkPut(signatures);

            //todo: be careful about bulk putting, if for example a clock in question changes it's id and it was cached
            //bulk putting will retain both the old and new questions 
            offlineDb.clockInQuestions.bulkPut(clockInQuestions);
            offlineDb.clockOutQuestions.bulkPut(clockOutQuestions);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                notificationMessage = "You are offline and may not be viewing the latest data.";
            } else {
                notificationMessage = error.message;
            }
        }


        // using notistack because it is wrapped around the whole app
        // and we are not awaiting this caching call (as we agreed)
        enqueueSnackbar(notificationMessage, { variant: "info" });
    }
    return [getDataForOffline]
}

//#region AUTH
export const logIn = async (loginVm: LogInVM) => {
    const client = new AuthClient();
    try {
        return await client.logIn(loginVm);
    } catch (error) {
        if (isOffline(error.message as string)) {
            return new UserVM({
                email: loginVm.email,
                firstName: null,
                id: null,
                isActive: true,
                lastName: null,
                userName: loginVm.email
            });
        } else {
            throw error;
        }
    }
}

export const getUserRole = async (id: number) => {
    const client = new RoleClient();
    try {
        return await client.getUserRole(id);
    } catch (error) {
        if (isOffline(error.message as string)) {
            return new RoleVM({
                id: null,
                name: UserRole.Technician
            });
        } else {
            throw error;
        }
    }
}

export const logOut = async (connectionId: string) => {
    const authClient = new AuthClient();
    try {
        return await authClient.logOut(connectionId);
    } catch (error) {
        if (isOffline(error.message as string)) {
            return Promise.resolve<void>(<any>null);
        } else {
            throw error;
        }
    }
}
//#endregion AUTH

//#region TECH VIEW
export const useGetDispatches = (): [Function] => {
    const dispatch = useDispatch()
    const getDispatches = async () => {
        const dispatchClient = new DispatchClient();
        try {
            return await dispatchClient.getForTechnician();
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                const cachedDispatches = await offlineDb.dispatches.toArray();
                return cachedDispatches.map(cd => new DispatchVM({
                    id: cd.id,
                    priority: cd.priority,
                    workOrderNumber: cd.workOrderNumber,
                    location: cd.location
                }));
            } else {
                throw error;
            }
        }
    }
    return [getDispatches]
}
//#endregion TECH VIEW

//#region TECH DISPATCH VIEW
export const useGetDispatch = (): [Function] => {
    const dispatch = useDispatch()
    const getDispatch = async (id: number) => {
        const dispatchClient = new DispatchClient();
        try {
            return await dispatchClient.getById(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(id);
                return new DispatchVM({ ...cachedDispatch });
            } else {
                throw error;
            }
        }
    }
    return [getDispatch]
}

export const useGetDispatchNotes = (): [Function] => {
    const dispatch = useDispatch()
    const getDispatchNotes = async (id: number, userInfo: boolean) => {
        const dispatchNotesClient = new DispatchNotesClient();
        try {
            return await dispatchNotesClient.get(id, userInfo);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(id);
                return cachedDispatch.dispatchNotes;
            } else {
                throw error;
            }
        }
    }
    return [getDispatchNotes]
}

export const useGetServiceNotes = (): [Function] => {
    const dispatch = useDispatch()
    const getServiceNotes = async (id: number, userInfo: boolean) => {
        const serviceNotesClient = new ServiceNotesClient();
        try {
            return await serviceNotesClient.get(id, userInfo);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(id);
                return cachedDispatch.serviceNotes;
            } else {
                throw error;
            }
        }
    }
    return [getServiceNotes]
}

export const useGetDispatchDocuments = (): [Function] => {
    const dispatch = useDispatch()
    const getDispatchDocuments = async (id: number) => {
        const dispatchDocumentClient = new DispatchDocumentsClient();
        try {
            return await dispatchDocumentClient.getDispatchDocuments(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(id);
                return cachedDispatch.dispatchDocuments;
            } else {
                throw error;
            }
        }
    }
    return [getDispatchDocuments]
}

export const useGetDispatchParts = (): [Function] => {
    const dispatch = useDispatch()
    const getDispatchParts = async (id: number) => {
        const partsClient = new PartClient();
        try {
            return await partsClient.getDispatchParts(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(id);
                return cachedDispatch.dispatchParts;
            } else {
                throw error;
            }
        }
    }
    return [getDispatchParts]
}

export const useGetDispatchEquipment = (): [Function] => {
    const dispatch = useDispatch()
    const getDispatchEquipment = async (id: number) => {
        const equipmentClient = new EquipmentClient();
        try {
            return await equipmentClient.getDispatchEquipment(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(id);
                return cachedDispatch.dispatchEquipments;
            } else {
                throw error;
            }
        }
    }
    return [getDispatchEquipment]
}

export const useGetDispatchItems = (): [Function] => {
    const dispatch = useDispatch()
    const getDispatchItems = async (id: number) => {
        try {
            const dispatchItemsClient = new DispatchItemsClient();
            return await dispatchItemsClient.get(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(id);
                return cachedDispatch.dispatchItems;
            } else {
                throw error;
            }
        }
    }
    return [getDispatchItems]
}


export const useAddDispatchNote = (): [Function] => {
    const dispatch = useDispatch()
    const addDispatchNote = async (id: number, noteText: string) => {
        const offlineDb = new TechOfflineDatabase();
        try {
            const dispatchNotesClient = new DispatchNotesClient()
            await dispatchNotesClient.create(new DispatchNoteCreateVM({
                dispatchId: id,
                text: noteText
            }))
            return await cacheDispatchNote(id, noteText, offlineDb)
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.ADD_DISPATCH_NOTE,
                    payload: new DispatchNoteCreateVM({
                        dispatchId: id,
                        text: noteText,
                        created: new Date()
                    }),
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheDispatchNote(id, noteText, offlineDb)
            } else {
                throw error;
            }
        }
    }
    return [addDispatchNote]
}

const cacheDispatchNote = async (id: number, noteText: string, offlineDb: TechOfflineDatabase) => {
    const cachedDispatch = await offlineDb.dispatches.get(id);
    cachedDispatch.dispatchNotes.push(
        new DispatchNoteVM({
            dispatchId: id,
            text: noteText,
            created: new Date(),
            createdBy: getUser()
        }));
    return await offlineDb.dispatches.put(cachedDispatch)
}

export const useAddServiceNote = (): [Function] => {
    const dispatch = useDispatch()
    const addServiceNote = async (id: number, noteText: string) => {
        const offlineDb = new TechOfflineDatabase();
        try {
            const serviceNotesClient = new ServiceNotesClient()
            await serviceNotesClient.create(new ServiceNoteCreateVM({
                dispatchId: id,
                text: noteText
            }))
            return await cacheServiceNote(id, noteText, offlineDb)
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.ADD_SERVICE_NOTE,
                    payload: new ServiceNoteCreateVM({
                        dispatchId: id,
                        text: noteText,
                        created: new Date()
                    }),
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheServiceNote(id, noteText, offlineDb)
            } else {
                throw error;
            }
        }
    }
    return [addServiceNote]
}

const cacheServiceNote = async (id: number, noteText: string, offlineDb: TechOfflineDatabase) => {
    const cachedDispatch = await offlineDb.dispatches.get(id);
    cachedDispatch.serviceNotes.push(
        new ServiceNoteVM({
            dispatchId: id,
            text: noteText,
            created: new Date(),
            createdBy: getUser()
        }));
    return await offlineDb.dispatches.put(cachedDispatch)
}

export const useUpdateDispatchParts = (): [Function] => {
    const dispatch = useDispatch()
    const updateDispatchParts = async (id: number, partsIds: number[]) => {
        const offlineDb = new TechOfflineDatabase();
        const requestPayload = new DispatchPartsUpdateVM({
            dispatchId: id,
            parts: partsIds
        })
        try {
            const partClient = new PartClient();
            await partClient.updateDispatchParts(requestPayload)
            return await cacheDispatchParts(id, partsIds, offlineDb)
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                offlineDb.actions
                    .where("actionType")
                    .equalsIgnoreCase(TechAction.UPDATE_DISPATCH_PARTS)
                    .delete();
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.UPDATE_DISPATCH_PARTS,
                    payload: requestPayload,
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheDispatchParts(id, partsIds, offlineDb)
            } else {
                throw error;
            }
        }
    }
    return [updateDispatchParts]
}

const cacheDispatchParts = async (id: number, partsIds: number[], offlineDb: TechOfflineDatabase) => {
    const cachedParts = await offlineDb.parts.filter(p => partsIds.includes(p.id)).toArray();
    const dispatchPartsToAdd = cachedParts.map(p => new DispatchPartVM({
        dispatchId: id,
        part: new PartVM({ ...p })
    }))
    const cachedDispatch = await offlineDb.dispatches.get(id);
    cachedDispatch.dispatchParts = dispatchPartsToAdd;
    return await offlineDb.dispatches.put(cachedDispatch)
}

export const useAddItemToDispatch = (): [Function] => {
    const dispatch = useDispatch()
    const addItemToDispatch = async (id: number, description: string) => {
        const offlineDb = new TechOfflineDatabase();
        const requestPayload = new DispatchItemCreateVM({
            dispatchId: id,
            description: description
        })
        try {
            const dispatchItemsClient = new DispatchItemsClient();
            await dispatchItemsClient.create(requestPayload);
            return await addItemToDispatchCache(requestPayload, offlineDb);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.ADD_ITEM_TO_DISPATCH,
                    payload: requestPayload,
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }))
                return await addItemToDispatchCache(requestPayload, offlineDb);
            } else {
                throw error;
            }
        }
    }
    return [addItemToDispatch]
}

const addItemToDispatchCache = async (payload: DispatchItemCreateVM, offlineDb: TechOfflineDatabase) => {
    const cachedDispatch = await offlineDb.dispatches.get(payload.dispatchId);
    cachedDispatch.dispatchItems.push(
        new DispatchItemVM({
            dispatchId: payload.dispatchId,
            description: payload.description
        })
    )
    return await offlineDb.dispatches.put(cachedDispatch);
}

export const useUpdateDispatchEquipment = (): [Function] => {
    const dispatch = useDispatch()
    const updateDispatchEquipment = async (id: number, equipmentIds: number[]) => {
        const offlineDb = new TechOfflineDatabase();
        const requestPayload = new DispatchEquipmentUpdateVM({
            dispatchId: id,
            equipment: equipmentIds
        });
        try {
            const equipmentClient = new EquipmentClient()
            await equipmentClient.updateDispatchEquipment(requestPayload)
            return await cacheDispatchEquipment(id, equipmentIds, offlineDb);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                offlineDb.actions
                    .where("actionType")
                    .equalsIgnoreCase(TechAction.UPDATE_DISPATCH_EQUIPMENT)
                    .delete();
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.UPDATE_DISPATCH_EQUIPMENT,
                    payload: requestPayload,
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheDispatchEquipment(id, equipmentIds, offlineDb);
            } else {
                throw error;
            }
        }
    }
    return [updateDispatchEquipment]
}

const cacheDispatchEquipment = async (id: number, equipmentIds: number[], offlineDb: TechOfflineDatabase) => {
    const cachedEquipment = await offlineDb.equipment.filter(e => equipmentIds.includes(e.id)).toArray();
    const dispatchEquipmentToAdd = cachedEquipment.map(e => new DispatchEquipmentVM({
        dispatchId: id,
        equipment: new EquipmentBaseVM({ ...e })
    }))
    const cachedDispatch = await offlineDb.dispatches.get(id);
    cachedDispatch.dispatchEquipments = dispatchEquipmentToAdd;
    return await offlineDb.dispatches.put(cachedDispatch)
}

export const useAddDocument = (): [Function] => {
    const dispatch = useDispatch()
    const addDocument = async (id: number, file: File) => {
        const offlineDb = new TechOfflineDatabase();
        const requestPayload = new DispatchDocumentAddVM({
            dispatchId: id,
            name: file.name,
            isConfidential: false,
            file: file
        });
        try {
            const documentClient = new CustomDispatchDocumentsClient();
            await documentClient.create(requestPayload);
            return await cacheDocument(id, file, offlineDb);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.ADD_DOCUMENT,
                    payload: requestPayload,
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheDocument(id, file, offlineDb);
            } else {
                throw error;
            }
        }
    }
    return [addDocument]
}

const cacheDocument = async (id: number, file: File, offlineDb: TechOfflineDatabase) => {
    const generatedDocumentId = generateNumberUID();
    const cachedDispatch = await offlineDb.dispatches.get(id);
    cachedDispatch.dispatchDocuments.push(
        new DispatchDocumentVM({
            dispatchId: id,
            documentFile: new DocumentFileVM({
                id: generatedDocumentId,
                name: file.name,
                isConfidential: false,
            })
        }));
    await offlineDb.dispatches.put(cachedDispatch)
    return await offlineDb.documents.put(new TableDocumentFileVM({
        id: generatedDocumentId,
        file: {
            fileName: file.name,
            data: file,
            status: 200
        }
    }))
}

export const useChangeDispatchStatus = (): [Function] => {
    const _dispatch = useDispatch()
    const changeDispatchStatus = async (dispatch: DispatchVM, statusId: number) => {
        const offlineDb = new TechOfflineDatabase();
        const requestPayload = new DispatchUpdateVM({
            id: dispatch.id!,
            locationId: dispatch.locationId!,
            followUpDate: dispatch.followUpDate,
            workOrderNumber: dispatch.workOrderNumber,
            priorityId: dispatch.priorityId,
            tradeId: dispatch.tradeId,
            slaDate: dispatch.slaDate,
            tehnicianId: dispatch.tehnicianId,
            statusId: statusId
        });
        try {
            const dispatchClient = new DispatchClient();
            await dispatchClient.put(requestPayload)
            return await cacheDispatchStatus(dispatch.id, statusId, offlineDb);
        } catch (error) {
            if (isOffline(error.message as string)) {
                _dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.CHANGE_DISPATCH_STATUS,
                    payload: requestPayload,
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheDispatchStatus(dispatch.id, statusId, offlineDb);
            } else {
                throw error;
            }
        }
    }
    return [changeDispatchStatus]
}

const cacheDispatchStatus = async (id: number, statusId: number, offlineDb: TechOfflineDatabase) => {
    const cachedDispatch = await offlineDb.dispatches.get(id);
    cachedDispatch.statusId = statusId;
    return await offlineDb.dispatches.put(cachedDispatch);
}

export const useAddLocationNote = (): [Function] => {
    const dispatch = useDispatch()
    const addLocationNote = async (dispatchId: number, locationId: number, noteText: string) => {
        const offlineDb = new TechOfflineDatabase();
        const requestPayload = new LocationNoteCreateVM({
            locationId: locationId,
            text: noteText
        });
        try {
            const locationClient = new LocationClient();
            await locationClient.createNote(requestPayload)
            return await cacheLocationNote(dispatchId, locationId, noteText, offlineDb);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.ADD_LOCATION_NOTE,
                    payload: requestPayload,
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheLocationNote(dispatchId, locationId, noteText, offlineDb);
            } else {
                throw error;
            }
        }
    }
    return [addLocationNote]
}

const cacheLocationNote = async (dispatchId: number, locationId: number, noteText: string, offlineDb: TechOfflineDatabase) => {
    const cachedDispatch = await offlineDb.dispatches.get(dispatchId);
    cachedDispatch.location.notes.push(
        new LocationNoteVM({
            locationId: locationId,
            text: noteText,
            created: new Date(),
            createdBy: getUser()
        }));
    return await offlineDb.dispatches.put(cachedDispatch)
}

export const useAddEquipmentNote = (): [Function] => {
    const dispatch = useDispatch()
    const addEquipmentNote = async (id: number, noteText: string) => {
        const offlineDb = new TechOfflineDatabase();
        const requestPayload = new EquipmentNoteCreateVM({
            equipmentId: id,
            text: noteText
        });
        try {
            const equipmentClient = new EquipmentClient()
            await equipmentClient.createNote(requestPayload)
            return await cacheEquipmentNote(id, noteText, offlineDb);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.ADD_EQUIPMENT_NOTE,
                    payload: requestPayload,
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheEquipmentNote(id, noteText, offlineDb);
            } else {
                throw error;
            }
        }
    }
    return [addEquipmentNote]
}

const cacheEquipmentNote = async (id: number, noteText: string, offlineDb: TechOfflineDatabase) => {
    const cachedEquipment = await offlineDb.equipment.get(id);
    cachedEquipment.notes.push(
        new EquipmentNoteVM({
            id: generateNumberUID(),
            equipmentId: id,
            text: noteText,
            created: new Date(),
            createdBy: getUser()
        }));
    return await offlineDb.equipment.put(cachedEquipment);
}
//#endregion TECH DISPATCH VIEW

//#region TECH LOCATION AND CONTACTS
export const useGetLocation = (): [Function] => {
    const dispatch = useDispatch()
    const getLocation = async (dispatchId: number, locationId: number) => {
        const locationClient = new LocationClient();
        try {
            return await locationClient.getById(locationId)
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const cachedDispatch = await getCachedDispatch(dispatchId);
                return cachedDispatch.location;
            } else {
                throw error;
            }
        }
    }
    return [getLocation]
}
//#endregion TECH LOCATION AND CONTACTS

//#region TECH DISPATCH DETAILS
export const useGetStatuses = (): [Function] => {
    const dispatch = useDispatch()
    const getStatuses = async () => {
        const statusesClient = new DispatchStatusClient();
        try {
            return await statusesClient.getAll();
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                return await offlineDb.statuses.toArray();
            } else {
                throw error;
            }
        }
    }
    return [getStatuses]
}
//#endregion TECH DISPATCH DETAILS

//#region TECH EQUIPMENT
export const useGetEquipment = (): [Function] => {
    const dispatch = useDispatch()
    const getEquipment = async (id: number) => {
        const equipmentClient = new EquipmentClient();
        try {
            return await equipmentClient.get(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                return await offlineDb.equipment.get(id);
            } else {
                throw error;
            }
        }
    }
    return [getEquipment]
}

export const useGetEquipmentAll = (): [Function] => {
    const dispatch = useDispatch()
    const getEquipmentAll = async () => {
        const equipmentClient = new EquipmentClient();
        try {
            return await equipmentClient.getAll();
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                return await offlineDb.equipment.toArray();
            } else {
                throw error;
            }
        }
    }
    return [getEquipmentAll]
}
//#endregion TECH EQUIPMENT

//#region TECH PARTS
export const useGetParts = (): [Function] => {
    const dispatch = useDispatch()
    const getParts = async () => {
        const partsClient = new PartClient();
        try {
            return await partsClient.get();
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                return await offlineDb.parts.toArray();
            } else {
                throw error;
            }
        }
    }
    return [getParts]
}
//#endregion TECH PARTS

//#region TECH DOCUMENTS
export const useDownload = (): [Function] => {
    const dispatch = useDispatch()
    const download = async (id: number) => {
        const dispatchDocumentsClient = new DispatchDocumentsClient();
        try {
            return await dispatchDocumentsClient.download(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                const cachedDocument = await offlineDb.documents.get(id);
                return cachedDocument.file;
            } else {
                throw error;
            }
        }
    }
    return [download]
}
//#endregion TECH DOCUMENTS

//#region TECH SIGNATURES
export const usePreview = (): [Function] => {
    const dispatch = useDispatch()
    const preview = async (id: number) => {
        const dispatchClient = new DispatchClient();
        try {
            return await dispatchClient.preview(id);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                const cachedSignature = await offlineDb.signatures.get(id);
                return cachedSignature.file;
            } else {
                throw error;
            }
        }
    }
    return [preview]
}

export const useAddSignature = (): [Function] => {
    const dispatch = useDispatch()
    const addSignature = async (id: number, name: string, text: string, file: File) => {
        const offlineDb = new TechOfflineDatabase();
        try {
            const client = new UploadFileClient();
            await client.addSignature(new SignatureAddVM({
                dispatchId: id,
                name: name,
                text: text,
                file: file
            }))
            return await cacheSignature(id, name, text, file, offlineDb);
        } catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                await offlineDb.actions.put(new TableTechActionVM({
                    actionType: TechAction.ADD_SIGNATURE,
                    payload: new SignatureAddVM({
                        dispatchId: id,
                        name: name,
                        text: text,
                        file: file,
                        created: new Date()
                    }),
                    timestamp: new Date(),
                    uid: generateNumberUID()
                }));
                return await cacheSignature(id, name, text, file, offlineDb);
            } else {
                throw error;
            }
        }
    }
    return [addSignature]
}

const cacheSignature = async (id: number, name: string, text: string, file: File, offlineDb: TechOfflineDatabase) => {
    const generatedDocumentId = generateNumberUID();
    const cachedDispatch = await offlineDb.dispatches.get(id);
    cachedDispatch.signatures.push(
        new SignatureVM({
            id: generatedDocumentId,
            dispatchId: id,
            documentFileId: generatedDocumentId,
            name: name,
            text: text,
            created: new Date()
        }));
    await offlineDb.dispatches.put(cachedDispatch)
    if (file !== null) {
        return await offlineDb.signatures.put(new TableDocumentFileVM({
            id: generatedDocumentId,
            file: {
                fileName: file.name,
                data: file,
                status: 200
            }
        }))
    } else {
        return new Promise<number>((resolve) => {
            resolve(0);
        });
    }
}
//#endregion TECH SIGNATURES

//#region TECH VISITS ADD TRAVEL ON SITE LEAVE
export const useGetClockInQuestions = (): [Function] => {
    const dispatch = useDispatch()
    const getClockInQuestions = async () => {
        //todo: maybe just always cache clock in/out questions or use versioning mechanism for faster performance(goes for all cached entities)
        try {
            const clockInQuestionsClient = new ClockInQuestionsClient();
            return await clockInQuestionsClient.get();
        }
        catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                return await offlineDb.clockInQuestions.toArray();
            } else throw error;
        }
    }
    return [getClockInQuestions]
}

export const useGetClockOutQuestions = (): [Function] => {
    const dispatch = useDispatch()
    const getClockOutQuestions = async () => {
        try {
            const clockOutQuestionsClient = new ClockOutQuestionsClient();
            return await clockOutQuestionsClient.get();
        }
        catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const offlineDb = new TechOfflineDatabase();
                return await offlineDb.clockOutQuestions.toArray();
            } else throw error;
        }
    }
    return [getClockOutQuestions]
}

interface OfflineVisitStartTravel {
    // travelTime: Date,
    dispatchId: number
}

export const useGetVisits = (): [Function] => {
    const dispatch = useDispatch()
    const getVisits = async (dispatchId: number) => {
        const cachedDispatch = await getCachedDispatch(dispatchId);
        try {
            const visitsClient = new VisitsClient();
            const visits = await visitsClient.getForDisplay(dispatchId);
            const unsynchedVisits = await areThereUnsynchedVisits();
            return visits.length > cachedDispatch.visits.length
                ? visits
                : visits.length === cachedDispatch.visits.length && unsynchedVisits
                    ? cachedDispatch.visits
                    : visits
        }
        catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                return cachedDispatch.visits;
            } else {
                throw error;
            }
        }
    }
    return [getVisits]
}

export const useVisitStartTravel = (): [Function] => {
    const dispatch = useDispatch()
    const visitStartTravel = async (dispatchId: number) => {
        const offlineDb = new TechOfflineDatabase();
        const cachedDispatch = await offlineDb.dispatches.get(dispatchId);
        const newVisit = new VisitVM({
            dispatchId: dispatchId,
            clockIn: new ClockInVM({
                startedTravelingTime: new Date(),
                arrivedOnSite: false,
            }),
            finished: false
        });
        try {
            const visitsClient = new VisitsClient();
            const ongoingVisitId = await visitsClient.visitStartTravel(new VisitStartTravelVM({
                dispatchId: dispatchId
            }));
            newVisit.id = ongoingVisitId;
            cachedDispatch.visits.push(newVisit);
            offlineDb.dispatches.put(cachedDispatch);
            return ongoingVisitId;
        }
        catch (error) {
            if (isOffline(error.message as string)) {
                dispatch(setOnlineState(false))
                const existingVisitRequests = (await offlineDb.actions.toArray()).filter(a => a.actionType == TechAction.VISIT_START_TRAVEL).length;
                const startTravelTime = new Date();
                newVisit.id = existingVisitRequests === 0 ? -1 : ((existingVisitRequests * -1) - 1);
                offlineDb.actions.add(new TableTechActionVM({
                    actionType: TechAction.VISIT_START_TRAVEL,
                    payload: {
                        dispatchId: dispatchId,
                        startedTravelingTime: startTravelTime
                    } as OfflineVisitStartTravel,
                    timestamp: startTravelTime,
                    uid: generateNumberUID()
                }));
                cachedDispatch.visits.push(newVisit);
                offlineDb.dispatches.put(cachedDispatch);
                return newVisit.id;
            } else {
                throw error;
            }
        }
    }
    return [visitStartTravel]
}

interface IVisitList {
    visits: VisitVM[]
}

export const useVisitEnterSite = (): [Function] => {
    const dispatch = useDispatch()
    const visitEnterSite = async (request: VisitClockInVM) => {
        const offlineDb = new TechOfflineDatabase();
        const timestamp = new Date();
        const cachedAction = new TableTechActionVM({
            actionType: TechAction.VISIT_ENTER_SITE,
            payload: request,
            timestamp: timestamp,
            uid: generateNumberUID()
        });
        const cachedDispatch = await offlineDb.dispatches.get(request.dispatchId);
        const cachedVisit = cachedDispatch.visits.find(v => v.finished == false && v.clockIn.arrivedOnSite == false);
        cachedVisit.clockIn = new ClockInVM({
            ...cachedVisit.clockIn,
            arrivedOnSite: true,
            clockInTime: timestamp,
            clockInAnswers: request.clockIn.clockInAnswers
        })
        offlineDb.dispatches.put(cachedDispatch);
        if (!request.visitId || request.visitId < 0) {
            //if visit id is negative that means the visit was not yet created in the back-end
            offlineDb.actions.add(cachedAction);
        } else {
            try {
                const visitsClient = new VisitsClient();
                await visitsClient.visitClockIn(request);
            } catch (error) {
                if (isOffline(error.message as string)) {
                    dispatch(setOnlineState(false))
                    offlineDb.actions.add(cachedAction);
                    // const cachedDispatch = await offlineDb.dispatches.get(request.dispatchId);
                    // const cachedVisit = cachedDispatch.visits.find(v => v.finished == false && v.clockIn.arrivedOnSite == false);
                    // cachedVisit.clockIn = new ClockInVM({
                    //     arrivedOnSite: true
                    // })
                    // offlineDb.dispatches.put(cachedDispatch);
                }
                else throw error;
            }
        }
    }
    return [visitEnterSite]
}

export const useVisitLeaveSite = (): [Function] => {
    const dispatch = useDispatch()
    const visitLeaveSite = async (request: ClockOutCreateVM) => {
        const offlineDb = new TechOfflineDatabase();
        const timestamp = new Date();
        const cachedAction = new TableTechActionVM({
            actionType: TechAction.VISIT_LEAVE_SITE,
            payload: request,
            timestamp: timestamp,
            uid: generateNumberUID()
        });
        const cachedDispatch = await offlineDb.dispatches.get(request.dispatchId);
        const cachedVisit = cachedDispatch.visits.find(v => v.finished == false && v.clockIn.arrivedOnSite == true);
        cachedVisit.finished = true;
        cachedVisit.clockOut = new ClockOutVM({
            clockOutTime: timestamp,
            clockOutAnswers: request.clockOutAnswers
        })
        cachedVisit.visitTime = Math.floor((cachedVisit.clockOut.clockOutTime.valueOf() - cachedVisit.clockIn.clockInTime.valueOf()) / 60000)
        offlineDb.dispatches.put(cachedDispatch);
        if (!request.visitId || request.visitId < 0) {
            //if visit id is negative that means the visit was not yet created in the back-end
            offlineDb.actions.add(cachedAction);
        } else {
            try {
                const visitsClient = new VisitsClient();
                await visitsClient.visitClockOut(request);
            }
            catch (error) {
                if (isOffline(error.message as string)) {
                    dispatch(setOnlineState(false))
                    offlineDb.actions.add(cachedAction);
                    const cachedDispatch = await offlineDb.dispatches.get(request.dispatchId);
                    const cachedVisit = cachedDispatch.visits.find(v => v.finished == false && v.clockIn.arrivedOnSite == true);
                    cachedVisit.finished = true;
                    cachedVisit.clockOut = new ClockOutVM({
                        clockOutTime: timestamp,
                        clockOutAnswers: request.clockOutAnswers
                    })
                    cachedVisit.visitTime = Math.floor((cachedVisit.clockOut.clockOutTime.valueOf() - cachedVisit.clockIn.clockInTime.valueOf()) / 60000)
                    offlineDb.dispatches.put(cachedDispatch);
                }
                else throw error;
            }
        }
    }
    return [visitLeaveSite]
}

//#endregion

//#region FIRE QUEUED REQUESTS
export const areThereUnsynchedVisits = async () => {
    const offlineDb = new TechOfflineDatabase();
    return await offlineDb.actions.where("actionType").startsWithIgnoreCase('VISIT').count() > 0
}

export const getCachedRequests = async () => {
    const offlineDb = new TechOfflineDatabase();
    return (await offlineDb.actions.toArray()).sort((a, b) => (a.timestamp.getTime() - b.timestamp.getTime()))
}

const dequeueAction = async (id: number) => {
    const offlineDb = new TechOfflineDatabase();
    await offlineDb.actions.delete(id);
}

export const removeQueuedRequests = async (ids: number[]) => {
    for await (const id of ids) {
        await dequeueAction(id)
    }
}

export const fireQueuedRequests = async () => {

    //go through cached requests and try to execute them
    //todo: maybe don't go through here if we're offline?
    //todo: maybe set periodic interval which checks in the background constantly if there are requests to be fire
    const offlineDb = new TechOfflineDatabase();
    let oneRequestFailed = false;
    const requests = (await offlineDb.actions.toArray()).sort((a, b) => (a.timestamp.getTime() - b.timestamp.getTime()));
    if (requests.length > 0) {
        for await (const request of requests) {
            //we might need to have some indicator to tell the user not all stuff was synched or how many requests were synched
            //alternatively we can make ONE back-end endpoint to make one big synch???
            //todo: possible that user makes 5 updates on parts, in which case only one request should be sent, now we're queueing an action for each update(should be implemented similar to temp state on dispatch hq)
            if (oneRequestFailed) break;
            try {
                switch (request.actionType) {
                    case TechAction.ADD_DISPATCH_NOTE:
                        const dispatchNotesClient = new DispatchNotesClient();
                        await dispatchNotesClient.create(request.payload as DispatchNoteCreateVM);
                        break;

                    case TechAction.UPDATE_DISPATCH_EQUIPMENT:
                        const equipmentClient = new EquipmentClient();
                        await equipmentClient.updateDispatchEquipment(request.payload as DispatchEquipmentUpdateVM);
                        break;

                    case TechAction.ADD_LOCATION_NOTE:
                        const locationClient = new LocationClient();
                        await locationClient.createNote(request.payload as LocationNoteCreateVM);
                        break;

                    case TechAction.CHANGE_DISPATCH_STATUS:
                        const dispatchClient = new DispatchClient();
                        await dispatchClient.put(request.payload as DispatchUpdateVM)
                        break;

                    case TechAction.ADD_SERVICE_NOTE:
                        const serviceNotesClient = new ServiceNotesClient()
                        await serviceNotesClient.create(request.payload as ServiceNoteCreateVM);
                        break;

                    case TechAction.ADD_EQUIPMENT_NOTE:
                        const equipmentClient2 = new EquipmentClient()
                        await equipmentClient2.createNote(request.payload as EquipmentNoteCreateVM);
                        break;

                    case TechAction.UPDATE_DISPATCH_PARTS:
                        const partClient = new PartClient();
                        await partClient.updateDispatchParts(request.payload as DispatchPartsUpdateVM);
                        break;

                    case TechAction.ADD_ITEM_TO_DISPATCH:
                        const dispatchItemsClient = new DispatchItemsClient();
                        await dispatchItemsClient.create(request.payload as DispatchItemCreateVM);
                        break;

                    case TechAction.ADD_DOCUMENT:
                        const documentClient = new CustomDispatchDocumentsClient();
                        await documentClient.create(request.payload as DispatchDocumentAddVM);
                        break;

                    case TechAction.ADD_SIGNATURE:
                        const client = new UploadFileClient();
                        await client.addSignature(request.payload as SignatureAddVM);
                        break;

                    case TechAction.VISIT_START_TRAVEL:
                        const visitsClient = new VisitsClient();
                        const generatedVisitId = await visitsClient.visitStartTravel(request.payload as VisitStartTravelVM);
                        const relatedCachedActions = (await offlineDb.actions.toArray()).filter(a => a.actionType == TechAction.VISIT_ENTER_SITE || a.actionType == TechAction.VISIT_LEAVE_SITE).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()).slice(0, 2);

                        console.log(relatedCachedActions);
                        if (relatedCachedActions[0] && relatedCachedActions[0].actionType == TechAction.VISIT_ENTER_SITE) {
                            (relatedCachedActions[0].payload as VisitClockInVM).visitId = generatedVisitId;
                            await offlineDb.actions.put(relatedCachedActions[0], relatedCachedActions[0].uid);

                            if (relatedCachedActions[1] && relatedCachedActions[1].actionType == TechAction.VISIT_LEAVE_SITE) {
                                (relatedCachedActions[1].payload as ClockOutCreateVM).visitId = generatedVisitId;
                                await offlineDb.actions.put(relatedCachedActions[1], relatedCachedActions[1].uid);
                            }
                        }
                        break;

                    case TechAction.VISIT_ENTER_SITE:
                        const visitsClient2 = new VisitsClient();
                        const _request = await offlineDb.actions.get(request.uid);
                        await visitsClient2.visitClockIn(_request.payload as VisitClockInVM);
                        break;

                    case TechAction.VISIT_LEAVE_SITE:
                        const visitsClient3 = new VisitsClient();
                        const __request = await offlineDb.actions.get(request.uid);
                        await visitsClient3.visitClockOut(__request.payload as ClockOutCreateVM);
                        break;

                    default:
                        break;
                }
                await offlineDb.actions.delete(request.uid);
            }
            catch (error) {
                console.log(error);
                oneRequestFailed = true;
            }
        }
    }
};

//#endregion FIRE QUEUED REQUESTS