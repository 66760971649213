import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ManufacturerClient, ManufacturerVM, ManufacturerAddVM } from '../../../brines-refrigerator-api';
import AddTable from '../../common/table/AddTable';
import SnackbarNotification from '../../common/snackbar/SnackbarNotification';
import { Color } from '@material-ui/lab/Alert';
import { redirectIfSessionExpired } from '../../common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../../helpers/validations';

const Manufacturers = () => {
  const history = useHistory();
  const [manufacturers, setManufacturers] = useState([]);

  async function populateTable() {

    try {
      const manufacturerClient = new ManufacturerClient();
      const manufacturers = await manufacturerClient.get();
      setManufacturers(manufacturers);
    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error displaying manufacturers.', "error")
    }
  };

  useEffect(() => {
    populateTable()
  }, []);

  //Delete Manufacturer
  const deleteManufacturer = async (manufacturerId) => {
    try {
      const manufacturerClient = new ManufacturerClient();
      await manufacturerClient.delete(manufacturerId);
      populateTable()
      setSnackBarState(true, 'Success deleting manufacturer.', "success")
    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error deleting manufacturer. There might be equipment using this manufacturer.', "error")
    }
  }

  const manufacturerColumns = [
    { title: 'Manufacturer', field: 'name' }
  ];

  const addManufacturer = async (data: ManufacturerVM) => {
    const result = data.name ? validateText(data.name) : { error: true, errorMessage: 'This field can\'t be empty.' };
    if (result.error) {
      setSnackBarState(true, result.errorMessage, "error")
      return;
    }
    try {
      const manufacturerClient = new ManufacturerClient();
      await manufacturerClient.post(
        new ManufacturerAddVM({
          name: data.name
        })
      );
      setSnackBarState(true, 'Success adding manufacturer.', "success")
      populateTable()
    } catch (error) {
      redirectIfSessionExpired(history, error)
      setSnackBarState(true, 'Error adding manufacturer.', "error")
    }
  }

  const snackbarDuration = 7000;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<Color>()

  const setSnackBarState = (state: boolean, message: string, severity: Color) => {
    setSnackbarOpen(state)
    setSnackbarMessage(message)
    setSeverity(severity)
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  
  const tableTooltip= { addTooltip: "Enter Manufacturer" }
  
  return (
    <>
      <Grid item sm={12} container>
        <AddTable
          width={'100%'}
          columns={manufacturerColumns}
          title={"Manufacturers"}
          add={addManufacturer}
          delete={deleteManufacturer}
          data={manufacturers as []}
          search={false}
          localization={tableTooltip}
          deleteTooltip={"Delete Manufacturer"}
        />
      </Grid>
      <SnackbarNotification
        open={snackbarOpen}
        snackbarMessage={snackbarMessage}
        handleClose={handleClose}
        severity={severity}
        snackbarDuration={snackbarDuration}
      />
    </>
  );
}
export default Manufacturers;