import React, { useState, useEffect, ElementType } from 'react';

import { makeStyles, Grid, Typography, Button, FormControl, InputAdornment, InputBaseComponentProps, TextField } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { InvoiceClient, InvoiceVM, InvoiceUpdateVM } from '../../brines-refrigerator-api';
import InvoiceHeader from './InvoiceHeader/InvoiceHeader';
import InvoiceParts from './InvoiceParts/InvoiceParts';
import InvoiceLabor from './InvoiceLabor';
import InvoiceWorkOrder from './InvoiceWorkOrder';
import { decimalNumberFormat } from '../../helpers/inputFormatters';
import { downloadFile } from '../../helpers/download';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
    root: {
        margin: "1rem 2.5rem"
    },
    companyInfo: {
        fontSize: "1.7rem",
        fontWeight: 100,
        color: "#6e6e6e"
    },
    header1Gray: {
        fontSize: "1.9rem",
        color: "#6e6e6e",
        fontWeight: 500
    },
    header2Gray: {
        fontSize: "1.55rem",
        color: "#6e6e6e",
        fontWeight: 500
    },
    header2Primary: {
        fontSize: "1.55rem",
        color: "#3f51b5",
        fontWeight: 500
    },
    header3Gray: {
        fontSize: "1.1rem",
        color: "#6e6e6e",
        fontWeight: 500
    },
    bodyText: {
        color: "#6e6e6e",
        fontSize: "1.1rem"
    },
    marginBottomMd: {
        marginBottom: "2rem"
    },
    marginBottomLg: {
        marginBottom: "6rem"
    },
    dispatchInfoCard: {
        padding: "1rem",
        backgroundColor: "rgb(245, 246, 251)"
    },
    totalSection: {
        borderTop: "1px solid rgba(0,0,0, 0.2)",
        padding: "1rem 0",
    },
    buttonSection: {
        width: '99%'
    },
    widthTaxInput: {
        width: '90%',
    },
    progress: {
        position: 'fixed',
        left: '50%',
        top: '35%',
        zIndex: 1000
    },
});

const Invoice = () => {
    const invoiceClient = new InvoiceClient();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [invoice, setInvoice] = useState(new InvoiceVM({
        id: 0,
        parts: 0,
        labor: 0,
        materialTax: 0,
        laborTax: 0,
        number: '000000',
        trip: 0,
        tripTax: 0
    }));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getInvoice = async (id: number) => {
        try {
            const invoice = await invoiceClient.getById(id);
            setInvoice(invoice);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    const updateInvoice = async (inTaxReview: boolean) => {
        try {
            const updatedInvoice = await invoiceClient.put(new InvoiceUpdateVM({
                id: invoice.id,
                laborTax: invoice.laborTax,
                materialTax: invoice.materialTax,
                trip: invoice.trip,
                tripTax: invoice.tripTax,
                inTaxReview: inTaxReview
            }));
            setInvoice(updatedInvoice);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    useEffect(() => {
        if (location.state) {
            getInvoice((location.state as { invoiceId: number }).invoiceId)
        }
    }, [])

    const updateInvoiceTotal = () => {
        getInvoice((location.state as { invoiceId: number }).invoiceId);;
    }

    const previewInvoice = () => {
        history.push({
            pathname: "/preview-invoice",
            state: { invoiceId: invoice.id, dispatchId: invoice.dispatchId }
        });
    }

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const generatePdf = async () => {
        try {
            setIsGeneratingPdf(true);
            const response = await invoiceClient.generatePdf(invoice.id);
            setIsGeneratingPdf(false);
            await downloadFile(response.data, response.fileName);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
        setIsGeneratingPdf(false);
    }

    const exemptInvoiceFromTax = async () => {
        try {
            const invoiceId = (location.state as { invoiceId: number }).invoiceId;
            await invoiceClient.exemptInvoiceFromTax(invoiceId);
            enqueueSnackbar("Invoice exempted from tax.", { variant: "success" });

            await getInvoice(invoiceId);
        }
        catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    const enableInvoiceTaxes = async () => {
        try {
            const invoiceId = (location.state as { invoiceId: number }).invoiceId;
            await invoiceClient.enableInvoiceTaxes(invoiceId);
            enqueueSnackbar("Invoice taxes enabled.", { variant: "success" });

            await getInvoice(invoiceId);
        }
        catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    return (
        <>
            {isGeneratingPdf && <CircularProgress size={100} className={classes.progress} />}
            <div className={`${classes.root} ${classes.marginBottomMd}`}>
                <InvoiceHeader
                    invoice={invoice}
                />
                {/* Parts table */}
                <Grid item xs={12} className={classes.marginBottomMd}>
                    <InvoiceParts
                        invoiceId={(location.state as { invoiceId: number }).invoiceId}
                        updateInvoiceTotal={updateInvoiceTotal}
                    />
                </Grid>
                {/* Labor table */}
                <Grid item xs={12} className={classes.marginBottomMd}>
                    <InvoiceLabor
                        invoiceId={(location.state as { invoiceId: number }).invoiceId}
                        updateInvoiceTotal={updateInvoiceTotal}
                    />
                </Grid>
                <Grid item container xs={12} className={classes.totalSection}>
                    <Grid item xs={2}>
                        <Typography className={classes.header3Gray}>Trip<br />
                            <FormControl fullWidth className={classes.widthTaxInput}>
                                <TextField
                                    value={invoice.trip}
                                    onChange={(e) => setInvoice(new InvoiceVM({ ...invoice, trip: Number(e.target.value) }))}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.header3Gray}>Trip tax<br />
                            <FormControl fullWidth className={classes.widthTaxInput}>
                                <TextField
                                    value={invoice.tripTax}
                                    onChange={(e) => setInvoice(new InvoiceVM({ ...invoice, tripTax: Number(e.target.value) }))}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    disabled={invoice.isTaxExempt}
                                />
                            </FormControl>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography className={classes.header2Gray} align="right">Trip Subtotal <br />
                        ${(invoice.trip + invoice.tripTax).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.totalSection}>
                    <Grid item xs={2}>
                        <Typography className={classes.header3Gray}>Parts <br />${invoice.parts}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.header3Gray}>Material tax<br />
                            <FormControl fullWidth className={classes.widthTaxInput}>
                                <TextField
                                    value={invoice.materialTax}
                                    disabled={true}
                                    onChange={(e) => setInvoice(new InvoiceVM({ ...invoice, materialTax: Number(e.target.value) }))}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography className={classes.header2Gray} align="right">Parts Subtotal <br />
                        ${(invoice.parts + invoice.materialTax).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.totalSection}>
                    <Grid item xs={2}>
                        <Typography className={classes.header3Gray}>Labor <br />${invoice.labor}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.header3Gray}>Labor tax<br />
                            <FormControl fullWidth className={classes.widthTaxInput}>
                                <TextField
                                    value={invoice.laborTax}
                                    disabled={true}
                                    onChange={(e) => setInvoice(new InvoiceVM({ ...invoice, laborTax: Number(e.target.value) }))}
                                    InputProps={{
                                        inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography className={classes.header2Gray} align="right">Labor Subtotal <br />
                        ${(invoice.labor + invoice.laborTax).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.totalSection}>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.header2Gray} align="right">Total <br />
                        ${(invoice.trip + invoice.tripTax + invoice.parts + invoice.labor + invoice.materialTax + invoice.laborTax).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.totalSection}>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                        {invoice.isTaxExempt ?
                            <Button className={classes.buttonSection} variant="contained" color="primary" onClick={enableInvoiceTaxes}>Enable Invoice Taxes</Button>
                            :
                            <Button className={classes.buttonSection} variant="contained" color="primary" onClick={exemptInvoiceFromTax}>Tax Exempt Invoice</Button>
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.buttonSection} variant="contained" color="primary" onClick={generatePdf}>Download PDF</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.buttonSection} variant="contained" color="primary" onClick={previewInvoice}>Preview invoice PDF</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.buttonSection} variant="contained" color="primary" onClick={() => updateInvoice(true)}>Save for Tax Review</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.buttonSection} variant="contained" color="primary" onClick={() => updateInvoice(false)}>Save and Continue</Button>
                    </Grid>
                </Grid>
            </div>
            <div>
                <InvoiceWorkOrder
                    invoiceId={invoice.id}
                    dispatchId={invoice.dispatchId}
                />
            </div>
        </>
    );
}

export default Invoice;