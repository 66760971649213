import { Button, Grid, InputAdornment, InputBaseComponentProps, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ElementType, useEffect, useState } from 'react';
import { LocationBaseVM, TaxRatesClient, TaxRatesUpdateVM, TaxRateVM } from '../../brines-refrigerator-api';
import { decimalNumberFormat } from '../../helpers/inputFormatters';
import SyncIcon from '@material-ui/icons/Sync';
import { useSnackbar } from 'notistack';

interface EditLocationTaxRatesProps {
    location: LocationBaseVM,
    stateId: number,
    locationStateTaxRate: TaxRateVM,
    countyTaxRates: TaxRateVM[],
    cityTaxRates: TaxRateVM[],
    zipCodeTaxRates: TaxRateVM[],
    syncTaxData: Function
}

const useStyles = makeStyles({
    marginBottom: {
        marginBottom: '2rem'
    },
    marginRight: {
        marginRight: "0.5rem"
    }
});

const EditLocationTaxRates = (props: EditLocationTaxRatesProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        fetchSelectedLocationTaxRates();
    }, [props.location, props.countyTaxRates, props.cityTaxRates, props.zipCodeTaxRates, props.locationStateTaxRate]);

    const fetchSelectedLocationTaxRates = async () => {
        const taxRatesClient = new TaxRatesClient();
        const locationTaxRates = await taxRatesClient.getLocationTaxRates(props.location.id);
        setSelectedCountyTaxRate(props.countyTaxRates.find(ctr => ctr.id == locationTaxRates.countyTaxRateId))
        setSelectedCityTaxRate(props.cityTaxRates.find(ctr => ctr.id == locationTaxRates.cityTaxRateId));
        setSelectedZipCodeTaxRate(props.zipCodeTaxRates.find(ztr => ztr.id == locationTaxRates.zipCodeTaxRateId))
    }

    const [selectedCountyTaxRate, setSelectedCountyTaxRate] = useState(null);
    const handleCountyTaxRateChange = (event: React.ChangeEvent<{ value: unknown }>, value: TaxRateVM) => {
        if (value) {
            setSelectedCountyTaxRate(value);
        }
    }

    const [selectedCityTaxRate, setSelectedCityTaxRate] = useState(null);
    const handleCityTaxRateChange = (event: React.ChangeEvent<{ value: unknown }>, value: TaxRateVM) => {
        if (value) {
            setSelectedCityTaxRate(value);
        }
    }

    const [selectedZipCodeTaxRate, setSelectedZipCodeTaxRate] = useState(null);
    const handleZipCodeTaxRateChange = (event: React.ChangeEvent<{ value: unknown }>, value: TaxRateVM) => {
        if (value) {
            setSelectedZipCodeTaxRate(value);
        }
    }

    const syncTaxData = async () => {
        props.syncTaxData(props.location.stateId)
        setSelectedCountyTaxRate(null);
        setSelectedCityTaxRate(null);
        setSelectedZipCodeTaxRate(null);
    }

    const updateTaxRates = async () => {
        try {
            const taxRatesClient = new TaxRatesClient();
            await taxRatesClient.updateLocationTaxRates(new TaxRatesUpdateVM({
                locationId: props.location.id,
                cityTaxRateId: selectedCityTaxRate ? selectedCityTaxRate.id : null,
                countyTaxRateId: selectedCountyTaxRate ? selectedCountyTaxRate.id : null,
                zipCodeTaxRateId: selectedZipCodeTaxRate ? selectedZipCodeTaxRate.id : null
            }));
            enqueueSnackbar("Updated tax rates successfully", { variant: "success" });
        }
        catch (error) {
            enqueueSnackbar("Error updating tax rates!", { variant: "error" });
        }
    }

    return (
        <Grid container>
            <Grid item container xs={12} alignItems="flex-end" alignContent="flex-end" className={classes.marginBottom}>
                <Grid item xs={6}>
                    <Typography variant="h2">{props.location ? props.location.name : "No location selected"}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={syncTaxData}><SyncIcon color="primary" /></Button>
                </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.marginBottom}>
                <Grid item lg={4} xs={6}>
                    <TextField
                        name='stateTaxRate'
                        label='State Tax Rate'
                        variant='outlined'
                        disabled={true}
                        value={props.locationStateTaxRate ? props.locationStateTaxRate.name : ""}
                        type='text'
                        size='medium'
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item lg={4} xs={6}>
                    <TextField
                        name='stateTaxRateAmount'
                        label='Tax Rate Amount'
                        variant='outlined'
                        disabled={true}
                        value={props.locationStateTaxRate ? props.locationStateTaxRate.amount : null}
                        type='text'
                        size='medium'
                        InputProps={{
                            inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <TextField
                        name='stateTaxRateAmount'
                        label='Tax Rate Type'
                        variant='outlined'
                        disabled={true}
                        value={props.locationStateTaxRate ? props.locationStateTaxRate.taxRateType.name : null}
                        type='text'
                        size='medium'
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.marginBottom} spacing={1}>
                <Grid item lg={4} xs={6}>
                    <Autocomplete
                        disabled={props.countyTaxRates.length > 0 ? false : true}
                        value={selectedCountyTaxRate}
                        options={props.countyTaxRates}
                        getOptionLabel={(option) => option.name}
                        onChange={handleCountyTaxRateChange}
                        renderInput={
                            (params) =>
                                <TextField
                                    {...params}
                                    label='County Tax Rate'
                                    variant='outlined'
                                    type='text'
                                    size='medium'
                                />

                        }
                    />
                </Grid>
                <Grid item lg={4} xs={6}>
                    <TextField
                        value={selectedCountyTaxRate ? selectedCountyTaxRate.amount : null}
                        variant="outlined"
                        label="County tax amt"
                        disabled={true}
                        InputProps={{
                            inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <TextField
                        label='Tax Rate Type'
                        variant='outlined'
                        disabled={true}
                        value={selectedCountyTaxRate ? selectedCountyTaxRate.taxRateType.name : null}
                        type='text'
                        size='medium'
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.marginBottom} spacing={1}>
                <Grid item lg={4} xs={6}>
                    <Autocomplete
                        disabled={props.cityTaxRates.length > 0 ? false : true}
                        value={selectedCityTaxRate}
                        options={props.cityTaxRates}
                        getOptionLabel={(option) => option.name}
                        onChange={handleCityTaxRateChange}
                        renderInput={
                            (params) =>
                                <TextField
                                    {...params}
                                    label='City Tax Rate'
                                    variant='outlined'
                                    type='text'
                                    size='medium'
                                />

                        }
                    />
                </Grid>
                <Grid item lg={4} xs={6}>
                    <TextField
                        value={selectedCityTaxRate ? selectedCityTaxRate.amount : null}
                        variant="outlined"
                        label="City tax amt"
                        disabled={true}
                        InputProps={{
                            inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <TextField
                        label='Tax Rate Type'
                        variant='outlined'
                        disabled={true}
                        value={selectedCityTaxRate ? selectedCityTaxRate.taxRateType.name : null}
                        type='text'
                        size='medium'
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.marginBottom} spacing={1}>
                <Grid item lg={4} xs={6}>
                    <Autocomplete
                        disabled={props.zipCodeTaxRates.length > 0 ? false : true}
                        value={selectedZipCodeTaxRate}
                        options={props.zipCodeTaxRates}
                        getOptionLabel={(option) => option.name}
                        onChange={handleZipCodeTaxRateChange}
                        renderInput={
                            (params) =>
                                <TextField
                                    {...params}
                                    label='ZipCode Tax Rate'
                                    variant='outlined'
                                    type='text'
                                    size='medium'
                                />

                        }
                    />
                </Grid>
                <Grid item lg={4} xs={6}>
                    <TextField
                        value={selectedZipCodeTaxRate ? selectedZipCodeTaxRate.amount : null}
                        variant="outlined"
                        label="ZipCode tax amt"
                        disabled={true}
                        InputProps={{
                            inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <TextField
                        label='Tax Rate Type'
                        variant='outlined'
                        disabled={true}
                        value={selectedZipCodeTaxRate ? selectedZipCodeTaxRate.taxRateType.name : null}
                        type='text'
                        size='medium'
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <Grid>
                <Button color="primary" variant="contained" onClick={updateTaxRates}>Update Taxes</Button>
            </Grid>
        </Grid>
    )
}

export default EditLocationTaxRates;