import React, { useEffect, useState } from 'react';

import { Button, Grid, Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Snackbar } from '@material-ui/core';

import './ClockInOut.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory, useLocation } from 'react-router-dom';
import { VisitClockInVM, ClockInArriveOnSiteVM, ClockInAnswerCreateVM } from '../../../brines-refrigerator-api';
import Alert from '@material-ui/lab/Alert/Alert';
import * as client from '../../../helpers/offline/offline-api';

const TechClockIn: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState('success');
    const [disabled, setDisabled] = useState(false);
    const snackbarDuration = 5000;
    const [_getClockInQuestions] = client.useGetClockInQuestions()
    const [_visitEnterSite] = client.useVisitEnterSite()


    function handleBackClick() {
        history.goBack();
    }

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([null, null]);

    useEffect(() => {
        getQuestions();
    }, []);


    const getQuestions = async () => {
        const questions = await _getClockInQuestions();
        setQuestions(questions);
    }

    const submitAnswers = async () => {
        if (validateAnswers()) {
            try {
                const preparedAnswers = answers.map((x) => { return new ClockInAnswerCreateVM({ clockInQuestionId: x.questionId, answer: x.answer }) });

                const clockIn = new ClockInArriveOnSiteVM({
                    clockInAnswers: preparedAnswers
                })

                // const visitsClient = new VisitsClient();
                const _answers = new VisitClockInVM({
                    dispatchId: (location.state as { dispatchId: number }).dispatchId,
                    clockIn: clockIn,
                    arrivedOnSiteTime: new Date(),
                    visitId: (location.state as { visitId: number }).visitId,
                });

                setDisabled(true);
                //clock in is displayed to tech as "on site"
                _visitEnterSite(_answers);
                setOpen(true);
                setSnackbarState('success');
                setTimeout(handleBackClick, 2000);
            }
            catch (error) {
                setOpen(true);
                setSnackbarState('error');
            }
        } else {
            alert("Please submit all answers!");
        }
    }

    const validateAnswers = () => {
        //this is just some basic validation for good measure
        // return (answers.find(x => x === null));
        return true;

    }

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, questionId: number) => {
        const newAnswers = [...answers];
        newAnswers[index] = {};
        newAnswers[index].answer = event.target.value === "true";
        newAnswers[index].questionId = questionId;
        setAnswers([...newAnswers]);
    }

    const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    type Severity = "error" | "success" | "info" | "warning" | undefined;

    const RenderAlert = ({ alertType }) => {
        const severity: Severity = alertType;

        const AlertMessage = () => {
            if (severity === 'success') {
                return <span>Tech clocked in successfully!</span>
            } else if (severity === 'error') {
                return <span>Error clocking in!</span>
            }
        }

        return (
            <Snackbar open={open} autoHideDuration={snackbarDuration} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleCloseAlert} severity={severity}>
                    <AlertMessage />
                </Alert>
            </Snackbar>
        )
    }

    return (
        <div>
            <Button startIcon={<ArrowBackIosIcon />} onClick={handleBackClick} color="primary">Back</Button>
            <Grid className='clock-io' container>
                <Typography variant="body1" className='clock-io_desc'>
                    Please answer these questions before starting work on the dispatch.
                </Typography>
                <Grid container item xs={12} className='clock-io_questions'>
                    {questions.map((x, index) => {
                        return (
                            <Grid key={x.id} container item xs={12} className='clock-io_question'>
                                <Grid item xs={12} className='clock-io_question-answers'>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className='clock-io_question-desc'>{x.text}</FormLabel>
                                        <RadioGroup aria-label={`answer${index}`} name={`answer${index}`} value={answers[index] ? answers[index].answer : null} onChange={(e) => { handleAnswerChange(e, index, x.id) }}>
                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <RenderAlert alertType={snackbarState} />
                    <Grid item xs={12}>
                        <Button className='clock-io_submit' type='submit' color='primary' variant='contained' size='large' disabled={disabled} onClick={submitAnswers} disableElevation>SUBMIT</Button>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )

}

export default TechClockIn;