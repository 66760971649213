import React, { forwardRef } from 'react';

import './DispatchDocuments.scss';
import { Box, SvgIconProps } from '@material-ui/core';
import UploadButton from '../../../components/common/upload/UploadButton';
import UploadButtonType from '../../../helpers/constants/uploadButtonTypes';
import MaterialTable, { Icons, Column } from 'material-table';
import Delete from '@material-ui/icons/Delete';
import Note from '@material-ui/icons/Note';
import GetAppIcon from '@material-ui/icons/GetApp';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Edit, Check, Clear } from '@material-ui/icons';
import { DispatchDocumentsClient } from '../../../brines-refrigerator-api';
import { downloadFile } from '../../../helpers/download';

const DispatchDocuments = (props) => {
    const dispatchDocumentsColumns = [
        { title: '#', field: 'id', sorting: false, editable: false },
        { title: 'Name', field: 'name', sorting: false },
        { title: 'Type', field: 'type', sorting: false, editable: false }
    ];

    const tableIcons: Icons = {
        Edit: forwardRef((props: SvgIconProps, ref) => <Edit {...props} ref={ref} htmlColor="#3f51b5" />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    }

    const tableActions = [
        rowData => ({
            icon: () => <GetAppIcon color='primary' />,
            onClick: async (event, rowData) => { downloadDocument(rowData.id) },
            hidden: rowData.draft
        }),
        rowData => ({
            icon: () => <Note color='primary' />,
            onClick: (event, rowData) => { console.log('add / view note') },
            hidden: rowData.draft
        }),
        rowData => ({
            icon: () => !rowData.isConfidential ? <VisibilityOff color='primary' /> : <Visibility color='primary' />,
            onClick: (event, rowData) => { props.setIsConfidentialDraft(rowData.uid, rowData.draft) },
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: async (event, rowData) => { await props.deleteDocument(rowData.uid, rowData.draft, rowData.id) }
        })
    ]

    const downloadDocument = async (id: number) => {
        const dispatchDocumentsClient = new DispatchDocumentsClient();
        const response = await dispatchDocumentsClient.download(id);
        await downloadFile(response.data, response.fileName);
    }

    return (
        <div className='dispatch-documents_container'>
            <UploadButton
                buttonType={UploadButtonType.AddDocuments}
                onDropAction={props.addDocuments}
                size="small"
            />
            <Box>
                <MaterialTable
                    icons={tableIcons}
                    columns={dispatchDocumentsColumns as Column<any>[]}
                    title={""}
                    data={props.documents as []}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        paging: false
                    }}
                    actions={tableActions}
                    editable={{
                        isEditHidden: rowData => !rowData.draft,
                        onRowUpdate: (newData) =>
                            new Promise((resolve) => {
                                props.changeDraftDocName(newData.uid, newData.name)
                                resolve();
                            }),
                    }}
                />
            </Box>

        </div>
    )
}

export default DispatchDocuments;