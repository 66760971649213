import React, { useEffect, useReducer, useState, ElementType } from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid, Button, Radio, FormControlLabel, Typography, InputBaseComponentProps, InputAdornment } from '@material-ui/core';
import './LocationCRUDForm.scss';
import { CustomerVM, StateVM, LocationVM, LocationNoteVM, LocationNoteCreateVM, ContactVM, CustomerBaseVM } from '../../brines-refrigerator-api';
import { decimalNumberFormat, phoneNumberFormat } from '../../helpers/inputFormatters';
import UserRole from '../../helpers/constants/userRole';

interface FormProps {
    title: string,
    button: JSX.Element,
    location: LocationVM,
    customers: CustomerBaseVM[],
    states: StateVM[],
    formAction: Function,
    editLocation: Function
}

interface StateType {
    id: number,
    name: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    zip: string,
    ivr: string,
    emsNumber: string,
    active: boolean,
    pmInfo: string,
    stateId: number,
    customerId: number,
    laborRate: number,
    tripRate: number,
    otRate: number,
    holidayRate: number,
    locationMarkup: number,
    notes: LocationNoteVM[],
    contacts: ContactVM[],
    customers: CustomerVM[],
    states: StateVM[],
}

export default function LocationForm(props: FormProps) {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const [statee, setStatee] = useReducer(
        (state: StateType, newState: StateType) => ({ ...state, ...newState }),
        {
            id: props.location.id,
            name: props.location.name,
            addressLine1: props.location.addressLine1,
            addressLine2: props.location.addressLine2,
            city: props.location.city,
            zip: props.location.zip,
            ivr: props.location.ivr,
            emsNumber: props.location.emsNumber,
            active: props.location.active,
            pmInfo: props.location.pmInfo,
            laborRate: props.location.laborRate,
            tripRate: props.location.tripRate,
            otRate: props.location.otRate,
            holidayRate: props.location.holidayRate,
            locationMarkup: props.location.locationMarkup,
            stateId: props.location.stateId,
            customerId: props.location.customerId,
            notes: props.location.notes,
            contacts: props.location.contacts,
            customers: props.customers,
            states: props.states
        },
    );

    const [company, setCompany] = useState(props.location.customerId);
    const [state, setState] = useState(props.location.stateId);

    // Get user data from session storage
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    //get user role
    const role = userData.role.name;

    function executeFormAction(e) {
        e.preventDefault();
    }

    useEffect(() => {
        setStatee({
            id: props.location.id,
            name: props.location.name,
            addressLine1: props.location.addressLine1,
            addressLine2: props.location.addressLine2,
            city: props.location.city,
            zip: props.location.zip,
            ivr: props.location.ivr,
            emsNumber: props.location.emsNumber,
            active: props.location.active,
            pmInfo: props.location.pmInfo,
            laborRate: props.location.laborRate,
            tripRate: props.location.tripRate,
            otRate: props.location.otRate,
            holidayRate: props.location.holidayRate,
            locationMarkup: props.location.locationMarkup,
            stateId: props.location.stateId,
            customerId: props.location.customerId,
            notes: props.location.notes,
            contacts: props.location.contacts,
            customers: props.customers,
            states: props.states
        })
        setCompany(props.location.customerId as number)
        setState(props.location.stateId as number)
    }, [props])

    //ADD NOTES

    const editLocation = async () => {
        await props.editLocation(statee.id);
    }

    const LocationNotes = () => {
        return (<div>

            <Grid item container justify='center'>
                <Grid item container xl={10} className='notes_container'>
                    <Grid container className='notes_container_textarea'>
                        {
                            statee.notes.map(({ text, created, createdBy }) => (
                                <div className='notes_container_textarea_data_has_content'>
                                    <span>{createdBy != null ? createdBy.userName : 'new note'}, {dateTimeFormat.format(created)}</span>
                                    <div style={{ marginTop: '.5em' }}>
                                        <span spellCheck>{text}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>


        </div>)
    }

    return (
        <>
            <Grid container alignItems='center' className='location_crud_form_title'>
                <Grid item container xl={4} sm={3} xs={6} alignItems='center'>
                    <Typography><b>{props.title}</b></Typography>
                </Grid>
                <Grid item container xl={4} sm={4} xs={6}>
                    <TextField
                        name='customer'
                        label="Customer"
                        value={props.customers.find(c => c.id == company).company}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                        }}
                    />
                </Grid>
                <Grid item container xl={4} sm={5} xs={5} className='location_crud_form_clr_fields'>
                    {props.button}
                </Grid>
                <hr />
            </Grid>
            <form noValidate autoComplete="off"
                onSubmit={e => executeFormAction(e)}>
                <Grid container className='location_form'>
                    <Grid lg={4} sm={6}>
                        <div className='location_details'>
                            <Typography>Location details</Typography>
                            <TextField
                                name='locationName'
                                label="Location Name"
                                value={statee.name}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                name='addressLine1'
                                label="Address 1"
                                value={statee.addressLine1 ? statee.addressLine1 : ""}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                name='addressLine2'
                                label="Address 2"
                                value={statee.addressLine2 ? statee.addressLine2 : ""}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                name='city'
                                label="City"
                                value={statee.city ? statee.city : ""}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                fullWidth
                            />
                            <section>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField
                                            name='state'
                                            label="STATE"
                                            value={props.states.find(s => s.id == state.toString()).name}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            name='zip'
                                            label="ZIP"
                                            value={statee.zip ? statee.zip : ""}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </section>
                            <TextField
                                name='ivr'
                                label="IVR / Check In"
                                value={statee.ivr ? statee.ivr : ""}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                name='emsNumber'
                                label="EMS"
                                value={statee.emsNumber ? statee.emsNumber : ""}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                fullWidth
                            />
                        </div>
                    </Grid>
                    <Grid container lg={4} sm={6}>
                        <div className='location_note'>
                            <Typography>Location notes</Typography>
                            <LocationNotes />
                        </div>
                    </Grid>
                    <Grid className='location_contact' lg={4} sm={12}>
                        <Grid container alignItems='center'>
                            <Grid item xs={6} >
                                <Typography>Contact Name</Typography>
                            </Grid>
                        </Grid>
                        <div className='location_contact_input_fields_readonly'>
                            {statee.contacts && statee.contacts.map((e) =>
                                <section className='contact_box'>
                                    <section>
                                        <FormControlLabel value="isPrimary" control={
                                            <Radio
                                                checked={e.isPrimary}
                                                color={'primary'}
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        } label="Primary" />
                                    </section>
                                    <section>
                                        <TextField
                                            name='contactName'
                                            label="Name"
                                            value={e.name}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </section>
                                    <section>
                                        <TextField
                                            name='email'
                                            label="Email"
                                            value={e.email}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </section>
                                    {e.title && <section>
                                        <TextField
                                            label="Title"
                                            value={e.title}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </section>}
                                    <section>
                                        <TextField
                                            label="Phone"
                                            value={e.phone.number}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                                inputComponent: phoneNumberFormat as unknown as ElementType<InputBaseComponentProps>
                                            }}
                                            fullWidth
                                        />
                                    </section>
                                </section>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Grid item container xl={12}>
                    <Grid item container xl={12} justify='space-between' className='location_rates' >
                        <Grid item container xl={12}>
                            <Typography className='rates_title'>Rates</Typography>
                        </Grid>
                        <Grid item container xl={2} sm={2} xs={6}>
                            <TextField
                                className='location_rates_input'
                                name='laborRate'
                                label="Labor"
                                defaultValue={0.00}
                                value={statee.laborRate}
                                InputProps={{
                                    inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container xl={2} sm={2} xs={6}>
                            <TextField
                                className='location_rates_input'
                                name='otRate'
                                label="OT"
                                defaultValue={0.00}
                                value={statee.otRate}
                                InputProps={{
                                    inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container xl={2} sm={2} xs={6}>
                            <TextField
                                className='location_rates_input'
                                name='tripRate'
                                label="Trip"
                                defaultValue={0.00}
                                value={statee.tripRate}
                                InputProps={{
                                    inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container xl={2} sm={2} xs={6}>
                            <TextField
                                className='location_rates_input'
                                name='holidayRate'
                                label="Holiday"
                                defaultValue={0.00}
                                value={statee.holidayRate}
                                InputProps={{
                                    inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container xl={2} sm={2} xs={6}>
                            <TextField
                                className='location_rates_input'
                                name='locationMarkup'
                                label="Markup"
                                defaultValue={0.00}
                                value={statee.locationMarkup}
                                InputProps={{
                                    inputComponent: decimalNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr />
                </Grid>
                <div className='location_form_footer'>
                    <Grid container>
                        <Grid item xl={6} sm={6}>
                            <TextField
                                className='location_form_footer_pm_info'
                                name={'pmInfo'}
                                placeholder="Type inside this box for PM info"
                                multiline
                                rows={5}
                                value={statee.pmInfo}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                            />
                        </Grid>
                        <Grid item container xs={6} sm={6} alignContent='flex-start'>
                            <Button
                                type='submit'
                                color='primary'
                                variant='contained'
                                size='large'
                                onClick={() => editLocation()}
                                fullWidth
                                disabled={role === UserRole.Dispatcher || role === UserRole.Technician}>
                                Edit location
                                </Button>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </>
    );
};
