import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography, GridList, GridListTile } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { UserBaseVM } from '../../../brines-refrigerator-api';

interface Note {
  id: number,
  created: Date,
  createdBy: UserBaseVM,
  lastModifiedBy: UserBaseVM,
  lastModified: Date,
  text: string,
  draft: boolean,
  uid: string
}

interface NoteProps {
  notes: Note[],
  deleteNoteCallback: (id: number, uid: string, draft: boolean) => void;
  setNoteForEditing: (id: number, text: string, uid: string, draft: boolean) => void,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,

    },
    marginBottom: {
      marginBottom: "0.5em"
    },
    rootList: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    rootTile: {
      width: "100%",

    },
    gridList: {
      width: 560,
      backgroundColor: "rgb(245, 245, 245)",

    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    section1: {
      height: "100%",
      backgroundColor: "rgb(245, 246, 251)",

    },
    note: {
      marginTop: "8%",
      fontSize: "1rem"
    },
    listTile: {
      borderBottom: "solid 0.2px"
    },
    authorFont: {
      fontSize: "0.875rem"
    }
  })
);

export default function NoteDispatch(props: NoteProps) {
  const options = { year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const classes = useStyles();

  function deleteNote(id: number, uid: string, draft: boolean) {
    props.deleteNoteCallback(id, uid, draft)
  }

  function editNote(id: number, text: string, uid: string, draft: boolean) {
    props.setNoteForEditing(id, text, uid, draft)
  }

  // Get user data from session storage
  let userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');

  // Map user roles
  let role = userData.role.name;

  return (
    <div className={classes.root}>
      <Grid container item xs={12} spacing={0}>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Grid item>
              <Paper variant="outlined" square >
                <div className={classes.rootList}>
                  <GridList cellHeight={220} className={classes.gridList}>
                    {props.notes.map((note) => (
                      <GridListTile key={note.id ? note.id : note.uid} style={{ width: "100%" }} className={classes.listTile} >
                        <div className={classes.section1}>
                          <Grid container alignItems="center" >
                            <Grid item xs={8}>
                              {
                                note.lastModifiedBy === undefined || note.lastModifiedBy === null
                                  ? <Typography className={classes.authorFont} gutterBottom>Added at: {note.draft ? 'Draft' : dateTimeFormat.format(note.created)}</Typography>
                                  : <Typography className={classes.authorFont} gutterBottom>Modified at: {note.draft ? 'Draft' : dateTimeFormat.format(note.lastModified)}</Typography>
                              }
                            </Grid>
                            <Grid item xs={4}>
                              <IconButton color="primary" aria-label="edit" onClick={() => editNote(note.id, note.text, note.uid, note.draft)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton color="primary" aria-label="delete" onClick={() => deleteNote(note.id, note.uid, note.draft)}>
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            {
                              note.lastModifiedBy === undefined || note.lastModifiedBy === null
                                ? <Typography className={classes.authorFont} gutterBottom>Added by: {note.draft ? userData.user.email : note.createdBy.userName}</Typography>
                                : <Typography className={classes.authorFont} gutterBottom>Modified by: {note.draft ? userData.user.email : note.lastModifiedBy.userName}</Typography>
                            }
                          </Grid>
                          <Typography className={classes.note} variant="body2">
                            {note.text}
                          </Typography>
                        </div>
                        <hr />
                      </GridListTile>
                    ))}
                  </GridList>
                </div>
              </Paper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};