import React, { useReducer, useEffect } from 'react';
import { Grid, Typography, TextField, Button, FormControl, Input, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { EquipmentDocumentVM, EquipmentNoteVM, EquipmentNoteCreateVM, ManufacturerVM, EquipmentTypeVM, CustomerVM, LocationVM } from '../../brines-refrigerator-api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UploadButton from '../../components/common/upload/UploadButton';
import UploadButtonType from '../../helpers/constants/uploadButtonTypes';
import { EquipmentDocumentAddVM } from '../../brines-refrigerator-api-extended';
import AddNote from '../../components/common/note/AddNote';
import FormDialog from '../../components/common/dialog/FormDialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSelectValidation, useTextValidation } from '../../helpers/validations';

interface FormProps {
    title: string,
    formAction: Function,
    selectedEquipment: {
        id: number,
        manufacturer: { id: number, name: string },
        model: string,
        type: { id: number, name: string },
        serialNumber: string,
        roomLocation: string,
        notes?: '',
        customer_name: { id: number, company: string },
        customer_location: { id: number, name: string }
    },
    clearFields: Function,
    addDraft: Function,
    removeDraft: Function,
    removeDocument: Function,
    downloadDocument: Function
    updateDocument: Function,
    setIsConfidentialDraft: Function,
    changeDraftDocumentName: Function,
    lastEditedDraftDocIndex: number,
    documents: EquipmentDocumentVM[],
    draftDocuments: EquipmentDocumentAddVM[],
    documentsToBeDeleted: EquipmentDocumentVM[],
    openNotesModal: Function,
    closeNotesModal: Function,
    notesModal: boolean,
    addEquipmentNote: Function,
    draftNotes: EquipmentNoteCreateVM[],
    manufacturerList: ManufacturerVM[],
    equipmentTypeList: EquipmentTypeVM[],
    customerList: CustomerVM[],
    locationList: LocationVM[],
}

const EquipmentCRUDForm: React.FC<FormProps> = (props: FormProps) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const { title, formAction, selectedEquipment, clearFields, manufacturerList,
        equipmentTypeList,
        customerList,
        locationList, } = props;

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            equipment: {
                id: 0,
                manufacturer: { id: 0, name: '' },
                model: '',
                serialNumber: '',
                roomLocation: '',
                type: { id: 0, name: '' },
                notes: [{ createdBy: '', created: '', text: '' }],
                customer_name: { id: 0, company: '' },
                customer_location: { id: 0, name: '' }
            },
            manufacturerList: manufacturerList,
            equipmentTypeList: equipmentTypeList,
            customerList: customerList,
            locationList: locationList,
            formTitle: 'ADD EQUIPMENT',
        },
    );

    useEffect(() => {
        setState({
            ...state,
            manufacturerList,
            equipmentTypeList,
            customerList,
            locationList,
            formTitle: title
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        setState({
            ...state,
            equipment: {
                id: selectedEquipment.id,
                manufacturer: selectedEquipment.manufacturer,
                model: selectedEquipment.model,
                type: selectedEquipment.type,
                serialNumber: selectedEquipment.serialNumber,
                roomLocation: selectedEquipment.roomLocation,
                notes: selectedEquipment.notes,
                customer_name: selectedEquipment.customer_name,
                customer_location: selectedEquipment.customer_location
            },
            formTitle: title
        })
        clearValidation()
    }, [props.selectedEquipment]);

    const EquipmentNotes = () => {

        const closeAddNoteModal = () => {
            props.closeNotesModal();
        }

        const openNotesModal = () => {
            props.openNotesModal();
        }

        const equipmentAddNote = (
            <AddNote
                buttonAction={props.addEquipmentNote}
                id={state.equipment.id}
                noteText={''}
                title={"Add Note"}
                buttonText={"Add Note"}
                onClick={closeAddNoteModal}
                uid={null}
            />
        );

        return (
            <Grid item container justify='center'>
                <Grid item container xl={10} className='notes_container'>
                    <Grid item container alignItems='flex-end'>
                        <Typography variant="h3">Equipment Notes</Typography>
                        <Button variant='outlined'
                            onClick={openNotesModal}
                            color='primary' style={{ width: '35em' }}>Add Note</Button>
                    </Grid>
                    <Grid container className='notes_container_textarea'>
                        {
                            state.formTitle === 'ADD EQUIPMENT' ?
                                (state.equipment.notes.length > 0 || props.draftNotes.length > 0) &&
                                state.equipment.notes.concat(props.draftNotes
                                    .map(x => new EquipmentNoteVM(
                                        {
                                            text: x.text,
                                            id: state.equipment.id,
                                            createdBy: null,
                                            equipmentId: 0,
                                            created: new Date()
                                        }
                                    )
                                    )
                                ).map(({ text, created, createdBy }) => (
                                    <div className='notes_container_textarea_data_has_content'>
                                        <span>{createdBy ? createdBy.username : 'Draft'}, {new Date(created).toLocaleDateString('en-US')}</span>
                                        <div style={{ marginTop: '.5em' }}>
                                            <span spellCheck>{text}</span>
                                        </div>
                                    </div>
                                ))
                                :
                                (state.equipment.notes.length > 0 || props.draftNotes.length > 0) &&
                                state.equipment.notes.concat(props.draftNotes.map(x => new EquipmentNoteVM(
                                    {
                                        text: x.text,
                                        id: state.equipment.id,
                                        createdBy: null,
                                        equipmentId: 0,
                                        created: new Date()
                                    }
                                )
                                )).map(({ text, created, createdBy }) => (
                                    <div className='notes_container_textarea_data_has_content'>
                                        <span>{createdBy ? createdBy.userName : 'Draft'}, {new Date(created).toLocaleDateString('en-US')}</span>
                                        <div style={{ marginTop: '.5em' }}>
                                            <span spellCheck>{text}</span>
                                        </div>
                                    </div>
                                ))
                        }
                        {props.notesModal && <FormDialog open={props.notesModal} body={equipmentAddNote} />}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const DocumentsFiles = () => {
        if (props.documents.length > 0 || props.draftDocuments.length > 0) {
            return (
                <div className='document_files'>
                    {props.documents.map((document, index) =>
                        <FormControl fullWidth style={{ marginTop: '.5em' }} key={document.documentFileId}>
                            <TextField
                                type='text'
                                value={document.documentFile.name}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => props.updateDocument(index)}
                                            >
                                                {document.documentFile.isConfidential ?
                                                    <Visibility color='primary' /> : <VisibilityOff color='primary' />}
                                            </IconButton>
                                            <IconButton
                                                aria-label="download document"
                                                onClick={() => props.downloadDocument(document.documentFileId)}
                                            >
                                                <GetAppIcon color='primary' />
                                            </IconButton>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => props.removeDocument(index)}
                                            >
                                                <HighlightOffIcon color='primary' />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    )}
                    {props.draftDocuments.map((document, index) =>
                        <FormControl fullWidth style={{ marginTop: '.5em' }} key={index}>
                            <Input
                                autoFocus={props.lastEditedDraftDocIndex === index}
                                type='text'
                                value={document.name}
                                onChange={(e) => props.changeDraftDocumentName(index, e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <span>{`.${document.file.name.split('.').pop()}`}</span>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => props.setIsConfidentialDraft(index)}
                                        >
                                            {document.isConfidential ?
                                                <Visibility color='primary' /> : <VisibilityOff color='primary' />}
                                        </IconButton>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => props.removeDraft(index)}
                                        >
                                            <HighlightOffIcon color='primary' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    )}
                </div>
            )
        } else {
            return (
                <div className='document_files'>
                    <Typography className='no_content'>No uploaded documents</Typography>
                </div>
            )
        }
    }

    interface CustomerListItem {
        company: string,
        id: number
    }

    interface LocationListItem {
        name: string,
        id: number
    }
    interface EquipmentType {
        name: string,
        id: number
    }

    const onFileDrop = (files: File[]) => {
        props.addDraft(files);
    }

    const [locationInputProps, triggerLocationValidation, resetLocationInputProps] = useSelectValidation();

    const [serialSerialNumberInputProps, triggerSerialNumberValidation, resetSerialNumberInputProps] = useTextValidation();
    const [equipmentEquipmentLocationInputProps, triggerEquipmentLocationValidation, resetEquipmentLocationInputProps] = useTextValidation();
    const [modelInputProps, triggerModelValidation, resetModelInputProps] = useTextValidation();
    const [manufacturerInputProps, triggerManufacturerValidation, resetManufacturerInputProps] = useSelectValidation();
    const [equipmentTypeInputProps, triggerEquipmentTypeValidation, resetEquipmentTypeInputProps] = useSelectValidation();

    function clearValidation() {
        resetSerialNumberInputProps()
        resetEquipmentLocationInputProps()
        resetModelInputProps()
        resetManufacturerInputProps()
        resetEquipmentTypeInputProps()
        resetLocationInputProps()
    }

    const validateAllFields = (): boolean => {
        const serialNumber = triggerSerialNumberValidation(state.equipment.serialNumber)
        const equipmentLocation = triggerEquipmentLocationValidation(state.equipment.roomLocation)
        const model = triggerModelValidation(state.equipment.model)
        const manufacturer = triggerManufacturerValidation(state.equipment.manufacturer)
        const location = triggerLocationValidation(state.equipment.customer_location.name)
        const equipmentType = triggerEquipmentTypeValidation(state.equipment.type.name)

        return (serialNumber || equipmentLocation || equipmentType || model || manufacturer || location)
    }

    function executeFormAction(e) {
        e.preventDefault();

        if (validateAllFields()) {
            return;
        }

        formAction(e, state.equipment, props.draftDocuments, props.documentsToBeDeleted, props.documents, props.draftNotes)
    }

    const handleManufacturerChange = (event: React.ChangeEvent<{ value: unknown }>, value: ManufacturerVM) => {
        setState({ ...state, equipment: { ...state.equipment, manufacturer: { id: value.id, name: value.name } } })
        if (manufacturerInputProps.error) {
            triggerManufacturerValidation(event.target.value)
        }
    }

    const handleEquipmentTypeChange = (event: React.ChangeEvent<{ value: unknown }>, value: EquipmentTypeVM) => {
        setState({ ...state, equipment: { ...state.equipment, type: { id: value.id, name: value.name } } })
        if (equipmentTypeInputProps.error) {
            triggerEquipmentTypeValidation(event.target.value)
        }
    }

    const handleCustomerChange = (event: React.ChangeEvent<{ value: unknown }>, value: CustomerVM) => {
        if (event.target.value === '') {
            setState({ ...state, equipment: { ...state.equipment, customer_name: { id: value ? value.id : 0, company: value.company ? value.company : "" }, customer_location: { id: 0, company: "" } } })
            return;
        }
        setState({ ...state, equipment: { ...state.equipment, customer_name: { id: value.id, company: value.company }, customer_location: { id: 0, company: "" } } })
    }

    const handleLocationChange = (event: React.ChangeEvent<{ value: unknown }>, value: LocationVM) => {
        if (event.target.value === '') {
            setState({ ...state, equipment: { ...state.equipment, customer_location: { id: 0, name: "" } } })
            return;
        }
        resetLocationInputProps()
        setState({ ...state, equipment: { ...state.equipment, customer_location: { id: value.id, name: value.name } } })
    }

    const getEquipmentLocations = () => {
        if (state.equipment.customer_name.id) {
            return state.locationList.filter(location => location.customerId == state.equipment.customer_name.id) as LocationListItem[];
        } else {
            return state.locationList as LocationListItem[];
        }
    }

    const getSelectedCustomer = () => {
        if (state.equipment.customer_location.id) {
            return state.customerList.find(customer => customer.id == (state.locationList.find(location => location.id == state.equipment.customer_location.id).customerId));
        } else {
            return state.equipment.customer_name;
        }
    }

    return (
        <form onSubmit={e => executeFormAction(e)}>
            <Grid container className='divider'>
                <Grid container item xl={10} lg={9} sm={9} alignItems='center'>
                    <Grid item xl={3} lg={4} sm={4}>
                        <Typography className='title'>{state.formTitle}</Typography>
                    </Grid>
                    <Grid container item xl={9} lg={8} sm={8} xs={12} justify='space-evenly'>
                        <Grid container item xl={5} lg={5} sm={5} className='equipment_customer'>
                            <Autocomplete
                                options={state.customerList as CustomerListItem[]}
                                getOptionLabel={(option) => option.company}
                                getOptionSelected={(option) => option.id}
                                disableClearable
                                value={getSelectedCustomer()}
                                onChange={handleCustomerChange}
                                fullWidth
                                renderInput={(params) =>
                                    <TextField {...params}
                                        type='text'
                                        label='Customer'
                                        variant='outlined'
                                        size='small'
                                        name='customer_name'
                                    />}
                            />
                        </Grid>
                        <Grid container item xl={5} lg={5} sm={5} className='equipment_location'>
                            <Autocomplete
                                // options={state.locationList as LocationList[]}
                                options={getEquipmentLocations()}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option) => option.id}
                                disableClearable
                                value={state.equipment.customer_location}
                                onChange={handleLocationChange}
                                fullWidth
                                renderInput={(params) =>
                                    <TextField {...params}
                                        type='text'
                                        label='Location'
                                        variant='outlined'
                                        size='small'
                                        name='customer_location'
                                        error={locationInputProps.error}
                                        onBlur={() => triggerLocationValidation(state.equipment.customer_location.name)}
                                    />}
                            />
                            <span className="validation_error">
                                {locationInputProps.errorMessage}
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xl={2} lg={3} sm={3} justify='flex-end'>
                    <Button type='button' variant='outlined' color='primary' onClick={() => clearFields()}>
                        {state.formTitle === 'ADD EQUIPMENT' ? 'Clear fields' : 'Cancel'}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container item xl={4} lg={6} className='equipment_details'>
                    <Typography className='label' variant="h3">Equipment Details</Typography>
                    <Grid container item>
                        <Autocomplete
                            options={state.manufacturerList as EquipmentType[]}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option) => option.id}
                            disableClearable
                            value={state.equipment.manufacturer}
                            onChange={handleManufacturerChange}
                            fullWidth
                            renderInput={(params) =>
                                <TextField {...params}
                                    type='text'
                                    label='Manufacturer'
                                    name='manufacturer'
                                    error={manufacturerInputProps.error}
                                    onBlur={() => triggerManufacturerValidation(state.equipment.manufacturer)}
                                />}
                        />
                        <span className="validation_error">
                            {manufacturerInputProps.errorMessage}
                        </span>
                        <TextField label='Model'
                            name='model'
                            value={state.equipment.model}
                            onChange={e => {
                                setState({ ...state, equipment: { ...state.equipment, model: e.target.value } })
                                if (modelInputProps.error) {
                                    triggerModelValidation(e.target.value)
                                }
                            }}
                            error={modelInputProps.error}
                            onBlur={() => triggerModelValidation(state.equipment.model)
                            }
                            fullWidth
                        />
                        <span className="validation_error">
                            {modelInputProps.errorMessage}
                        </span>
                        <Autocomplete
                            options={state.equipmentTypeList as EquipmentType[]}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option) => option.id}
                            disableClearable
                            fullWidth
                            value={state.equipment.type}
                            onChange={handleEquipmentTypeChange}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label='Equipment Type'
                                    name='type'
                                    error={equipmentTypeInputProps.error}
                                    onBlur={() => triggerEquipmentTypeValidation(state.equipment.type.name)
                                    }
                                />}
                        />
                        <span className="validation_error">
                            {equipmentTypeInputProps.errorMessage}
                        </span>
                        <TextField label='Serial Number'
                            name='serialNumber'
                            value={state.equipment.serialNumber}
                            onChange={e => {
                                setState({ ...state, equipment: { ...state.equipment, serialNumber: e.target.value } })
                                if (serialSerialNumberInputProps.error) {
                                    triggerSerialNumberValidation(e.target.value)
                                }
                            }}
                            error={serialSerialNumberInputProps.error}
                            onBlur={() => triggerSerialNumberValidation(state.equipment.serialNumber)}
                            fullWidth
                        />
                        <span className="validation_error">
                            {serialSerialNumberInputProps.errorMessage}
                        </span>
                        <TextField label='Equipment Location'
                            name='location'
                            value={state.equipment.roomLocation}
                            onChange={e => {
                                setState({ ...state, equipment: { ...state.equipment, roomLocation: e.target.value } })
                                if (equipmentEquipmentLocationInputProps.error) {
                                    triggerEquipmentLocationValidation(e.target.value)
                                }
                            }}
                            error={equipmentEquipmentLocationInputProps.error}
                            onBlur={() => triggerEquipmentLocationValidation(state.equipment.roomLocation)}
                            fullWidth
                        />
                        <span className="validation_error">
                            {equipmentEquipmentLocationInputProps.errorMessage}
                        </span>
                    </Grid>
                </Grid>
                <Grid container item xl={4} lg={5} className='equipment_notes'>
                    <EquipmentNotes />
                </Grid>
                <Grid container item xl={4} lg={6} className='equipment_documents'>
                    <Typography className='label' variant="h3">Documents</Typography>
                    <Grid className='upload_documents' style={{ width: '100%', height: '3em' }}>
                        <UploadButton buttonType={UploadButtonType.UploadDocument} onDropAction={onFileDrop} />
                    </Grid>
                    <Grid style={{ width: '100%', height: '18em', marginTop: '1em' }}>
                        <DocumentsFiles />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify='flex-end' className='equipment_submit'>
                <Button type='submit' variant='contained' color='primary' style={{ width: '25em' }}>Save All</Button>
            </Grid>
        </form>
    );
}

EquipmentCRUDForm.displayName = 'EquipmentCRUDForm';

export default EquipmentCRUDForm;