import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { DispatchClient } from '../../../brines-refrigerator-api';
import MaterialTable from 'material-table';

interface DispatchLaborPreviewProps {
    dispatchId: number
}

const DispatchLaborPreview = (props: DispatchLaborPreviewProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const laborColumns = [
        {
            title: 'Technician', field: 'technicianName'
        },
        {
            title: 'Hourly rate', field: 'hourlyRate'
        },
        {
            title: 'Hours', field: 'hours'
        },
        { title: 'Subtotal', field: 'subtotal' }
    ]

    const [laborList, setLaborList] = useState([]);
    const [laborTableLoading, setLaborTableLoading] = useState(false);

    const getDispatchLabor = async () => {
        try {
            setLaborTableLoading(true);
            const dispatchClient = new DispatchClient();
            const labor = await dispatchClient.getDispatchLabor(props.dispatchId);
            setLaborList(labor);
            setLaborTableLoading(false);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setLaborTableLoading(false);
        }
    }

    useEffect(() => {
        if (props.dispatchId != null) {
            getDispatchLabor();
        } else {
            setLaborList([]);
        }
    }, [props.dispatchId]);

    return (
        <div key={`dispatch-labor-table-${new Date().toLocaleString()}`}>
            <MaterialTable
                title="Labor"
                columns={laborColumns}
                data={laborList as unknown as []}
                options={{
                    search: false,
                    paging: false
                }}
                isLoading={laborTableLoading}
            />
        </div>
    );
}

export default DispatchLaborPreview;