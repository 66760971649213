import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useDropzone } from 'react-dropzone';
import UploadButtonType from '../../../helpers/constants/uploadButtonTypes';

const useStyles = makeStyles({
    button: {
        marginTop: '.4rem'
    }
});


interface ButtonProps {
    buttonType: UploadButtonType,
    onDropAction: Function,
    size?: 'small' | 'medium' | 'large';
}

export default function UploadButton(props: ButtonProps) {
    const classes = useStyles();
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        onDrop: acceptedFiles => {
            props.onDropAction(acceptedFiles)
        },
        noClick: true,
        noKeyboard: true
    });

    return (
        <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {props.buttonType === UploadButtonType.Basic &&
                <Button onClick={open} size={props.size}>
                    Upload
                </Button>
            }
            {props.buttonType === UploadButtonType.Icon &&
                <PublishIcon onClick={open} color="primary" className={classes.button}>
                    Upload
                </PublishIcon>
            }
            {props.buttonType === UploadButtonType.AddDocuments &&
                <Button variant="outlined" color="primary" onClick={open} size={props.size}>
                    + Add Documents
                </Button>
            }
            {props.buttonType === UploadButtonType.UploadDocument &&
                <Button component='label' variant='outlined' color='primary' fullWidth onClick={open}>
                    UPLOAD DOCUMENT
                </Button>
            }
            {props.buttonType === UploadButtonType.TechAddDocument &&
                <Button component='label' variant='text' color='primary' fullWidth onClick={open}>
                    ADD DOCUMENT
                </Button>
            }
        </div>
    )
}