import React from 'react';
import { Grid, Paper, Typography, GridList, TextField, GridListTile, withStyles, makeStyles, Theme, createStyles } from '@material-ui/core';
import { DispatchPartVM, DispatchItemVM } from '../../../brines-refrigerator-api';

interface PartsItemsListProps {
  dispatchParts: DispatchPartVM[]
  dispatchItems: DispatchItemVM[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    partsHeader: {
      marginBottom: "0.5em"
    },
    tileList: {
      maxHeight: "25rem",
      width: "100%"
    },
  })
);

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#000000',
    },
  },
})(TextField);

export default function PartsItemsList(props: PartsItemsListProps) {
  const classes = useStyles();


  return (

    <div>
      <Grid container>
        <Grid sm={6} xs={6}>
          <Paper square elevation={0}>
            <div>
              <Typography variant="h3" className={classes.partsHeader}>Parts</Typography>
              {props.dispatchParts.length === 0
                ? 'No parts to display.'
                : <GridList className={classes.tileList} cellHeight={100}>
                  {props.dispatchParts.map((tile) => (
                    <GridListTile key={tile.part.id} style={{ width: "100%" }} >
                      <div>
                        <Grid xs={12} item>
                          <CustomTextField
                            disabled
                            id="outlined-disabled"
                            label="Part #"
                            defaultValue={tile.part.number}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <CustomTextField
                            disabled
                            id="outlined-disabled"
                            label="Part Name"
                            defaultValue={tile.part.name}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>
                      </div>
                    </GridListTile>
                  ))}
                </GridList>}
            </div>
          </Paper>
        </Grid>

        <Grid sm={6} xs={6}>
          <Paper square elevation={0}>
            <div>
              <Typography variant="h3" className={classes.partsHeader}>Items</Typography>
              <GridList className={classes.tileList} >
                {props.dispatchItems.length === 0
                  ? 'No items to display.'
                  : props.dispatchItems.map((item) => (
                    <GridListTile style={{ width: "100%" }} >
                      <div>
                        <Grid xs={12} item>
                          <CustomTextField
                            disabled
                            id="outlined-disabled"
                            label="Item id"
                            multiline
                            defaultValue={item.id}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>
                        <Grid xs={12} item>
                          <CustomTextField
                            disabled
                            id="outlined-disabled"
                            label="Item Description"
                            multiline
                            defaultValue={item.description}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>
                      </div>
                    </GridListTile>
                  ))}
              </GridList>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}