import React from 'react';

import './DispatchDocuments.scss';
import { Box } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DispatchDocumentsClient } from '../../../brines-refrigerator-api';
import { downloadFile } from '../../../helpers/download';

const DispatchDocumentsPreview = (props) => {
    const dispatchDocumentsColumns = [
        { title: '#', field: 'id', sorting: false, editable: false },
        { title: 'Name', field: 'name', sorting: false },
        { title: 'Type', field: 'type', sorting: false, editable: false }
    ];

    const tableActions = [
        rowData => ({
            icon: () => <GetAppIcon color='primary' />,
            onClick: async (event, rowData) => { downloadDocument(rowData.id) },
            hidden: rowData.draft
        })
    ]

    const downloadDocument = async (id: number) => {
        const dispatchDocumentsClient = new DispatchDocumentsClient();
        const response = await dispatchDocumentsClient.download(id);
        await downloadFile(response.data, response.fileName);
    }

    return (
        <div className='dispatch-documents_container'>
            <Box>
                <MaterialTable
                    columns={dispatchDocumentsColumns as Column<any>[]}
                    title={""}
                    data={props.documents as []}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        paging: false
                    }}
                    actions={tableActions}
                />
            </Box>
        </div>
    )
}

export default DispatchDocumentsPreview;