import React, { useEffect, useReducer, useState } from 'react';
import { Container, Grid, Button, Paper, TextField, Typography, InputAdornment } from '@material-ui/core';
import { CustomerClient, CustomerVM, CustomerAddVM, PhoneTypeClient, PhoneAddVM, CustomerUpdateVM, StateClient, PhoneVM, HeadquarterVM, StateVM, HeadquarterUpdateVM, HeadquarterAddVM } from '../../brines-refrigerator-api';
import BasicTable from '../../components/common/table/BasicTable'
import CustomerCRUDForm from '../../components/customer/CustomerCRUDForm'
import ConfirmDialog from '../../components/common/dialog/ConfirmationDialog';
import UserRole from '../../helpers/constants/userRole';
import Delete from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import './CustomerView.scss';
import { useSnackbar } from 'notistack';
import { redirectIfSessionExpired } from '../../components/common/redirect/RedirectOnSessionTimeout';
import { useHistory } from 'react-router-dom';
import { useDebouncedSearch } from '../../helpers/search';
import SearchIcon from '@material-ui/icons/Search';
import CustomerForm from '../../components/customer/CustomerForm';
import LaunchIcon from '@material-ui/icons/Launch';

interface StateType {
    formTitle: string,
    customer: CustomerVM,
    formAction: Function
}

export default function CustomerView() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();

    // Get user data from session storage
    const userData: any = JSON.parse(sessionStorage.getItem('userData') || '{}');
    //get user role
    const role = userData.role.name;

    function handleServerError(message) {
        if (message === undefined || message.response === undefined) {
            enqueueSnackbar("An error has occured", { variant: "error" });
            return;
        }

        for (let prop in message.response.data.errors) {
            let propErrors = message.response.data.errors[prop];

            propErrors.forEach(element => {
                enqueueSnackbar(element, { variant: "error" });
            });
        }
    }

    const addNewCustomer = async (e: React.FormEvent<HTMLFormElement>, id: number, phones: PhoneAddVM[], _: number, stateId: number) => {
        e.preventDefault();

        const form = e.currentTarget;
        const customer = new CustomerAddVM({
            company: form.company.value,
            active: true,
            laborRate: Number(form.laborRate.value),
            tripRate: Number(form.tripRate.value),
            otRate: Number(form.otRate.value),
            holidayRate: Number(form.holidayRate.value),
            customerMarkup: Number(form.customerMarkup.value),
            phones: phones.filter(phone => phone.phoneTypeId !== 0 || phone.number !== ''),
            headquarterLocation: new HeadquarterAddVM({
                addressLine1: form.address1.value,
                addressLine2: form.address2.value,
                city: form.city.value,
                zip: form.zip.value,
                stateId: stateId,
            })
        });

        try {
            const customerClient = new CustomerClient();
            await customerClient.post(customer);
            clearFields()
            setTableLoading(true);
            populateTable()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }

    }
    const [state, setState] = useReducer(
        (state: StateType, newState: StateType) => ({ ...state, ...newState }),
        {
            customer: new CustomerVM({
                id: 0,
                company: '',
                active: true,
                laborRate: 0,
                tripRate: 0,
                otRate: 0,
                holidayRate: 0,
                customerMarkup: 0,
                phones: [],
                headquarterLocation: new HeadquarterVM({
                    id: 0,
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    zip: '',
                    stateId: 0,
                    state: new StateVM({
                        id: '0',
                        abbreviation: '',
                        name: ''
                    })
                })
            }),
            formTitle: 'ADD CUSTOMER',
            formAction: addNewCustomer
        },
    );

    const [phoneTypes, setPhoneTypes] = useState([]);

    const [customers, setCustomers] = useState([]);

    const [tableIsLoading, setTableLoading] = useState(true);

    async function getPhoneTypes() {
        const phoneTypeClient = new PhoneTypeClient();
        const phoneTypes = await phoneTypeClient.get();

        try {
            setPhoneTypes(phoneTypes);
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    };

    const [states, setStates] = useState([]);

    async function getStates() {
        const stateClient = new StateClient();
        const states = await stateClient.get();
        try {
            setStates(states);
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    };

    const editCustomer = async (e: React.FormEvent<HTMLFormElement>, id: number, phones: PhoneVM[], headquarterLocationId: number, stateId: number) => {
        e.preventDefault();

        const form = e.currentTarget;
        const customer = new CustomerUpdateVM({
            id: id,
            company: form.company.value,
            active: true,
            laborRate: Number(form.laborRate.value),
            tripRate: Number(form.tripRate.value),
            otRate: Number(form.otRate.value),
            holidayRate: Number(form.holidayRate.value),
            customerMarkup: Number(form.customerMarkup.value),
            phones: phones.filter(phone => phone.phoneTypeId !== 0 || phone.number !== ''),
            headquarterLocation: new HeadquarterUpdateVM({
                id: headquarterLocationId,
                addressLine1: form.address1.value,
                addressLine2: form.address2.value,
                city: form.city.value,
                zip: form.zip.value,
                stateId: stateId,
            })
        });
        try {
            const customerClient = new CustomerClient();
            await customerClient.put(customer);
            clearFields()
            setTableLoading(true);
            populateTable()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }

    }
    const button = <Button variant="outlined" onClick={(e: React.MouseEvent<HTMLElement>) => clearFields()} color='primary'>Clear Fields</Button>
    const buttonCancel = <Button variant="outlined" onClick={(e: React.MouseEvent<HTMLElement>) => clearFields()} color='primary'>Cancel</Button>

    const columns = [
        { title: '#', field: 'id' },
        { title: 'Customer', field: 'customer' },
    ];

    function clearFields() {
        setState({
            ...state,
            formTitle: 'ADD CUSTOMER',
            formAction: addNewCustomer,
            customer: new CustomerVM({
                id: 0,
                company: '',
                active: true,
                laborRate: 0,
                tripRate: 0,
                otRate: 0,
                holidayRate: 0,
                customerMarkup: 0,
                phones: [],
                created: new Date(),
                headquarterLocation: new HeadquarterVM({
                    id: 0,
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    zip: '',
                    stateId: 0,
                    state: new StateVM({
                        id: '0',
                        abbreviation: '',
                        name: ''
                    })
                })
            })
        })
    }

    async function setCustomerForEditing(id: number) {
        const customerClient = new CustomerClient();
        const customer = await customerClient.getById(id);
        setState({
            ...state,
            formTitle: 'EDIT CUSTOMER',
            formAction: editCustomer,
            customer
        })
    };

    async function setCustomerForDisplay(id: number) {
        const customerClient = new CustomerClient();
        const customer = await customerClient.getById(id);
        setState({
            ...state,
            formTitle: 'VIEW CUSTOMER',
            formAction: setCustomerForEditing,
            customer
        })
    };

    async function deleteCustomer(id: number) {
        try {
            const customerClient = new CustomerClient();
            await customerClient.toggleArchivedStatus(id);
            clearFields()
            setTableLoading(true);
            populateTable()
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
    };

    async function populateTable() {
        const customerClient = new CustomerClient();
        const customers = await customerClient.getByTeam();
        try {
            setCustomers(customers.map((e: CustomerVM) => ({ id: Number(e.id), customer: e.company, isArchived: e.isArchived }))
                .filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived)
            );
        } catch (error) {
            redirectIfSessionExpired(history, error)
            handleServerError(error)
        }
        setTableLoading(false);
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            await populateTable();
            getPhoneTypes();
            getStates();
        }
        fetchDataAsync()
    }, []);

    const [confirm, setConfirmOpen] = useState(false);
    const [idForDelete, setidForDelete] = useState(0);

    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');

    function confirmOpen() {
        setConfirmOpen(true);
    };

    function openDialog(id: number, toArchive: boolean) {
        //dialog title && message
        if (toArchive) {
            setDialogTitle('Delete?')
            if (role === UserRole.Admin) {
                setDialogMessage('Are you sure you want to archive this Customer?')
            } else {
                setDialogMessage('Are you sure you want to delete this Customer?')
            }
        }
        else {
            setDialogTitle('Return?')
            setDialogMessage('Are you sure you want to restore this Customer?')
        }
        setConfirmOpen(true);
        setidForDelete(id);
    };

    const tableActions = [
        rowData => ({
            icon: () => <LaunchIcon color='primary' />,
            onClick: (event, rowData: unknown) => { setCustomerForDisplay((rowData as { id: number }).id) },
            tooltip: "View Customer"
        }),
        rowData => ({
            icon: () => <Delete color='primary' />,
            onClick: (event, rowData: unknown) => { openDialog((rowData as { id: number }).id, true) },
            hidden: rowData['isArchived'] || role === UserRole.Dispatcher || role === UserRole.Technician,
            tooltip: "Delete Customer"
        }),
        rowData => ({
            icon: () => <RestoreIcon color='primary' />,
            onClick: (event, rowData: unknown) => { openDialog((rowData as { id: number }).id, false) },
            hidden: !rowData['isArchived'] || role === UserRole.Dispatcher || role === UserRole.Technician,
            tooltip: "Restore Customer"
        }),
    ]

    const searchCustomers = async (input: string) => {
        const client = new CustomerClient();
        const customers = await client.getByFilter(input)

        return customers.map((e: CustomerVM) => ({ id: Number(e.id), customer: e.company, isArchived: e.isArchived }))
            .filter(x => (x.isArchived && role === UserRole.Admin) || !x.isArchived)
    }
    const { inputText, setInputText, search } = useDebouncedSearch((text: string) => searchCustomers(text))

    return (
        <>
            <main className='customer'>
                <Container maxWidth={false} >
                    <Grid container>
                        <Grid xl={6} lg={6} md={6} xs={12} className='customer_container'>
                            <div className='customer_table'>
                                <Grid container item xs={12}>
                                    <Grid item sm={8}>
                                        <Typography variant="h1">Customer List</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Search"
                                            value={inputText}
                                            onChange={(e) => {
                                                setInputText(e.target.value)
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <BasicTable
                                    columns={columns}
                                    title="CUSTOMERS LIST"
                                    data={inputText ? search.result : customers as []}
                                    actions={tableActions}
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />,
                                        Toolbar: () => <></>,
                                    }}
                                    paging={true}
                                    isLoading={tableIsLoading || search.loading}
                                />
                            </div>
                        </Grid>
                        <Grid xl={6} lg={6} md={6} xs={12}>
                            <div className='customer_crud_form'>
                                {state.formTitle !== "VIEW CUSTOMER"
                                    && <CustomerCRUDForm
                                        title={state.formTitle}
                                        button={state.formTitle === "ADD CUSTOMER" ? button : buttonCancel}
                                        customer={state.customer}
                                        phoneTypes={phoneTypes}
                                        states={states}
                                        formAction={state.formAction}
                                    />}
                                {state.formTitle === "VIEW CUSTOMER"
                                    && <CustomerForm
                                        button={buttonCancel}
                                        customer={state.customer}
                                        formAction={state.formAction}
                                    />}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                {confirm && <ConfirmDialog
                    title={dialogTitle}
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={deleteCustomer}
                    param={idForDelete}
                >
                    {dialogMessage}
                </ConfirmDialog>}
            </main>
        </>
    );
}
